import { type Dispatch, type SetStateAction, useCallback } from 'react';
import type { Location, SetURLSearchParams } from 'react-router-dom';

import type { CriteriaChange, CriteriaDimension, PaginationCriteria, SortCriteria } from '@openx/types/tableCriteria';

import { type ListAction, ListActions, changeListCriteria, changePhraseCriteriaParams } from '../utils';

export const useGetActionCallbacks = (
  dispatch: Dispatch<ListAction>,
  onParamsChange: SetURLSearchParams,
  setQueryParamsForBackButton: (value: SetStateAction<string>) => void,
  location: Location
) => {
  const onSearchChange = useCallback(
    (filters: { phrase: string }) => {
      dispatch({ type: ListActions.SET_LOADING });
      onParamsChange(changePhraseCriteriaParams(filters.phrase));
      setQueryParamsForBackButton(location.search);
    },
    [dispatch, location.search, onParamsChange, setQueryParamsForBackButton]
  );

  const onCriteriaChange = useCallback(
    (change: CriteriaChange<CriteriaDimension>) => {
      dispatch({ type: ListActions.SET_LOADING });

      const changedCriteria = change.value as SortCriteria | PaginationCriteria;
      const { dimension } = change;

      onParamsChange(changeListCriteria(changedCriteria, dimension));
      setQueryParamsForBackButton(location.search);
    },
    [dispatch, location.search, onParamsChange, setQueryParamsForBackButton]
  );

  return { onCriteriaChange, onSearchChange };
};
