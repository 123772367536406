import { useQuery } from '@tanstack/react-query';
import { mergeWith } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ACL_TYPE } from '@openx/types/aclType';
import type {
  InstancesByIdObject,
  TargetableInventoryEntities,
  TargetableInventoryList,
  inventoryTargetingContent,
} from '@openx/types/targeting/inventoryAndContent';
import { QueryKeys } from '@openx/utils/api/queryKeys';
import { useIsAllowed } from '@openx/utils/permission/useIsAllowed';
import { isAllowedByCompiledAcl } from '@openx/utils/permission/validators';
import { enqueueErrorNotification } from '@openx/utils/state/notifications/actions';
import { searchInventories } from '@openx/utils/state/packages/inventories/api';

import { splitIncludedExcluded } from '../../../Targeting/shared';
import { useTargetingContext } from '../../context';

const rootInventoriesFetcher = async (
  idByInstances,
  currentInstanceUid,
  includedAndExcludedInventoriesJoined,
  inventoryPayload,
  accountUid
) => {
  const requests: Promise<TargetableInventoryList>[] = [];

  if (idByInstances) {
    Object.keys(idByInstances).forEach(instanceUid => {
      instanceUid !== currentInstanceUid &&
        requests.push(searchInventories(true, includedAndExcludedInventoriesJoined, instanceUid, accountUid, true));
    });
  }
  requests.push(searchInventories(true, includedAndExcludedInventoriesJoined, currentInstanceUid, accountUid, true));
  const resp = await Promise.all(requests);
  return splitIncludedExcluded(resp, inventoryPayload.excludes);
};

export const useInventoriesFetch = (
  inventoryPayload: inventoryTargetingContent<TargetableInventoryEntities>,
  idByInstances?: InstancesByIdObject
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { useAccountUid, useCurrentInstanceState } = useTargetingContext();

  const accountUid = useAccountUid();
  const { currentInstanceUid, isBefInstance } = useCurrentInstanceState();
  const { isAllowed } = useIsAllowed();

  const isUserAllowed =
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE)) ||
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE));

  const isBefAndRoot = isUserAllowed && isBefInstance;

  const includedAndExcludedInventoriesJoined = mergeWith(
    inventoryPayload.includes,
    inventoryPayload.excludes,
    (includesInstancesId, excludesInstancesId) => `${includesInstancesId},${excludesInstancesId}`
  );

  const { data: inventories, isLoading } = useQuery<inventoryTargetingContent<TargetableInventoryList> | null>({
    queryFn: async () => {
      try {
        if (isBefAndRoot) {
          return await rootInventoriesFetcher(
            idByInstances,
            currentInstanceUid,
            includedAndExcludedInventoriesJoined,
            inventoryPayload,
            accountUid
          );
        } else {
          const resp = await Promise.all([
            searchInventories(false, includedAndExcludedInventoriesJoined, currentInstanceUid, accountUid, true),
          ]);
          return splitIncludedExcluded(resp, inventoryPayload.excludes);
        }
      } catch (err) {
        dispatch(enqueueErrorNotification(t('Failed to fetch Inventories'), err));
        return null;
      }
    },
    queryKey: [QueryKeys.INVENTORIES],
  });

  return {
    inventories,
    loading: isLoading,
  };
};
