import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';
import { StatusIndicator } from '@openx/components/core/lib/StatusIndicator/StatusIndicator';
import type { Audience } from '@openx/types/targeting/audience';

import { AudienceExpirationAlerts, Item, type SelectedItemComponent } from '../../shared';

export const OaAudienceItem: SelectedItemComponent = props => {
  const { t } = useTranslation();
  const { children, option, testId, readonly } = props;

  const audienceId = option?.id.replace('openaudience-', '');

  return (
    <Grid gap={1} wrap="nowrap" container>
      <Grid paddingRight={2} xs item>
        <Item {...props}>{children}</Item>
      </Grid>

      <Grid paddingTop={0.25} xs={2} item>
        <Grid justifyContent="flex-end" container>
          <StatusIndicator status={option?.status} data-test={testId?.replace('-item', '-status')} />
        </Grid>
      </Grid>

      <Grid xs={1} item>
        {option && (
          <AudienceExpirationAlerts
            audience={option as Audience}
            fromDeal={true}
            sx={{ height: 28, marginRight: 0.5 }}
          />
        )}
      </Grid>

      {audienceId && readonly && (
        <Grid xs={3} item>
          <Grid justifyContent="flex-end" container>
            <Button
              data-test="show-audience-button"
              variant="text"
              color="primary"
              href={`/audiences/${audienceId}?fallback=true`}
              disabled={!audienceId}
            >
              {t('SHOW AUDIENCE')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
