import { isEqual } from 'lodash';

import type { BaseRow } from '@openx/types/tableHelpers';
import { isInArray } from '@openx/utils/lib/isInArray';

export function allItemsSelected<RowT extends BaseRow>(items: RowT[], selectedItems: RowT[]): boolean {
  if (items.length === 0 || items.length > selectedItems.length) {
    return false;
  }

  return items.every(item => isInArray(selectedItems, item, isEqual));
}

export function anyItemSelected<RowT extends BaseRow>(items: RowT[], selectedItems: RowT[]): boolean {
  if (items.length === 0 || selectedItems.length === 0) {
    return false;
  }

  return !items.every(item => !isInArray(selectedItems, item, isEqual));
}
