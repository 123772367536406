import CloseIcon from '@mui/icons-material/Close';
import { type ReactNode, useCallback } from 'react';

import type { Option } from '@openx/types/options';

import {
  StyledAllowedFiltersGrid,
  StyledFilterTitleTypography,
  StyledItemContainerSpan,
  StyledItemRemoveIconButton,
  StyledRemoveIconWrapperDiv,
} from './styled/Item.styled';

export type ItemProps<ItemType> = {
  readonly: boolean;
  testId?: string;
  item?: ItemType;
  option?: Option;
  onRemove?: (item: ItemType) => void;
  children?: ReactNode;
  disabledStyle?: boolean;
};

export function Item<ItemType>({
  readonly,
  testId,
  onRemove,
  item,
  children,
  disabledStyle,
}: ItemProps<ItemType>): JSX.Element {
  const onClick = useCallback(() => {
    if (item && onRemove) {
      onRemove(item);
    }
  }, [item, onRemove]);

  return (
    <StyledAllowedFiltersGrid container wrap="nowrap" alignItems="center">
      <StyledFilterTitleTypography data-test={testId} variant="body1" component="div">
        <StyledItemContainerSpan disabledStyle={disabledStyle}>{children}</StyledItemContainerSpan>
      </StyledFilterTitleTypography>

      {!readonly && !!onRemove && (
        <StyledRemoveIconWrapperDiv className="removeIconWrapper">
          <StyledItemRemoveIconButton onClick={onClick} size="large">
            <CloseIcon data-test="close-dialog" fontSize="small" />
          </StyledItemRemoveIconButton>
        </StyledRemoveIconWrapperDiv>
      )}
    </StyledAllowedFiltersGrid>
  );
}
