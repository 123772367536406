import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { ACL_TYPE } from '@openx/types/aclType';
import type { InstanceProps } from '@openx/types/targeting/inventoryAndContent';
import { useIsAllowed } from '@openx/utils/permission/useIsAllowed';
import { isAllowedByCompiledAcl } from '@openx/utils/permission/validators';
import { searchInventoriesInstances } from '@openx/utils/state/packages/inventories/api';

import { useTargetingContext } from '../../../utils/context';

const filterInstancesByMarkets = (instances, market) =>
  market ? instances.filter(({ market_operator_id }) => market_operator_id === market) : instances;

export const useFetchInventoryInstances = (marketId: null | string = null) => {
  const [instances, setInstances] = useState<InstanceProps[] | undefined>([]);
  const { isAllowed } = useIsAllowed();

  const isUserAllowed =
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE)) ||
    isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE));

  const { useCurrentInstanceState } = useTargetingContext();
  const { isBefInstance } = useCurrentInstanceState();

  const { data } = useQuery<InstanceProps[] | null>({
    queryFn: async () => {
      if (isUserAllowed && isBefInstance) {
        const response = await searchInventoriesInstances();

        return filterInstancesByMarkets(response, marketId);
      }

      return [];
    },
    queryKey: [marketId],
  });

  useEffect(() => {
    setInstances(data ?? []);
  }, [data]);

  return {
    instances,
  };
};
