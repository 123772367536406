import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useScreenResolutionOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isScreenResolutionOptionsLoading,
    data: screenResolutionOptions,
    refetch: refetchScreenResolutionOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Screen Resolution options'),
    optionType: OptionsType.SCREEN_RESOLUTION,
    queryOptions,
  });

  return {
    isScreenResolutionOptionsLoading,
    refetchScreenResolutionOptions,
    screenResolutionOptions,
  };
}
