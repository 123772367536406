import type { AdPlacement } from '@openx/types/adUnit';
import type { OptionsMap } from '@openx/types/options';

export const mapAdPlacementLabels = (inputValue: AdPlacement, adPlacement: OptionsMap): string | null => {
  switch (inputValue) {
    case 'BANNER':
      return adPlacement.BANNER?.name;
    case 'VIDEO':
      return adPlacement.VIDEO?.name;
    case 'BANNER,VIDEO' as AdPlacement:
      return 'Any (Banner or Video)';
    default:
      return null;
  }
};
