import { styled } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

import { IndentedSections } from '@openx/components/core/lib/IndentedSection/IndentedSections';
import type { VideoFormState, VideoOption } from '@openx/types/targeting/video';

import { DimensionChipLine } from '../../shared';
import { Items } from '../Items';

const StyledWrapper = styled('div')`
  padding-left: ${({ theme }) => theme.spacing(1.5)};
`;

type PlacementAndFormatProps = {
  placementAndFormat: (VideoOption.PLACEMENT | VideoOption.PRESENTATION_FORMAT)[];
  params: VideoFormState;
  updateParams?: Dispatch<SetStateAction<VideoFormState>>;
  readonly: boolean;
  shouldAddDimensionLine: boolean;
};

export function PlacementAndFormat({
  placementAndFormat,
  params,
  updateParams = () => {},
  readonly,
  shouldAddDimensionLine,
}: PlacementAndFormatProps): JSX.Element {
  return (
    <>
      <IndentedSections dataTest="placement-and-format-or-container">
        <StyledWrapper>
          {placementAndFormat.map((type, index) => (
            <div key={type}>
              <Items params={params[type]} type={type} readonly={readonly} onChange={updateParams} />
              {index === 0 && <DimensionChipLine switchState isSubsection />}
            </div>
          ))}
        </StyledWrapper>
      </IndentedSections>
      {shouldAddDimensionLine && <DimensionChipLine />}
    </>
  );
}
