import { useMemo } from 'react';

import type { GeographicTargetingState } from '@openx/types/targeting/geographic';
import type { TargetingReadOnlyProps } from '@openx/types/targeting/targeting';

import { TargetingReadOnly } from '../shared';

import { GeographicItems } from './GeographicItems';
import { isGeoEmpty } from './utils';

export const GeographicReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<GeographicTargetingState>) => {
  const isEmpty = useMemo(() => isGeoEmpty(targetingParams), [targetingParams]);

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isEmpty && targetingParams && <GeographicItems targetingParams={targetingParams} />}
    </TargetingReadOnly>
  );
};
