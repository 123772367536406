import { Grid, type SxProps } from '@mui/material';
import { find } from 'lodash';
import { type KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core/lib/TextField/TextField';
import type { AdvancedTargetingData } from '@openx/types/targeting/advancedTargeting';
import { contextualSegmentPrefix } from '@openx/types/targeting/contextualSegments';
import type { CustomVariablesData } from '@openx/types/targeting/customVariables';
import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

import {
  dropdownOptions,
  useCustomVariablesDropdownLabels,
  useCustomVariablesPlaceholder,
} from '../../../CustomVariables/constants';
import { ComparisonTypeDropdown, DrawerTopBox } from '../../../shared';

type CustomVariablesTopBoxProps = {
  items: AdvancedTargetingData;
  onEnter: (customVariables: CustomVariablesData) => void;
  sx?: SxProps;
};

type ErrorText = {
  customAttribute: string | null;
  customValue: string | null;
};

export const CustomVariablesTopBox = ({ items, onEnter, sx }: CustomVariablesTopBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const mapTypeToPlaceholder = useCustomVariablesPlaceholder();
  const labels = useCustomVariablesDropdownLabels();

  const [comparisonType, setComparisonType] = useState<ComparisonType | null>(null);
  const [customAttributeInput, setCustomAttributeInput] = useState<string>('');
  const [customValueInput, setCustomValueInput] = useState<string>('');
  const [errorText, setErrorText] = useState<ErrorText>({ customAttribute: null, customValue: null });

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && comparisonType) {
        const attr = customAttributeInput.trim();
        const val = customValueInput.trim();

        const alreadyExists = find(items.custom, { attr, op: comparisonType, val });

        if (val === '' || attr === '') {
          const attrError = attr === '' ? t('This field cannot be empty.') : null;
          const valError = val === '' ? t('This field cannot be empty.') : null;

          setErrorText({
            customAttribute: attrError,
            customValue: valError,
          });
          return;
        }

        if (attr.startsWith(contextualSegmentPrefix)) {
          setErrorText({
            customAttribute: t('Use Contextual Segments section to set contextual variables.'),
            customValue: null,
          });
          return;
        }

        if (alreadyExists) {
          const errorMessage = t('This custom variable is already on the list.');

          setErrorText({
            customAttribute: errorMessage,
            customValue: errorMessage,
          });
          return;
        }
        onEnter({ op: items.op, val: [...items.custom, { attr, op: comparisonType, val }] });
        setCustomAttributeInput('');
        setCustomValueInput('');
      }

      setErrorText({ customAttribute: null, customValue: null });
    },
    [items, comparisonType, onEnter, t, customValueInput, customAttributeInput]
  );

  const placeholder = mapTypeToPlaceholder[comparisonType ?? mapTypeToPlaceholder[ComparisonType.EQUALS]];

  return (
    <Grid item xs={8} sx={{ paddingLeft: '0.8rem' }}>
      <DrawerTopBox sx={sx}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              label={t('Type')}
              fullWidth
              value={customAttributeInput}
              onChange={e => {
                setCustomAttributeInput(e.target.value);
              }}
              onKeyUp={onKeyPress}
              placeholder={t('Enter a value')}
              error={!!errorText.customAttribute}
              helperText={errorText.customAttribute}
              data-test="custom-variables-custom-attribute-input"
            />
          </Grid>

          <Grid item xs={3}>
            <ComparisonTypeDropdown
              dataTest="custom-variables-comparison-options"
              labels={labels}
              options={dropdownOptions}
              value={comparisonType}
              sx={{ marginTop: 2 }}
              onChange={setComparisonType}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t('Value')}
              value={customValueInput}
              onChange={e => setCustomValueInput(e.target.value)}
              onKeyUp={onKeyPress}
              fullWidth
              placeholder={placeholder}
              error={!!errorText.customValue}
              helperText={errorText.customValue}
              data-test="custom-variables-custom-value-input"
            />
          </Grid>
        </Grid>
      </DrawerTopBox>
    </Grid>
  );
};
