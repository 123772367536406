import ArrowBack from '@mui/icons-material/ArrowBack';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getPath } from 'utils';

import { Background } from 'components/Background';
import { Logo } from 'components/Logo';

import {
  BackButton,
  BackgroundIcons,
  HeaderImage,
  PageHeaderContainer,
  SubtitleTypography,
  TitleContainer,
  TitleTypography,
} from './components';

type PageHeaderProps = {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  isDetailsPage?: boolean;
  children?: React.ReactNode;
  searchParams?: string;
};

export function PageHeader({
  title,
  subtitle,
  isLoading,
  isDetailsPage = false,
  children,
  searchParams,
}: PageHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const path = getPath(searchParams);

  return (
    <PageHeaderContainer isDetailsPage={isDetailsPage}>
      <Background isHeader>
        <HeaderImage src={'/assets/header_particles.jpg'} />
        <BackgroundIcons />
        <Logo />
        <TitleContainer isDetailsPage={isDetailsPage}>
          {isLoading && <Skeleton variant="rounded" width={130} height={35} sx={{ marginTop: '7px' }} />}
          {title && (
            <TitleTypography isDetailsPage={isDetailsPage} variant="h1" data-test="page-header-title">
              {title}
            </TitleTypography>
          )}

          {subtitle && (
            <SubtitleTypography variant="h3" data-test="page-header-subtitle">
              {subtitle}
            </SubtitleTypography>
          )}
        </TitleContainer>

        {isDetailsPage && (
          <BackButton
            variant="text"
            startIcon={<ArrowBack />}
            color="inherit"
            onClick={() => {
              navigate(path);
            }}
            data-test="back-button"
          >
            Back to list
          </BackButton>
        )}
      </Background>

      {children}
    </PageHeaderContainer>
  );
}
