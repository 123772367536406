import type { DistributionChannel } from '@openx/types/distributionChannel';
import type { OptionsMap } from '@openx/types/options';

export const mapDistributionChannelLabels = (
  inputValue: DistributionChannel,
  distributionChannel: OptionsMap
): string | null => {
  switch (inputValue) {
    case 'APP':
      return distributionChannel.APP?.name;
    case 'WEB':
      return distributionChannel.WEB?.name;
    case 'APP,WEB' as DistributionChannel:
      return 'Any (Web or Application)';
    default:
      return null;
  }
};
