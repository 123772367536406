import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type UrlTargeting, UrlTargetingType } from '@openx/types/targeting/domains';

import { EmptyFilterMessage, FiltersTitle, SelectedItems, Switch, getItemsMaxRows } from '../shared';
import type { TargetingItemsProps } from '../types';

export const DomainsItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<UrlTargeting>) => {
  const { t } = useTranslation();

  const { type, urls } = targetingParams;

  const typeLabel = type === UrlTargetingType.blacklist ? t('blocked domains') : t('allowed domains');
  const filtersTitle = urls.length ? typeLabel : '';

  const switchState = type === UrlTargetingType.blacklist;

  const itemsMaxRows = getItemsMaxRows(readonly);

  const handleChange = useCallback(
    (values: string[]) => {
      onChange({
        type,
        urls: values,
      });
    },
    [onChange, type]
  );

  return urls.length !== 0 ? (
    <div data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('Domains [ {count} ]', { count: urls.length })}
        readonly={readonly}
      />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange({
              type: type === UrlTargetingType.blacklist ? UrlTargetingType.whitelist : UrlTargetingType.blacklist,
              urls,
            })
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}
      <SelectedItems
        filtersTitle={filtersTitle}
        isAddIcon={!switchState}
        selectedItems={urls}
        readonly={readonly}
        handleChange={handleChange}
        dataTest={filtersTitle}
        //Forcing maxRows constraint on DomainsItems because due to a bug in MUI it was not showing all text without it.
        //Should be removed by https://openxtechinc.atlassian.net/browse/UIG-469
        maxRows={itemsMaxRows}
      />
    </div>
  ) : (
    <EmptyFilterMessage text={t('No filters applied.')} />
  );
};
