import { CloudDone, CloudOff, CloudSync, CloudUpload, EventBusy, SyncProblem } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';

import { AudienceStatus } from '@openx/types/targeting/audience';

const defaultStyles = {
  fontSize: '1rem',
  marginRight: 0.5,
  verticalAlign: 'middle',
};

const statusIcons = {
  [AudienceStatus.EXPIRED]: props => <EventBusy color="warning" {...props} />,
  [AudienceStatus.EXPORTED]: props => <CloudDone color="success" {...props} />,
  [AudienceStatus.EXPORTING]: props => <CloudSync color="primary" {...props} />,
  [AudienceStatus.PUBLISHING]: props => <CloudUpload color="disabled" {...props} />,
  [AudienceStatus.PUBLISH_FAILED]: props => (
    <CloudOff {...props} sx={{ color: 'background.tomato', ...(props.sx ?? {}) }} />
  ),
  [AudienceStatus.EXPORT_FAILED]: props => <SyncProblem color="error" {...props} />,
};

export const StatusIcon = ({ status = '', sx }: { status?: string; sx?: SxProps<Theme> }) => {
  const IconComponent = statusIcons[status];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent data-test={`status-${status.replace('_', '-')}`} sx={{ ...sx, ...defaultStyles }} />;
};
