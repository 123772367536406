import { isEqual } from 'lodash';
import { memo, useEffect, useState } from 'react';

import type { MetacategoryData } from '@openx/types/targeting/metacategory';
import type { TargetingProps } from '@openx/types/targeting/targeting';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { TargetingDrawer, useDrawer } from '../shared';

import { MetacategoryReadOnly, MetacategoryTopBox } from './components';
import { MetacategoryItems } from './components/MetacategoryItems';

const emptyMappedTargetingParams: MetacategoryData = {
  exclude_mfa: true,
  excludes: [],
  includes: null,
  inter_dimension_operator: Criteria.ALL,
  keywords: {
    excludes: [],
    includes: [],
  },
};

export const Metacategories = memo(function Metacategory({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
}: TargetingProps<MetacategoryData>) {
  const mappedTargetingParams: MetacategoryData = {
    ...emptyMappedTargetingParams,
    ...targetingParams,
    keywords: {
      ...emptyMappedTargetingParams.keywords,
      ...(targetingParams?.keywords || {}),
    },
  };

  const [params, updateParams] = useState<MetacategoryData>(mappedTargetingParams);

  const areParamsNotEmpty = !isEqual(params, emptyMappedTargetingParams);
  const isDataUpdated = !isEqual(mappedTargetingParams, params);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => onFieldUpdate(field, areParamsNotEmpty ? params : null),
    restoreParams: () => updateParams(mappedTargetingParams),
  });

  useEffect(() => {
    if (mappedTargetingParams.exclude_mfa !== params.exclude_mfa) {
      updateParams(prevParams => ({ ...prevParams, exclude_mfa: mappedTargetingParams.exclude_mfa }));
    }
  }, [params.exclude_mfa, mappedTargetingParams?.exclude_mfa]);

  return (
    <>
      <MetacategoryReadOnly
        name={name}
        readonly={readonly}
        targetingParams={targetingParams}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={() => {
          onFieldUpdate(field, { ...emptyMappedTargetingParams, exclude_mfa: params.exclude_mfa });
          updateParams({ ...emptyMappedTargetingParams, exclude_mfa: params.exclude_mfa });
        }}
        isDisabled={isDisabled}
      />
      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <MetacategoryTopBox items={params} onChange={metacategories => updateParams(metacategories)} />
          <MetacategoryItems targetingParams={params} readonly={false} onChange={updateParams} />
        </TargetingDrawer>
      )}
    </>
  );
});
