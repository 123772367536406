import { Grid } from '@mui/material';
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import { TextField } from '@openx/components/core/lib/TextField/TextField';
import type { AdvancedTargetingData, ContextualSegmentsV2FormState } from '@openx/types/targeting/advancedTargeting';
import type { SegmentsOption } from '@openx/types/targeting/customVariables';
import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

import { useGetContextualData } from '../hooks/useGetContextualData';

import { comparisonOptionLabels, filteredTypeOptions } from './constants';
import { filterSegmentsOptions } from './utils';

interface ContextualSegmentsTopBoxItems extends AdvancedTargetingData {
  contextualV2: ContextualSegmentsV2FormState;
}

export type ContextualSegmentsTopBoxProps = {
  items: ContextualSegmentsTopBoxItems;
  updateParams: Dispatch<SetStateAction<AdvancedTargetingData>>;
  accountUid: string;
};

export const ContextualSegmentsTopBoxV2 = ({ items, updateParams, accountUid }: ContextualSegmentsTopBoxProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [comparisonType, setComparisonType] = useState<ComparisonType>();
  const { t } = useTranslation();

  const optionLabels = useMemo(() => comparisonOptionLabels(), []);
  const { segments, loading, providerId, providerName } = useGetContextualData(accountUid);

  useEffect(() => {
    if (providerId) {
      updateParams(prev => ({
        ...prev,
        contextualV2: {
          ...prev.contextualV2,
          attr: providerId,
        },
      }));
    }
  }, [providerId, providerName, updateParams]);

  const filteredOptions = useMemo(
    () => filterSegmentsOptions(items.contextualV2, segments, searchInput),
    [items.contextualV2, searchInput, segments]
  );

  const handleAddSegment = (value: SegmentsOption | null) => {
    if (!value) return;

    const inclusionKey = comparisonType === ComparisonType.INTERSECTS ? 'includes' : 'excludes';

    updateParams(prev => {
      const prevSegmentValue = prev?.contextualV2?.[inclusionKey] || [];
      const udpatedSegments = [...prevSegmentValue, value.id];

      return {
        ...prev,
        contextualV2: {
          ...prev.contextualV2,
          attr: providerId ?? '',
          [inclusionKey]: udpatedSegments,
        },
      };
    });
  };

  const noSegments = segments.length === 0;
  const notApplicablePlaceholder = t('N / A');
  const providerDisplayName = providerName ?? providerId ?? notApplicablePlaceholder;

  return (
    <>
      <Grid item xs={8} sx={{ paddingLeft: '0.8rem' }}>
        <Grid container spacing={1} sx={{ border: 'none', marginTop: theme => -theme.spacing(1) }}>
          <Grid item xs={6}>
            <TextField
              sx={{ width: '100%' }}
              data-test={'provider-section'}
              name="contextual_provider"
              value={providerDisplayName}
              placeholder={providerDisplayName}
              label={t('Provider')}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <AutoComplete
              options={filteredTypeOptions}
              renderOptions={{
                dataTest: 'comparsion-option',
              }}
              getOptionLabel={option => optionLabels[option]}
              textFieldReadOnly
              disableClearable
              data-test={'comparison-options'}
              disabled={!providerName || noSegments || loading}
              textFieldProps={{
                label: t('Comparison Type'),
                placeholder: !providerName ? notApplicablePlaceholder : t('Select Comparison Type'),
              }}
              onChange={(_, value) => setComparisonType(value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ border: 'none', marginBottom: theme => theme.spacing(1), marginTop: theme => theme.spacing(1) }}
      >
        <AutoCompleteVirtualize
          loading={loading}
          disabled={!comparisonType || noSegments || loading}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          textFieldProps={{
            label: t('Segment'),
            placeholder: !providerName ? notApplicablePlaceholder : t('Select Segment'),
          }}
          onChange={(_, value) => {
            handleAddSegment(value);
            setSearchInput('');
          }}
          getOptionLabel={option => option.name ?? ''}
          options={filteredOptions}
          data-test="contextual-segments-dropdown"
          onInputChange={(_, option) => setSearchInput(option)}
          inputValue={searchInput}
          value={null}
        />
      </Grid>
    </>
  );
};
