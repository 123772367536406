import { Grid, IconButton, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const StyledAllowedFiltersGrid = styled(Grid)`
  position: relative;
  width: fit-content;
  &:hover {
    .removeIconWrapper {
      visibility: visible;
    }
  }
`;

export const StyledRemoveIconWrapperDiv = styled('div')`
  background: linear-gradient(270deg, #f0f0f0 49.09%, rgba(240, 240, 240, 0) 100%);
  border-radius: 0px 28px 28px 0px;
  position: absolute;
  right: 7px;
  text-align: right;
  top: 50%;
  transform: translate(0, -50%);
  visibility: hidden;
  width: 50px;
`;

export const StyledItemRemoveIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledFilterTitleTypography = styled(Typography)`
  line-height: 28px;
  margin-right: ${({ theme }) => theme.spacing(1)};
` as typeof Typography;

export const StyledItemContainerSpan = styled('span')<{ disabledStyle?: boolean }>`
  word-break: break-word;
  ${({ disabledStyle, theme }) =>
    disabledStyle &&
    css`
      color: ${theme.palette.text.disabled};
    `}
`;
