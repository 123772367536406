import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { OptionsMap } from '@openx/types/options';
import { type AudienceData, AudienceOption } from '@openx/types/targeting/audience';

import { useTargetingContext } from '../../utils';
import { OptionsDropdown, StatusIcon } from '../shared';

import { AudienceTypeDropdown, LiveIntentInput, MatchedUsersDropdown, SegmentsDropdown } from './components';

type TopBoxValueFieldProps = {
  selectedType: AudienceOption;
  params: AudienceData | null;
  onChange: (value?: string | Set<string>) => void;
  onChangeMulti: (values: Set<string>) => void;
  accountUid: string;
  options: OptionsMap;
  isLoading: boolean;
};

export const TopBoxValueField: React.FC<TopBoxValueFieldProps> = ({
  selectedType,
  params,
  onChange,
  onChangeMulti,
  accountUid,
  options,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isOa } = useTargetingContext();

  const dmpOptions = useMemo(() => new Set(params?.[AudienceOption.DMP_SEGMENTS]?.val.split(',') ?? []), [params]);

  if (selectedType === AudienceOption.LIVEINTENT) {
    return (
      <LiveIntentInput
        value={params?.[AudienceOption.LIVEINTENT]?.val ?? ''}
        onEnter={onChange}
        placeholder={t('Input LiveIntent segment ID and press ‘Enter’')}
      />
    );
  }

  if (selectedType === AudienceOption.DMP_SEGMENTS) {
    return (
      <OptionsDropdown
        loading={isLoading}
        options={options ? options : {}}
        onChange={onChangeMulti}
        selectedOptions={dmpOptions}
        placeholder={t('[ Select an Audience Segment ]')}
        dataTest="audience-segments-input"
      />
    );
  }

  if (selectedType === AudienceOption.INTENDED_AUDIENCE) {
    return <AudienceTypeDropdown onChange={onChange} value={params?.[AudienceOption.INTENDED_AUDIENCE]?.val} />;
  }

  if (selectedType === AudienceOption.MATCHED_USERS) {
    return <MatchedUsersDropdown accountUid={accountUid} onChange={onChange} params={params} />;
  }

  if (selectedType === AudienceOption.OPEN_AUDIENCES) {
    return (
      <SegmentsDropdown
        value={params?.[AudienceOption.OPEN_AUDIENCES]?.val ?? ''}
        onChange={onChange}
        placeholder={t('[ Select an Audience Segment ]')}
        options={options}
        isLoading={isLoading}
        {...(isOa && {
          label: t('Choose Audience'),
          placeholder: t('[ Select Audience ]'),
          renderOptions: {
            childrenLocation: 'above',
            renderChildren: option => <StatusIcon status={options?.[option]?.status} />,
            tooltipMessage: option => options?.[option]?.status ?? '',
            tooltipPlacement: 'left',
          },
        })}
      />
    );
  }

  return null;
};
