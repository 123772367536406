import type { MetacategoryData } from '@openx/types/targeting/metacategory';
import type { TargetingReadOnlyProps } from '@openx/types/targeting/targeting';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { TargetingReadOnly } from '../../shared';

import { MetacategoryItems } from './MetacategoryItems';

export const MetacategoryReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
}: TargetingReadOnlyProps<Partial<MetacategoryData>>): JSX.Element => {
  const { exclude_mfa, excludes, includes, keywords, inter_dimension_operator } = targetingParams || {};
  const isMetacategoryEmpty =
    !excludes?.length && !includes?.val?.length && !keywords?.includes?.length && !keywords?.excludes?.length;

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={isMetacategoryEmpty}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {!isMetacategoryEmpty && (
        <MetacategoryItems
          targetingParams={{
            exclude_mfa: exclude_mfa || false,
            excludes: excludes || [],
            includes: includes || { op: Criteria.ANY, val: [] },
            inter_dimension_operator: inter_dimension_operator || Criteria.ANY,
            keywords: {
              excludes: keywords?.excludes || [],
              includes: keywords?.includes || [],
            },
          }}
        />
      )}
    </TargetingReadOnly>
  );
};
