import { useCallback, useEffect } from 'react';

import type { CategoryData, CategoryFormState } from '@openx/types/targeting/category';
import type { TargetingFields } from '@openx/types/targeting/targeting';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { useResetToAccountDefault } from '../../shared/hooks/';

type ResetToDefaultProps = {
  accountUid?: string;
  field: TargetingFields;
  initState: CategoryFormState;
  onFieldUpdate: (field: TargetingFields, newData: CategoryData | null) => void;
  onRemoveAll: () => void;
  updateParams: (value: CategoryFormState) => void;
};

export const useResetCategoryToAccountDefault = ({
  accountUid,
  field,
  initState,
  onFieldUpdate,
  onRemoveAll,
  updateParams,
}: ResetToDefaultProps) => {
  const { blockedCategories, inheritanceInfoComponent, shouldDisplayResetButton, shouldTriggerUpdate } =
    useResetToAccountDefault({
      accountUid,
      initState,
    });

  const handleResetToAccountDefault = useCallback(() => {
    updateParams({ op: Intersect.NOT_INTERSECTS, val: new Set(blockedCategories) });
  }, [blockedCategories, updateParams]);

  useEffect(() => {
    if (shouldTriggerUpdate) {
      if (!blockedCategories.length) {
        onRemoveAll();
        return;
      }

      updateParams({ op: Intersect.NOT_INTERSECTS, val: new Set(blockedCategories) });
      onFieldUpdate(field, { op: Intersect.NOT_INTERSECTS, val: blockedCategories.join(',') });
    }
  }, [blockedCategories, field, onRemoveAll, onFieldUpdate, shouldTriggerUpdate, updateParams]);

  return { handleResetToAccountDefault, inheritanceInfoComponent, shouldDisplayResetButton };
};
