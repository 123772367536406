import { FormControl, FormControlLabel, FormHelperText, Switch, type SwitchProps } from '@mui/material';
import type { ReactNode } from 'react';
import { type Control, type FieldPath, type FieldValues, useController } from 'react-hook-form';

export type MuiSwitchProps = SwitchProps & { 'data-test'?: string };

export type RHFMuiSwitchProps<T extends FieldValues> = Omit<MuiSwitchProps, 'name'> & {
  name: FieldPath<T>;
  control?: Control<T>;
  label: ReactNode;
  hideValidationErrors?: boolean;
};

export function RHFSwitchField<T extends Record<string, unknown> = Record<string, string>>({
  name,
  control,
  label,
  hideValidationErrors = false,
  ...rest
}: RHFMuiSwitchProps<T>): JSX.Element {
  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
  });

  const defaultProps: MuiSwitchProps = {
    color: 'primary',
    disableRipple: true,
  };

  const helperText = error?.message;
  const parentDataTest = rest['data-test'];
  rest['data-test'] = `${rest['data-test']}-switch`;

  return (
    <FormControl error={invalid} data-test={parentDataTest}>
      <FormControlLabel
        control={
          <Switch
            {...defaultProps}
            {...field}
            {...rest}
            checked={rest.checked ?? !!field.value}
            disabled={isSubmitting || rest.disabled}
          />
        }
        label={label}
        data-test={`${parentDataTest}-label`}
      />
      {!hideValidationErrors && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
