import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useVideoDurationOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isVideoDurationLoading,
    data: videoDurations,
    refetch: refetchVideoDuration,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch video duration options'),
    optionType: OptionsType.VIDEO_DURATION,
    queryOptions,
  });

  return {
    isVideoDurationLoading,
    refetchVideoDuration,
    videoDurations,
  };
}
