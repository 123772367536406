import { useEffect, useState } from 'react';

import { validateEpisodeRange } from './utils';

const INITIAL_FIELD_VALUE = { error: null, value: '' };

type Field = {
  value: string;
  error?: string | null;
};

export function useEpisodeValidation() {
  const [fromField, setFromField] = useState<Field>(INITIAL_FIELD_VALUE);
  const [toField, setToField] = useState<Field>(INITIAL_FIELD_VALUE);

  const areFieldsEmpty = !fromField.value || !toField.value;
  const areFieldsValid = !fromField.error && !toField.error;

  const resetFields = () => {
    setFromField(INITIAL_FIELD_VALUE);
    setToField(INITIAL_FIELD_VALUE);
  };

  const onChange = (name: 'from' | 'to', value: string) => {
    if (name === 'from') setFromField({ value });

    if (name === 'to') setToField({ value });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: in order not to trigger forever-loop
  useEffect(() => {
    const { error: fromError } = validateEpisodeRange({
      currentValue: fromField.value,
      fromValue: fromField.value,
      name: 'from',
      toValue: toField.value,
    });

    const { error: toError } = validateEpisodeRange({
      currentValue: toField.value,
      fromValue: fromField.value,
      name: 'to',
      toValue: toField.value,
    });

    fromField.error !== null && setFromField(prevFromField => ({ ...prevFromField, error: fromError }));
    toField.error !== null && setToField(prevToField => ({ ...prevToField, error: toError }));
  }, [fromField.value, toField.value]);

  return {
    fromField,
    isValid: areFieldsValid && !areFieldsEmpty,
    onChange,
    resetFields,
    toField,
  };
}
