import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WhenLoaded } from '@openx/components/core/lib/Loader/WhenLoaded';
import { type InventoryAndContentState, InventoryContentOption } from '@openx/types/targeting/inventoryAndContent';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { useTargetingContext } from '../../../utils/context';
import { DimensionChipLine, FiltersTitle, SelectedItems, Switch } from '../../shared';
import { useInventoryAndContentLabels } from '../../shared/InventoryAndContent/constants';
import {
  resetFilter,
  setComparisonOpValue,
  setContentCategoryOp,
} from '../../shared/InventoryAndContent/inventoryHelpers';
import type { TargetingItemsProps } from '../../types';

export const AdunitItems = ({
  dispatchInventory = () => {},
  readonly = true,
  targetingParams,
  isNotLastItem,
  switchState: chipLineSwitchState,
}: TargetingItemsProps<InventoryAndContentState>) => {
  const { t } = useTranslation();

  const optionPayload = targetingParams[InventoryContentOption.ADUNIT_SIZE];
  const { op: dimension, val } = optionPayload;

  const labels = useInventoryAndContentLabels();
  const label = labels[InventoryContentOption.ADUNIT_SIZE].label;
  const { usePmpSizesFetch } = useTargetingContext();

  const { isSizesLoading, sizes } = usePmpSizesFetch();

  const switchState = dimension === Intersect.NOT_INTERSECTS;
  const filtersTitle = dimension === Intersect.NOT_INTERSECTS ? t('Excluded') : t('Included');
  const itemsCount = val.length;

  return (
    <WhenLoaded loading={isSizesLoading}>
      <Grid data-test="sub-section">
        <FiltersTitle
          onClear={() =>
            dispatchInventory({
              payload: {
                contentOption: InventoryContentOption.ADUNIT_SIZE,
              },
              type: resetFilter,
            })
          }
          title={`${label} [ ${itemsCount} ]`}
          readonly={readonly}
        />
        {!readonly && (
          <Switch
            switchState={switchState}
            onSwitchChange={() =>
              dispatchInventory({
                payload: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                type: setContentCategoryOp[InventoryContentOption.ADUNIT_SIZE],
              })
            }
            switchOffLabel={t('include')}
            switchOnLabel={t('exclude')}
          />
        )}

        <Grid data-test="section-items">
          <SelectedItems
            filtersTitle={filtersTitle}
            isAddIcon={dimension === Intersect.INTERSECTS}
            selectedItems={val}
            readonly={readonly}
            handleChange={values => {
              dispatchInventory({
                payload: values,
                type: setComparisonOpValue[InventoryContentOption.ADUNIT_SIZE],
              });
            }}
            getItemLabel={i => sizes[i]?.name || i}
            isRemovingAll={true}
            dataTest="adunit-size"
          />
        </Grid>
        {isNotLastItem && <DimensionChipLine switchState={chipLineSwitchState} />}
      </Grid>
    </WhenLoaded>
  );
};
