import type { SxProps } from '@mui/material';

import type { CriteriaValue } from '@openx/types/targeting/targetingValuesTypes';

import { StyledBox } from './components';
import { useGetDimensionLabels } from './hooks';

export const DimensionChip = ({
  targetingDimension,
  isInsideOption = false,
  sx,
}: {
  targetingDimension: CriteriaValue;
  isInsideOption?: boolean;
  sx?: SxProps;
}): JSX.Element => {
  const { dimensionLabels } = useGetDimensionLabels();

  return (
    <StyledBox component="span" data-test={`dimension-${isInsideOption}`} isInside={isInsideOption} sx={sx}>
      {dimensionLabels[targetingDimension]}
    </StyledBox>
  );
};
