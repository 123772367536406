import { type Dispatch, type SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import type { AdvancedTargetingData, ContextualSegmentsV2FormState } from '@openx/types/targeting/advancedTargeting';
import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

import { BulkAddDrawer } from '../../shared';

import { comparisonOptionLabels, filteredTypeOptions, getBulkPlaceholder } from './constants';
import { validateBulkContextualSegments } from './utils';

type ContextualSegmentsBulkV2Props = {
  isOpen: boolean;
  closeDrawer: () => void;
  updateParams: Dispatch<SetStateAction<AdvancedTargetingData>>;
  contextualItems: ContextualSegmentsV2FormState;
};

export const ContextualSegmentsBulkV2 = ({
  isOpen,
  closeDrawer,
  contextualItems,
  updateParams,
}: ContextualSegmentsBulkV2Props) => {
  const { t } = useTranslation();
  const [comparisonType, setComparisonType] = useState<ComparisonType.INTERSECTS | ComparisonType.NOT_INTERSECTS>(
    ComparisonType.INTERSECTS
  );
  const optionLabels = useMemo(() => comparisonOptionLabels(), []);
  const addBulkHandler = useCallback(
    (addedItems: string[]) => {
      const inclusionKey = comparisonType === ComparisonType.INTERSECTS ? 'includes' : 'excludes';

      updateParams(prevState => {
        const alreadyOnTheList = prevState.contextualV2 ? prevState.contextualV2[inclusionKey] : [];

        return {
          ...prevState,
          contextualV2: {
            ...prevState.contextualV2,
            [inclusionKey]: [...alreadyOnTheList, ...addedItems],
            op: comparisonType,
          },
        };
      });

      closeDrawer();
    },
    [closeDrawer, comparisonType, updateParams]
  );

  return (
    isOpen && (
      <BulkAddDrawer
        onClose={closeDrawer}
        onApply={addBulkHandler}
        placeholder={getBulkPlaceholder()}
        validate={items => validateBulkContextualSegments(items, contextualItems)}
      >
        <AutoComplete
          options={filteredTypeOptions}
          renderOptions={{
            dataTest: 'comparsion-option',
          }}
          getOptionLabel={option => optionLabels[option] ?? ''}
          textFieldReadOnly
          disableClearable
          data-test={'comparison-options'}
          textFieldProps={{
            label: t('Comparison Type'),
            placeholder: t('Select Type'),
          }}
          value={comparisonType}
          onChange={(_, value) => {
            setComparisonType(value);
          }}
        />
      </BulkAddDrawer>
    )
  );
};
