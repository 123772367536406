import { createRoot } from 'react-dom/client';

import { i18nConfigure } from '@openx/utils/i18n/i18n';
import { initGA } from '@openx/utils/lib/googleAnalytics';

import { GA_ORIGIN_DOMAIN, GA_TRACKING_ID } from 'config';

import { App } from './App';

i18nConfigure();

initGA(GA_TRACKING_ID, !window.origin.includes(GA_ORIGIN_DOMAIN));

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(<App />);
