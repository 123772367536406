import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';

import { Button } from '@openx/components/core/lib/Button/Button';
import type { BaseRow, RowActionProps, RowActionState } from '@openx/types/tableHelpers';

import MenuButtonItem from './MenuButtonItem';

export interface MenuButtonProps<RowT extends BaseRow> {
  rowData: RowT;
  menuItems: RowActionProps<RowT>[];
}

export default function MenuButton<RowT extends BaseRow>(props: MenuButtonProps<RowT>): JSX.Element {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { menuItems, rowData } = props;

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const isActionAllowed = useCallback(
    (action: RowActionState<RowT>): boolean => {
      if (typeof action === 'boolean') {
        return action;
      }

      return action(rowData);
    },
    [rowData]
  );

  const isMenuLoading = useMemo(
    () => menuItems.some(item => isActionAllowed(item.loading || false)),
    [isActionAllowed, menuItems]
  );

  const MenuItems = useMemo(
    (): JSX.Element => (
      <div data-test="actions-sub-menu">
        {menuItems.map(
          (
            {
              onClick,
              disabled = false,
              loading = false,
              allowed = true,
              hide = false,
              notAllowedMessage,
              label,
              'data-test': dataTest,
              ...restProps
            }: RowActionProps<RowT>,
            index
          ) => {
            const isItemAllowed = isActionAllowed(allowed);
            const isItemDisabled = isActionAllowed(disabled) || isActionAllowed(loading) || !isItemAllowed;
            const notAllowedMessageString =
              typeof notAllowedMessage === 'function' ? notAllowedMessage(rowData) : notAllowedMessage;
            const isAllowedMessage = !isItemAllowed
              ? notAllowedMessageString || 'You need permission to perform this action'
              : '';
            const labelString = typeof label === 'function' ? label(rowData) : label;
            const isActionHidden = typeof hide === 'function' ? hide(rowData) : hide;
            const dataTestString = typeof dataTest === 'function' ? dataTest(rowData) : dataTest;

            return (
              <MenuButtonItem
                onClick={e => {
                  onClick(rowData, e);
                  handleClose();
                }}
                isAllowedMessage={isAllowedMessage}
                disabled={isItemDisabled}
                hide={isActionHidden}
                key={index}
                label={labelString}
                data-test={dataTestString}
                {...restProps}
              />
            );
          }
        )}
      </div>
    ),
    [isActionAllowed, rowData, menuItems]
  );

  return (
    <span data-test={props['data-test']} ref={anchorRef}>
      <Button
        loading={isMenuLoading}
        size="small"
        icon
        onClick={() => {
          handleToggle();
        }}
        data-test="action-button"
      >
        <MoreVertIcon />
      </Button>
      {!isMenuLoading && (
        <Menu
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          MenuListProps={{ disablePadding: true }}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'center',
          }}
        >
          {MenuItems}
        </Menu>
      )}
    </span>
  );
}
