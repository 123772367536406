import { Alert, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { InvalidItemsError } from '../types';

import { AlertAction } from './components/AlertAction';
import { ErrorDetails } from './components/ErrorDetails';

const StyledAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;

  .MuiAlert-action {
    padding: ${({ theme }) => theme.spacing(0, 0, 0, 2)};
  }
`;

interface InvalidItemsProps {
  errorText?: string;
  errors: InvalidItemsError[];
}

export function InvalidItems(props: InvalidItemsProps) {
  const { errorText, errors } = props;
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = useState(false);

  const nonValidItems = useMemo(() => errors.reduce((acc, error) => acc + error.invalidItems.length, 0), [errors]);

  const itemText = nonValidItems > 1 ? t('items were') : t('item was');

  return (
    <>
      <StyledAlert
        severity="error"
        action={<AlertAction showErrors={showErrors} setShowErrors={setShowErrors} />}
        data-test="bulk-message"
      >
        <Typography variant="body1">
          <b>{t('{num} {itemText} removed', { itemText, num: nonValidItems })}</b>
        </Typography>
        {showErrors && errorText ? <Typography variant="body1">{errorText}</Typography> : null}
      </StyledAlert>
      {showErrors && <ErrorDetails errors={errors} />}
    </>
  );
}
