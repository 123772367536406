import { type Notification, type NotificationAction, NotificationActionType } from './actions';

export interface NotificationsState {
  notifications: Notification[];
}

const defaultState: NotificationsState = {
  notifications: [],
};

export const notificationsReducer = (
  state: NotificationsState = defaultState,
  action: NotificationAction
): NotificationsState => {
  switch (action.type) {
    case NotificationActionType.ENQUEUE_NOTIFICATION: {
      const isDuplicate = !!state.notifications.find(({ message }) => message === action.notification.message);

      if (isDuplicate) {
        return state;
      }

      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    }

    case NotificationActionType.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification !== action.notification),
      };

    default:
      return state;
  }
};
