import type { FunctionComponent } from 'react';

import type { Option, OptionState, OptionsMap } from '../options';

import type { AdvancedTargetingData } from './advancedTargeting';
import type { AudienceData } from './audience';
import type { CategoryData } from './category';
import type { ContentObjectApi, ContentObjectOption } from './contentObject';
import type { CustomVariablesData } from './customVariables';
import type { DomainData } from './domain';
import type { UrlTargeting } from './domains';
import type { FormatTypeData } from './formatType';
import type { GeographicTargetingApi } from './geographic';
import type { InventoryAndContentApi, TargetableInventoryList } from './inventoryAndContent';
import type { MetacategoryAPI } from './metacategory';
import type { SelectRateDataViewThroughRate, SelectRateDataViewability, SelectRateFormState } from './selectRate';
import type { CriteriaValue } from './targetingValuesTypes';
import type { TechnologyAndDevicesData } from './technologyAndDevices';
import type { VideoData } from './video';

export type InterDimensionOperator = 'AND' | 'OR';
export type InterDimensionOperatorDisplay = 'MATCH ALL' | 'MATCH ANY';

export interface TargetingDimension {
  inter_dimension_operator: InterDimensionOperator;
}

export interface Targeting extends TargetingDimension {
  audience?: AudienceData | null;
  content?: InventoryAndContentApi | null;
  custom?: CustomVariablesData | null;
  domain?: DomainData | null;
  exclude_non_direct?: boolean | null;
  geographic?: GeographicTargetingApi | null;
  include_open_bidding_inventory?: '1' | null;
  inventory_format?: FormatTypeData | null;
  metacategory?: MetacategoryAPI | null;
  technographic?: TechnologyAndDevicesData | null;
  video?: VideoData | null;
  viewability?: SelectRateDataViewability | null;
  vtr?: SelectRateDataViewThroughRate | null;
  instream_content?: ContentObjectApi | null;
}

//TODO: UIG-540 Convert to as Const & export Type
export enum TargetingFields {
  AD_SIZES = 'ads',
  DIMENSION = 'dimension',
  INVENTORY_AND_CONTENT = 'content',
  URL_TARGETING = 'url_targeting',
  DOMAIN = 'domain',
  CATEGORY = 'categories',
  GEOGRAPHIC = 'geographic',
  TECHNOLOGY_AND_DEVICES = 'technographic',
  EXCLUDE_INDIRECT = 'exclude_non_direct',
  INCLUDE_OPEN_BIDDING_INVENTORY = 'include_open_bidding_inventory',
  VIDEO = 'video',
  CONTEXTUAL_SEGMENTS = 'contextual',
  CUSTOM_VARIABLES = 'custom',
  ADVANCED_TARGETING = 'advanced_targeting',
  VIEWABILITY = 'viewability',
  VIEW_THROUGH_RATE = 'vtr',
  AUDIENCE = 'audience',
  FORMAT_TYPE = 'inventory_format',
  EXCLUDE_MFA = 'exclude_mfa',
  METACATEGORY = 'metacategory',
  CONTENT_OBJECT = 'instream_content',
}

export enum ListIconType {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}

export enum AllowBlockType {
  ALLOW = 'Allow',
  BLOCK = 'Block',
}

export type TargetingConfig = {
  name: string | JSX.Element;
  field: TargetingFields;
  TargetingComponent: FunctionComponent<TargetingProps<any, any>>;
  customOptions?: TargetingCustomOptions<any>;
};

export interface TargetingBase<T, K> {
  name: string | JSX.Element;
  targetingParams: T | null;
  accountUid?: string;
  readonly?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  customOptions?: TargetingCustomOptions<any>;
  targetingContext?: 'deal' | 'package';
  customValidationMethods?: K;
}

export interface TargetingProps<T, K = {}> extends TargetingBase<T, K> {
  onFieldUpdate?: (field: TargetingFields, newData: T | null) => void;
  field: TargetingFields;
}

export interface TargetingReadOnlyProps<T, K = {}> extends TargetingBase<T, K> {
  onOpenDrawerClick: () => void;
  onRemoveClick?: () => void;
  dataTest?: string;
}

export interface TargetingState {
  [TargetingFields.EXCLUDE_INDIRECT]: boolean;
  [TargetingFields.METACATEGORY]: MetacategoryAPI | null;
  [TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY]: boolean;
  [TargetingFields.DIMENSION]: CriteriaValue;
  [TargetingFields.INVENTORY_AND_CONTENT]: InventoryAndContentApi | null;
  [TargetingFields.URL_TARGETING]: UrlTargeting | null;
  [TargetingFields.CATEGORY]: CategoryData | null;
  [TargetingFields.GEOGRAPHIC]: GeographicTargetingApi | null;
  [TargetingFields.TECHNOLOGY_AND_DEVICES]: TechnologyAndDevicesData | null;
  [TargetingFields.VIDEO]: VideoData | null;
  [TargetingFields.VIEW_THROUGH_RATE]: SelectRateFormState | null;
  [TargetingFields.CUSTOM_VARIABLES]: AdvancedTargetingData | null;
  [TargetingFields.VIEWABILITY]: SelectRateFormState | null;
  [TargetingFields.FORMAT_TYPE]: FormatTypeData | null;
  [TargetingFields.AUDIENCE]: AudienceData | null;
  [TargetingFields.CONTENT_OBJECT]: ContentObjectApi | null;
}

export type TargetingCustomOptions<T> = {
  defaultOption: T;
  availableOptions: T[];
};

export interface InventoryFetchResponse {
  data: TargetableInventoryList | undefined;
  isLoading: boolean;
  refetch: unknown;
}
type OptionWithUid = Option & { uid?: string };
export type UseAccountOptionsFetch = () => {
  accounts: OptionState<OptionWithUid>;
};

export type OptionItem = { loading: boolean; options: OptionsMap };
export type UseOptionFetch = (type: ContentObjectOption, queryEnabled?: boolean) => OptionItem;

export type MarketProps = {
  name: string;
  id?: string;
};
