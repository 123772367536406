import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useBrowserOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isBrowserOptionsLoading,
    data: browsers,
    refetch: refetchBrowserOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch browser options'),
    optionType: OptionsType.BROWSER,
    queryOptions,
  });

  return {
    browsers,
    isBrowserOptionsLoading,
    refetchBrowserOptions,
  };
}
