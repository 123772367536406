import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type AudienceData, AudienceOption } from '@openx/types/targeting/audience';

import { type EidsOptionsFetchResponse, useTargetingContext } from '../../../utils/';
import { SelectedItems } from '../../shared/';

type EidsItemsProps = {
  onChange: (newData: AudienceData | null | ((prevState: AudienceData | null) => AudienceData | null)) => void;
  readonly: boolean;
  selectedTrafficFilterEidsArray: string[];
};

type TrafficFilterEidsOptionsFetch = {
  useTrafficFilterEidsOptionsFetch: () => EidsOptionsFetchResponse;
};

type RenderEidsItemsProps = EidsItemsProps & TrafficFilterEidsOptionsFetch;

export const EidsItems = ({ onChange, selectedTrafficFilterEidsArray, readonly }: EidsItemsProps) => {
  const { useTrafficFilterEidsOptionsFetch } = useTargetingContext();

  if (useTrafficFilterEidsOptionsFetch) {
    return (
      <RenderEidsItems
        onChange={onChange}
        selectedTrafficFilterEidsArray={selectedTrafficFilterEidsArray}
        readonly={readonly}
        useTrafficFilterEidsOptionsFetch={useTrafficFilterEidsOptionsFetch}
      />
    );
  }

  return null;
};

const RenderEidsItems = ({
  onChange,
  selectedTrafficFilterEidsArray,
  readonly,
  useTrafficFilterEidsOptionsFetch,
}: RenderEidsItemsProps) => {
  const { t } = useTranslation();
  const { trafficFilterEids } = useTrafficFilterEidsOptionsFetch();

  const handleChange = useCallback(
    (values: string[]) => {
      const selectedValues = new Set(values);
      onChange((params: AudienceData | null) => ({ ...params, [AudienceOption.TRAFFIC_FILTER_EIDS]: selectedValues }));
    },
    [onChange]
  );

  return (
    <SelectedItems
      isAddIcon
      filtersTitle={t('External User IDs:')}
      selectedItems={selectedTrafficFilterEidsArray}
      readonly={readonly}
      handleChange={handleChange}
      options={trafficFilterEids}
      dataTest={'traffic-filters-eids-selected-items'}
    />
  );
};
