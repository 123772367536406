import {
  type InventoryAndContentOptionPayload,
  type InventoryAndContentState,
  TargetableInventoryOption,
} from '@openx/types/targeting/inventoryAndContent';
import type { OptionEntriesProps } from '@openx/types/targeting/targetingValuesTypes';

const getComparisonsCount = (comparisonObject: InventoryAndContentOptionPayload, label: string) =>
  Object.values(comparisonObject.val).reduce((acc, val) => (val ? acc + val.length : acc), 0) ? label : null;

const inventoryAndContentSettingsCheckingMethods = {
  // [InventoryContentOption.PUBLISHER_ID]
  account(targetingSettings: InventoryAndContentState) {
    return targetingSettings['account'].val.length ? 'account' : null;
  },
  adunit(targetingSettings: InventoryAndContentState) {
    return targetingSettings['adunit'].val.length ? 'adunit' : null;
  },
  adunit_size(targetingSettings: InventoryAndContentState) {
    return targetingSettings['adunit_size'].val.length ? 'adunit_size' : null;
  },
  app_bundle_id(targetingSettings: InventoryAndContentState) {
    return getComparisonsCount(targetingSettings['app_bundle_id'], 'app_bundle_id') ? 'app_bundle_id' : null;
  },
  content_inter_dimension_operator() {},
  // [InventoryContentOption.INSTANCE_HASH]
  instance(targetingSettings: InventoryAndContentState) {
    return targetingSettings['instance'].val.length ? 'instance' : null;
  },
  inventory(targetingSettings: InventoryAndContentState) {
    const includes = targetingSettings['inventory'][TargetableInventoryOption.INCLUDES].length;
    const excludes = targetingSettings['inventory'][TargetableInventoryOption.EXCLUDES].length;

    return includes + excludes ? 'inventory' : null;
  },
  keywords(targetingSettings: InventoryAndContentState) {
    return targetingSettings['keywords'].val.length ? 'keywords' : null;
  },
  line_item_app_bundle_id(targetingSettings: InventoryAndContentState) {
    return targetingSettings['line_item_app_bundle_id'].val.length ? 'line_item_app_bundle_id' : null;
  },
  page_url(targetingSettings: InventoryAndContentState) {
    return getComparisonsCount(targetingSettings['page_url'], 'page_url') ? 'page_url' : null;
  },
  site(targetingSettings: InventoryAndContentState) {
    return targetingSettings['site'].val.length ? 'site' : null;
  },
};
export const getInventoriesWithSettings = (targetingSettings: InventoryAndContentState) => {
  return Object.keys(targetingSettings).filter(optionLabel =>
    inventoryAndContentSettingsCheckingMethods[optionLabel](targetingSettings)
  );
};

export const getSelectedOptionsCount = (optionsEntries: Array<OptionEntriesProps>): number =>
  //option[1] because its value of comparisonType object entries
  optionsEntries.map(option => option[1]).flat().length;
