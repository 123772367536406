import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';
import { useOptionsFetch } from '@openx/utils/apiHooks/generics/useOptionsFetch';

export function useLocationSourceOptionsFetch() {
  const { t } = useTranslation();
  const { isLoading, data: locationSourceOptions } = useOptionsFetch({
    errorMessage: t('Failed to fetch Location Source options'),
    optionType: OptionsType.LOCATION_SOURCE_OPTIONS,
  });

  return {
    isLoading,
    locationSourceOptions,
  };
}
