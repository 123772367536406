import { Button, Grid, useMediaQuery } from '@mui/material';

import { FieldDetails } from '@openx/components/core/lib/FieldDetails/FieldDetails';
import { PageSection } from '@openx/components/core/lib/PageSection/PageSection';
import { trackCreateDealConversion } from '@openx/utils/lib/googleAnalytics';

import { useDealActivationModal } from 'components/DealActivationModal';
import { Tags } from 'components/Tags';
import type { DealDetailsProps } from 'types';

import { GreenDeal } from '../components/GreenDeal';

import { StyledButtonContainerGrid, StyledContainerGrid, StyledDetailSectionContainer } from './styledComponents';

export const DealDetailsSection = ({ deal }: DealDetailsProps): JSX.Element => {
  const { openDealActivationModal } = useDealActivationModal();
  const matchesWidth = useMediaQuery('(max-width: 760px)');

  const gridItemWidth = matchesWidth ? 8 : 6;

  return (
    <StyledDetailSectionContainer>
      <PageSection divider title="Deal Details">
        <Grid item xs={gridItemWidth}>
          {deal.isGreen && <GreenDeal />}

          <StyledContainerGrid container spacing={4}>
            {deal.monthlyRequests && (
              <Grid item xs={gridItemWidth}>
                <FieldDetails title={'Monthly Requests'} value={deal.monthlyRequests.toLocaleString()} />
              </Grid>
            )}
            {deal.uniqueUsers && (
              <Grid item xs={gridItemWidth}>
                <FieldDetails title={'Unique Users'} value={deal.uniqueUsers.toLocaleString()} />
              </Grid>
            )}
          </StyledContainerGrid>

          {deal.description && <FieldDetails title={'Deal Description'} value={deal.description} />}

          <Grid container>
            {deal.tags && deal.tags.length > 0 && (
              <Grid item xs={12}>
                <Tags tags={deal.tags} handleTagClick={() => {}} />
              </Grid>
            )}

            <StyledButtonContainerGrid item xs={12}>
              <Button
                variant="contained"
                data-test="create-deal-button"
                onClick={() => {
                  trackCreateDealConversion();
                  openDealActivationModal(deal.name);
                }}
              >
                Create Deal
              </Button>
            </StyledButtonContainerGrid>
          </Grid>
        </Grid>
      </PageSection>
    </StyledDetailSectionContainer>
  );
};
