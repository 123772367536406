import type { TFunction } from 'i18next';

import { Intersect } from '@openx/types/targeting/targetingValuesTypes';
import {
  RewardedVideoOption,
  type VideoData,
  type VideoDataPayload,
  type VideoFormState,
  VideoOption,
  VideoSkipabilityOption,
  VideoSkipabilityValue,
  type VideoStatePayload,
  payloadVideoTypes,
} from '@openx/types/targeting/video';

function typeToApi(stateType: VideoStatePayload) {
  return {
    op: stateType.op,
    val: [...stateType.val].join(','),
  };
}

function typeToState(apitype: VideoDataPayload) {
  return {
    op: apitype.op,
    val: new Set(apitype.val.split(',')),
  };
}

export function mapVideoToApi(state: VideoFormState): VideoData | null {
  if (isEmptyState(state)) {
    return null;
  }

  const result: VideoData = {};

  payloadVideoTypes.forEach(type => {
    if (state[type].val.size) {
      result[type] = typeToApi(state[type]);
    }
  });

  if (state[VideoOption.REWARDED_VIDEO]) {
    result[VideoOption.REWARDED_VIDEO] = state[VideoOption.REWARDED_VIDEO];
  }

  if (state[VideoOption.VIDEO_SKIPABILITY]) {
    result[VideoOption.VIDEO_SKIPABILITY] = state[VideoOption.VIDEO_SKIPABILITY];
  }

  return result;
}

const getDefaultParams = (): VideoFormState => ({
  [VideoOption.REWARDED_VIDEO]: null,
  [VideoOption.MAX_AD_DURATION]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [VideoOption.PRESENTATION_FORMAT]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [VideoOption.IN_STREAM_VIDEO_POSITION]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [VideoOption.PLACEMENT]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [VideoOption.VIDEO_SKIPABILITY]: null,
});

export function mapVideoToFormState(video?: VideoData | null): VideoFormState {
  const defaultState = getDefaultParams();

  if (!video) return defaultState;

  payloadVideoTypes.forEach(type => {
    if (video[type]) {
      defaultState[type] = typeToState(video[type] as VideoDataPayload);
    }
  });

  if (video[VideoOption.REWARDED_VIDEO]) {
    defaultState[VideoOption.REWARDED_VIDEO] = video[VideoOption.REWARDED_VIDEO];
  }

  if (video[VideoOption.VIDEO_SKIPABILITY]) {
    defaultState[VideoOption.VIDEO_SKIPABILITY] = video[VideoOption.VIDEO_SKIPABILITY];
  }

  return defaultState;
}

export function isEmptyState(state?: VideoFormState | null): boolean {
  if (!state) {
    return true;
  }

  return (
    payloadVideoTypes.every(type => !state[type].val.size) &&
    !state[VideoOption.REWARDED_VIDEO] &&
    !state[VideoOption.VIDEO_SKIPABILITY]
  );
}

export function getTypePlaceholder(t: TFunction<'translation'>, type: VideoOption) {
  return {
    [VideoOption.REWARDED_VIDEO]: t('[ Select a Rewarded Video Option ]'),
    [VideoOption.IN_STREAM_VIDEO_POSITION]: t('[ Select an In-stream Video Position ]'),
    [VideoOption.PRESENTATION_FORMAT]: t('[ Select a Presentation Format ]'),
    [VideoOption.MAX_AD_DURATION]: t('[ Select Maximum Ad Duration ]'),
    [VideoOption.PLACEMENT]: t('[ Select Placement ]'),
    [VideoOption.VIDEO_SKIPABILITY]: t('[ Select Video Skippability ]'),
  }[type];
}

export function getRewardedVideoOptions(t: TFunction<'translation'>) {
  return {
    [RewardedVideoOption.EQUALS]: { id: `${RewardedVideoOption.EQUALS}`, name: t('Rewarded') },
    [RewardedVideoOption.DOES_NOT_EQUAL]: { id: `${RewardedVideoOption.DOES_NOT_EQUAL}`, name: t('Non-Rewarded') },
  };
}

export function getVideoSkipabilityOptions(t: TFunction<'translation'>) {
  return {
    [VideoSkipabilityValue.NON_SKIPPABLE]: { id: `${VideoSkipabilityOption.EQUALS}`, name: t('Non-Skippable') },
    [VideoSkipabilityValue.SKIPPABLE]: { id: `${VideoSkipabilityOption.EQUALS}`, name: t('Skippable') },
  };
}

export function getOptionKeys<T extends object>(options: T): Array<keyof T> {
  return Object.keys(options) as Array<keyof T>;
}
