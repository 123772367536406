import type { AnyAction } from '@reduxjs/toolkit';
import { type KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core/lib/TextField/TextField';
import type { IntersectOptionPayload, InventoryContentIdsTypeOption } from '@openx/types/targeting/inventoryAndContent';

import { setComparisonOpValue } from '../../../shared/InventoryAndContent/inventoryHelpers';
import {
  getTypeInventoryIDsPlaceholder,
  validateSingleRecordSubmissionInventoryIDs,
} from '../../../shared/InventoryAndContent/utils';

type InventoryIdsProps = {
  dispatchInventory: (action: AnyAction) => void;
  inventoryContent: IntersectOptionPayload;
  selectedType: InventoryContentIdsTypeOption;
};

enum KeyboardInput {
  ENTER = 'Enter',
}

export const InventoryIDs = ({ dispatchInventory, inventoryContent, selectedType }: InventoryIdsProps) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setErrorText('');
  }, [selectedType]);

  const handleOnKeyUp = useCallback(
    (event: KeyboardEvent) => {
      const targetElement = event.target as HTMLInputElement;
      const { value } = targetElement;
      const inventoryContentOptionComparisonData = inventoryContent.val || [];

      if (event.key === KeyboardInput.ENTER) {
        const validationResult = validateSingleRecordSubmissionInventoryIDs(
          value,
          new Set([...inventoryContent.val]) || [],
          selectedType
        );

        if (validationResult?.error) {
          setErrorText(t(validationResult.errorMessage));
          return false;
        }

        dispatchInventory({
          payload: [...inventoryContentOptionComparisonData, value],
          type: setComparisonOpValue[selectedType],
        });
        targetElement.value = '';
      }

      setErrorText('');
    },
    [dispatchInventory, inventoryContent, selectedType, t]
  );

  return (
    <TextField
      name="inventory_ids"
      placeholder={getTypeInventoryIDsPlaceholder(t, selectedType)}
      fullWidth
      label=" "
      data-test="inventory-and-content-input"
      autoComplete="off"
      onKeyUp={handleOnKeyUp}
      error={!!errorText}
      helperText={errorText}
    />
  );
};
