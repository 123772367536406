import type { GeoLocationTypes, GeoLocationsList } from '../geoLocation';

import type { Intersect } from './targetingValuesTypes';

export enum GeographicOption {
  BOUNDING_BOX = 'bounding_box',
  CIRCLES = 'circles',
  INCLUDES = 'includes',
  EXCLUDES = 'excludes',
  EXCLUDES_SUBSET = 'excludes_subset',
  SOURCE = 'location_source',
}

export const BoundingBoxApiOptions = {
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
} as const;

export const BoundingBoxOptions = {
  LATMAX: 'latMax',
  LATMIN: 'latMin',
  LONGMAX: 'longMax',
  LONGMIN: 'longMin',
} as const;

export type BoundingBoxOption = (typeof BoundingBoxOptions)[keyof typeof BoundingBoxOptions];

export interface GeoCirclesApi {
  op: '<';
  val: string;
}

export type RadiusCircle = { lat: string; lon: string; rad: string };

export type GeoDataApi = {
  [K in GeoLocationTypes]?: string | null;
};

export type LocationSourceApi = {
  op: Intersect;
  val: string;
};

export type LocationSource = {
  op: Intersect;
  val: Set<string>;
};

export type BoundingBoxApi = {
  val: `${string} - ${string}`;
};

export type BoundingBox = {
  [BoundingBoxOptions.LATMIN]?: string;
  [BoundingBoxOptions.LATMAX]?: string;
  [BoundingBoxOptions.LONGMIN]?: string;
  [BoundingBoxOptions.LONGMAX]?: string;
};

export interface GeographicTargetingApi {
  [GeographicOption.CIRCLES]?: GeoCirclesApi | null;
  [GeographicOption.EXCLUDES]?: GeoDataApi | null;
  [GeographicOption.INCLUDES]?: GeoDataApi | null;
  [BoundingBoxApiOptions.LATITUDE]?: BoundingBoxApi | null;
  [BoundingBoxApiOptions.LONGITUDE]?: BoundingBoxApi | null;
  [GeographicOption.SOURCE]?: LocationSourceApi | null;
}

export interface GeographicTargeting {
  [GeographicOption.BOUNDING_BOX]: BoundingBox;
  [GeographicOption.CIRCLES]: RadiusCircle[];
  [GeographicOption.INCLUDES]: GeoLocationsList;
  [GeographicOption.EXCLUDES]: GeoLocationsList;
  [GeographicOption.EXCLUDES_SUBSET]: GeoLocationsList;
  [GeographicOption.SOURCE]: LocationSource;
}

export type GeographicTargetingState = {
  [GeographicOption.BOUNDING_BOX]: BoundingBox;
  [GeographicOption.CIRCLES]: RadiusCircle[];
  [GeographicOption.INCLUDES]: GeoLocationType;
  [GeographicOption.EXCLUDES]: GeoLocationType;
  [GeographicOption.EXCLUDES_SUBSET]: GeoLocationsList;
  [GeographicOption.SOURCE]: LocationSource;
};

export type GeoLocationType = {
  continent: GeoLocationsList;
  country: GeoLocationsList;
  region: GeoLocationsList;
  state: GeoLocationsList;
  dma: GeoLocationsList;
  msa: GeoLocationsList;
  city: GeoLocationsList;
  postal_code: GeoLocationsList;
};

export const TargetingLocation = {
  GLOBAL: 'GLOBAL',
  UNSET: 'UNSET',
  US_ONLY: 'US_ONLY',
} as const;

export type TargetingLocationType = (typeof TargetingLocation)[keyof typeof TargetingLocation];
