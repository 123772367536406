import type { FieldValues } from 'react-hook-form';
import type { Location } from 'react-router-dom';

type CreateAudienceNavigationStateParam = {
  formValues: FieldValues;
  location: Location;
  accountId;
};
export const createAudienceNavigationState = ({
  formValues,
  location,
  accountId,
}: CreateAudienceNavigationStateParam) => {
  const formDataKey: string = location.pathname.startsWith('/inventory/packages') ? 'package' : 'deal';
  const isWithinDeal = formDataKey === 'deal';
  const useUniqueTargeting = isWithinDeal ? true : undefined;

  return {
    accountId,
    [formDataKey]: formValues,
    redirectPath: location.pathname,
    useUniqueTargeting,
  };
};
