import { createReducer } from '@reduxjs/toolkit';
import { useReducer } from 'react';

import {
  type InventoryAndContentState,
  InventoryContentOption,
  TargetableInventoryOption,
} from '@openx/types/targeting/inventoryAndContent';

import {
  getEmptyInventoryState,
  resetContent,
  resetFilter,
  setComparisonOpValue,
  setContent,
  setContentCategoryOp,
  setContentInterDimentionOperator,
  setInventories,
} from '../../shared/InventoryAndContent/inventoryHelpers';

export const useInventoryAndContent = (initialState: InventoryAndContentState) => {
  // we using reduxjs/toolkit so we don't need to spread object data
  const reducer = createReducer(initialState, builder => {
    builder
      .addCase(setContent, (_state, action) => {
        return action.payload;
      })
      .addCase(resetContent, () => {
        return getEmptyInventoryState();
      })
      .addCase(resetFilter, (state, action) => {
        const { contentOption } = action.payload;
        state[contentOption] = getEmptyInventoryState()[contentOption] as any;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.APP_BUNDLE_ID], (state, action) => {
        const { values, comparisonType } = action.payload;
        if (values.length) {
          state[InventoryContentOption.APP_BUNDLE_ID].val[comparisonType] = values;
        } else {
          delete state[InventoryContentOption.APP_BUNDLE_ID].val[comparisonType];
        }
      })
      .addCase(setContentCategoryOp[InventoryContentOption.APP_BUNDLE_ID], (state, action) => {
        state[InventoryContentOption.APP_BUNDLE_ID].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.PAGE_URL], (state, action) => {
        const { values, comparisonType } = action.payload;
        if (values.length) {
          state[InventoryContentOption.PAGE_URL].val[comparisonType] = values;
        } else {
          delete state[InventoryContentOption.PAGE_URL].val[comparisonType];
        }
      })
      .addCase(setContentCategoryOp[InventoryContentOption.PAGE_URL], (state, action) => {
        state[InventoryContentOption.PAGE_URL].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.ADUNIT], (state, action) => {
        state[InventoryContentOption.ADUNIT].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.ADUNIT], (state, action) => {
        state[InventoryContentOption.ADUNIT].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.ADUNIT_SIZE], (state, action) => {
        state[InventoryContentOption.ADUNIT_SIZE].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.ADUNIT_SIZE], (state, action) => {
        state[InventoryContentOption.ADUNIT_SIZE].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.PUBLISHER_ID], (state, action) => {
        state[InventoryContentOption.PUBLISHER_ID].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.PUBLISHER_ID], (state, action) => {
        state[InventoryContentOption.PUBLISHER_ID].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.INSTANCE_HASH], (state, action) => {
        state[InventoryContentOption.INSTANCE_HASH].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.INSTANCE_HASH], (state, action) => {
        state[InventoryContentOption.INSTANCE_HASH].op = action.payload;
      })
      .addCase(setInventories[TargetableInventoryOption.INCLUDES], (state, action) => {
        state[InventoryContentOption.INVENTORY][TargetableInventoryOption.INCLUDES] = action.payload;
      })
      .addCase(setInventories[TargetableInventoryOption.EXCLUDES], (state, action) => {
        state[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES] = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.SITE], (state, action) => {
        state[InventoryContentOption.SITE].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.SITE], (state, action) => {
        state[InventoryContentOption.SITE].op = action.payload;
      })
      .addCase(setContentInterDimentionOperator, (state, action) => {
        state[InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR] = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.KEYWORDS], (state, action) => {
        state[InventoryContentOption.KEYWORDS].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.KEYWORDS], (state, action) => {
        state[InventoryContentOption.KEYWORDS].op = action.payload;
      })
      .addCase(setComparisonOpValue[InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID], (state, action) => {
        state[InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID].val = action.payload;
      })
      .addCase(setContentCategoryOp[InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID], (state, action) => {
        state[InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID].op = action.payload;
      })
      .addDefaultCase(() => {});
  });

  const [inventoryState, dispatchInventory] = useReducer(reducer, initialState);

  return { dispatchInventory, inventoryState };
};
