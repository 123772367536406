import type { BaseRow, Columns } from '@openx/types/tableHelpers';

import type { CustomColumnConfig } from './config';

export const recalculateColumsWidth = <RowT extends BaseRow>(columns: Columns<RowT>) => {
  const newWidth = columns.reduce((acc, col) => {
    if (!col.hide) {
      return acc + parseInt(col.width || '0');
    }
    return acc;
  }, 0);

  const diff = 100 - newWidth;
  return columns.map(col => {
    const currentWidth = parseInt(col.width || '0');
    return { ...col, calcWidth: `${currentWidth + (currentWidth / newWidth) * diff}%` };
  });
};

export const loadCustomColumnsConfig = <RowT extends BaseRow>(name: string | undefined, columns: Columns<RowT>) => {
  const config = name && localStorage.getItem(`tableHeaderConfig-${name}`);

  if (!config) {
    return recalculateColumsWidth(columns);
  }

  const serializeConfig: CustomColumnConfig[] = JSON.parse(config);

  const result = serializeConfig.reduce((acc: Columns<RowT>, loadedColumn) => {
    const col = columns.find(c => c.key === loadedColumn.key);
    col && acc.push({ ...col, ...loadedColumn });

    return acc;
  }, []);

  if (columns.length !== result.length) {
    return columns;
  }

  const calcColsWidth = recalculateColumsWidth(result);

  return calcColsWidth;
};

export const groupData = <RowT extends BaseRow>(data: RowT[], groupBy?: keyof RowT): Record<string, RowT[]> => {
  if (!groupBy) return { '': data };
  return data.reduce(
    (acc, item) => {
      const groupKey = String(item[groupBy]) || 'Ungrouped';
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    },
    {} as Record<string, RowT[]>
  );
};
