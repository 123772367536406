import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { OptionsMap } from '@openx/types/options';
import { TechnologyAndDevicesOption, type UserAgentPayload } from '@openx/types/targeting/technologyAndDevices';
import { produceSortedOptionsKeys } from '@openx/utils/lib/sortedOptions';

import { useTargetingContext } from '../../../utils';
import { OptionsDropdown } from '../../shared';
import { optionsToFetch } from '../constants';
import { sortBrowserOptionsKeys, transformOptionsToMatchLegacyApi } from '../utils';

import { MobileCarrierDropdown } from './MobileCarrierDropdown';
import { SingleSelectDropdown } from './SingleSelectDropdown';
import { UserAgentDropdown } from './UserAgentDropdown';

type TopBoxProps = {
  items: Set<string> | UserAgentPayload;
  onChange: (options: Set<string> | UserAgentPayload) => void;
  selectedType: TechnologyAndDevicesOption;
};

export const TopBoxValueField = ({ items, onChange, selectedType }: TopBoxProps) => {
  const { t } = useTranslation();
  const { useTechnologyOptionsFetch } = useTargetingContext();

  const shouldFetchOptions = !!optionsToFetch[selectedType];
  const { options, isLoading } = useTechnologyOptionsFetch(selectedType, shouldFetchOptions);
  let transformedOptions: OptionsMap = {};

  if (selectedType === TechnologyAndDevicesOption.SCREEN_RESOLUTION) {
    transformedOptions = transformOptionsToMatchLegacyApi(options);
  }

  const optionsToDisplay = Object.keys(transformedOptions).length ? transformedOptions : options;

  const labels = useMemo(
    () => ({
      [TechnologyAndDevicesOption.DEVICE_TYPE]: t('[ Select Device Types ]'),
      [TechnologyAndDevicesOption.OS]: t('[ Select an Operating System ]'),
      [TechnologyAndDevicesOption.BROWSER]: t('[ Select a Browser ]'),
      [TechnologyAndDevicesOption.LANGUAGE]: t('[ Select a Language ]'),
      [TechnologyAndDevicesOption.API_FRAMEWORK]: t('[ Select an API Frameworks ]'),
      [TechnologyAndDevicesOption.CONNECTION_SPEED]: t('[ Select a Connection Speed ]'),
      [TechnologyAndDevicesOption.CONNECTION_TYPE]: t('[ Select a Connection Type ]'),
      [TechnologyAndDevicesOption.DEVICE]: t('[ Select a Device ]'),
      [TechnologyAndDevicesOption.DEVICE_ID]: t('[ Select a Device ID ]'),
      [TechnologyAndDevicesOption.HTTPS]: t('[ Select a HTTPS ]'),
      [TechnologyAndDevicesOption.MOBILE_CARRIER]: t('[ Select a Mobile Carrier ]'),
      [TechnologyAndDevicesOption.MKT_COOKIES]: t('[ Select a Cookies ]'),
      [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: t('[ Select a Screen Resolution ]'),
      [TechnologyAndDevicesOption.USER_AGENT]: t('[ Select an User Agent ]'),
    }),
    [t]
  );

  const label = labels[selectedType];

  const getSortedKeys = useCallback(
    options => {
      if (selectedType !== TechnologyAndDevicesOption.BROWSER) {
        return produceSortedOptionsKeys(options);
      }
      return sortBrowserOptionsKeys(options);
    },
    [selectedType]
  );

  if (items instanceof Set) {
    if (selectedType === TechnologyAndDevicesOption.MOBILE_CARRIER) {
      return (
        <MobileCarrierDropdown
          loading={isLoading}
          options={options}
          onChange={onChange}
          selectedOptions={items}
          placeholder={label}
          selectedType={selectedType}
        />
      );
    }

    if (
      selectedType === TechnologyAndDevicesOption.MKT_COOKIES ||
      selectedType === TechnologyAndDevicesOption.DEVICE_ID ||
      selectedType === TechnologyAndDevicesOption.HTTPS
    ) {
      return <SingleSelectDropdown onChange={onChange} selectedType={selectedType} />;
    }

    return (
      <OptionsDropdown
        loading={isLoading}
        options={optionsToDisplay}
        onChange={values => onChange(values)}
        selectedOptions={items}
        placeholder={label}
        getSortedKeys={getSortedKeys}
        dataTest="technology-input"
      />
    );
  }

  return <UserAgentDropdown onChange={onChange} selectedOptions={items} />;
};
