import { type SxProps, type Theme, Typography } from '@mui/material';

const handleBackgroundType = (color: string, theme: Theme): string | undefined => {
  switch (color) {
    case 'active':
    case 'activated':
    case 'allowed':
    case 'exported':
    case 'reviewed':
    case 'running':
      return theme.palette.success.main;
    case 'banned':
    case 'error':
    case 'stopped':
    case 'activate-failed':
    case 'expire-failed':
    case 'export-failed':
      return theme.palette.error.main;
    case 'publish-failed':
      return theme.palette.background?.tomato;
    case 'exporting':
    case 'goalreached':
    case 'publishing':
    case 'initializing':
      return theme.palette.primary.main;
    case 'expired':
    case 'ignored':
    case 'inactive':
    case 'notallowed':
    case 'purged':
      return theme.palette.action.inactive;
    case 'draft':
    case 'new':
    case 'paused':
    case 'pending':
    case 'warning':
    case 'expiring':
      return theme.palette.warning.main;
    default:
      return undefined;
  }
};

const StyledSpan = props => (
  <Typography
    data-color={props.color}
    data-test={props['data-test']}
    variant="caption"
    sx={{
      '&:nth-last-of-type(2)': {
        marginRight: 1,
      },
      backgroundColor: theme => handleBackgroundType(props.color, theme),
      borderRadius: '100%',
      display: 'inline-block',
      height: 8,
      marginRight: 0.25,
      width: 8,
      ...(props?.sx ?? {}),
    }}
  />
);

export type IndicatorStatus =
  | 'active'
  | 'activated'
  | 'allowed'
  | 'banned'
  | 'draft'
  | 'error'
  | 'expired'
  | 'expiring'
  | 'exported'
  | 'exporting'
  | 'export-failed'
  | 'goalreached'
  | 'ignored'
  | 'inactive'
  | 'initializing'
  | 'new'
  | 'notallowed'
  | 'paused'
  | 'pending'
  | 'publishing'
  | 'publish-failed'
  | 'purged'
  | 'reviewed'
  | 'running'
  | 'stopped'
  | 'warning';

export interface StatusIndicatorProps {
  status?: string;
  label?: string;
  className?: string;
  'data-test'?: string;
  iconStyles?: SxProps;
  textStyles?: SxProps;
  wrapperStyles?: SxProps;
}

export function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
  const { status = 'inactive', className, label, iconStyles, textStyles, wrapperStyles } = props;
  const statusKebabLowerCase = status.toLowerCase().replace('_', '-').replace(' ', '');

  return (
    <Typography component="span" className={className} data-test={props['data-test']} sx={wrapperStyles}>
      <StyledSpan data-test={`status-${statusKebabLowerCase}`} color={statusKebabLowerCase} sx={iconStyles} />
      {status === 'publishing' && (
        <>
          <StyledSpan data-test={`status-${statusKebabLowerCase}`} color="inactive" sx={iconStyles} />
          <StyledSpan data-test={`status-${statusKebabLowerCase}`} color="inactive" sx={iconStyles} />
        </>
      )}
      <Typography variant="overline" color="textSecondary" sx={{ wordBreak: 'break-all', ...(textStyles ?? {}) }}>
        {label?.toUpperCase() || status.replace(/[-_]/g, ' ').toUpperCase()}
      </Typography>
    </Typography>
  );
}
