import { Grid, TextField, styled } from '@mui/material';
import { type KeyboardEvent, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type BoundingBoxOption,
  BoundingBoxOptions,
  type GeographicTargetingState,
} from '@openx/types/targeting/geographic';

import { checkIfCoordinateIsValid } from '../utils';

const StyledContainerGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type BoundingBoxProps = {
  children: ReactNode;
  boundingBoxState: GeographicTargetingState['bounding_box'];
  onChange: (newData: GeographicTargetingState['bounding_box']) => void;
};

export const BoundingBox = ({ children, boundingBoxState, onChange }: BoundingBoxProps) => {
  const { t } = useTranslation();
  const [inputsState, setInputesState] = useState(boundingBoxState);
  const [errors, setErrors] = useState({
    [BoundingBoxOptions.LATMAX]: undefined,
    [BoundingBoxOptions.LATMIN]: undefined,
    [BoundingBoxOptions.LONGMAX]: undefined,
    [BoundingBoxOptions.LONGMIN]: undefined,
  });

  const handleValidateCoordinate = useCallback(
    (fieldName: BoundingBoxOption) => {
      const isFieldInvalid = !checkIfCoordinateIsValid(fieldName, inputsState[fieldName]);
      setErrors(errors => ({ ...errors, [fieldName]: isFieldInvalid }));

      return isFieldInvalid;
    },
    [inputsState]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const inputsErrors: boolean[] = [];

        Object.keys(inputsState).forEach(fieldName => {
          const isFieldInvalid = handleValidateCoordinate(fieldName as BoundingBoxOption);
          inputsErrors.push(isFieldInvalid);
        });

        const isFormValid = inputsErrors.every(error => error === false);
        isFormValid && onChange(inputsState);
      }
    },
    [handleValidateCoordinate, onChange, inputsState]
  );

  useEffect(() => {
    setInputesState(boundingBoxState);
  }, [boundingBoxState]);

  const boundBoxInputs = useMemo(
    () => [
      { dataTest: 'lat-min', name: BoundingBoxOptions.LATMIN, placeholder: t('Minimum latitude') },
      { dataTest: 'lat-max', name: BoundingBoxOptions.LATMAX, placeholder: t('Maximum latitude') },
      { dataTest: 'long-min', name: BoundingBoxOptions.LONGMIN, placeholder: t('Minimum longitude') },
      { dataTest: 'long-max', name: BoundingBoxOptions.LONGMAX, placeholder: t('Maximum longitude') },
    ],
    [t]
  );

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={3}>
        {children}
      </Grid>

      <StyledContainerGrid container spacing={1} item xs={9}>
        {boundBoxInputs.map(({ dataTest, name, placeholder }) => (
          <Grid item xs={6} key={dataTest}>
            <TextField
              fullWidth
              placeholder={placeholder}
              onChange={e =>
                setInputesState({
                  ...inputsState,
                  [name]: e.target.value,
                })
              }
              onKeyUp={onKeyPress}
              onBlur={() => handleValidateCoordinate(name)}
              error={errors[name]}
              value={inputsState[name]}
              data-test={dataTest}
            />
          </Grid>
        ))}
      </StyledContainerGrid>
    </Grid>
  );
};
