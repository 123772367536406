import type { TFunction } from 'i18next';

import type { OptionsMap } from '@openx/types/options';
import { ComparisonType, Criteria, Intersect } from '@openx/types/targeting/targetingValuesTypes';
import {
  type BrowserOption,
  type TechnologyAndDevicesData,
  type TechnologyAndDevicesFormState,
  TechnologyAndDevicesOption,
  technologyAndDevicesOptions,
} from '@openx/types/targeting/technologyAndDevices';

import { comparisonMapToList, isEmptyState, mapByComparison } from '../shared';

import { CookiesOptions, DeviceIdOptions, HttpsOptions, MobileCarrierOptions } from './constants';

function typeToApi(state: TechnologyAndDevicesFormState, type: TechnologyAndDevicesOption) {
  if (type === TechnologyAndDevicesOption.USER_AGENT) {
    const itemsList = comparisonMapToList(state[type].val);

    if (itemsList.length) {
      return {
        op: state[TechnologyAndDevicesOption.USER_AGENT].op,
        val: itemsList,
      };
    }
    return;
  }

  if (state[type].val instanceof Set) {
    return {
      op: state[type].op,
      val: [...state[type].val].join(','),
    };
  }
}

function typeToState(data: TechnologyAndDevicesData | null | undefined, type: TechnologyAndDevicesOption) {
  if (!data) {
    return null;
  }

  const apitype = data[type];

  if (!apitype) {
    return null;
  }

  if (typeof apitype.val === 'string') {
    return {
      op: apitype.op,
      val: new Set(apitype.val.split(',')),
    };
  }

  if (apitype.op && Array.isArray(apitype.val)) {
    return {
      op: apitype.op,
      val: mapByComparison(apitype.val),
    };
  }

  return {
    op: apitype.op,
    val: apitype.val,
  };
}

export function mapTechnographicToApi(state: TechnologyAndDevicesFormState): TechnologyAndDevicesData | null {
  if (isEmptyState(state)) {
    return null;
  }

  const result = {};

  for (const type of technologyAndDevicesOptions) {
    if (type !== TechnologyAndDevicesOption.USER_AGENT && state[type].val.size) {
      result[type] = typeToApi(state, type);
    }

    if (type === TechnologyAndDevicesOption.USER_AGENT && Object.keys(state[type].val).length)
      result[type] = typeToApi(state, type);
  }

  return result;
}

const getDefaultParams = () => ({
  [TechnologyAndDevicesOption.API_FRAMEWORK]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.BROWSER]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.DEVICE_TYPE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.LANGUAGE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.OS]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.CONNECTION_SPEED]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.CONNECTION_TYPE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.DEVICE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.DEVICE_ID]: { op: ComparisonType.EQUALS, val: new Set<string>() },
  [TechnologyAndDevicesOption.HTTPS]: { op: ComparisonType.EQUALS, val: new Set<string>() },
  [TechnologyAndDevicesOption.MOBILE_CARRIER]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.MKT_COOKIES]: { op: ComparisonType.EQUALS, val: new Set<string>() },
  [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: { op: Intersect.INTERSECTS, val: new Set<string>() },
  [TechnologyAndDevicesOption.USER_AGENT]: {
    op: Criteria.ALL,
    val: {},
  },
});

export function mapTechnographicToFormState(
  technographic?: TechnologyAndDevicesData | null
): TechnologyAndDevicesFormState {
  const state = getDefaultParams();

  if (technographic) {
    for (const option in technographic) {
      state[option] = typeToState(technographic, option as TechnologyAndDevicesOption) ?? state[option];
    }
  }

  return state;
}

export function sortBrowserOptionsKeys(options: Record<string, BrowserOption>) {
  return Object.keys(options).sort((a, b) => {
    if (options[a].browser_name !== options[b].browser_name) {
      return options[a].browser_name.localeCompare(options[b].browser_name);
    }
    if (!options[a].version_id) return -1;
    if (!options[b].version_id) return +1;

    return Number(options[a].version_id) - Number(options[b].version_id);
  });
}

export const getCookiesLabelsMap = (t: TFunction) => {
  return {
    [CookiesOptions.accepted]: { id: CookiesOptions.accepted, name: t('Accepted') },
    [CookiesOptions.notAccepted]: { id: CookiesOptions.notAccepted, name: t('Not accepted') },
  };
};

export const getDeviceIdLabelsMap = (t: TFunction) => {
  return {
    [DeviceIdOptions.enabled]: { id: DeviceIdOptions.enabled, name: t('Ad Tracking Enabled') },
    [DeviceIdOptions.limited]: { id: DeviceIdOptions.limited, name: t('Ad Tracking Limited') },
  };
};

export const getHttpsLabelsMap = (t: TFunction) => {
  return {
    [HttpsOptions.notServed]: { id: HttpsOptions.notServed, name: t('Not Served through HTTPS') },
    [HttpsOptions.served]: { id: HttpsOptions.served, name: t('Served through HTTPS') },
  };
};

export const getMobileCarrierLabelsMap = (t: TFunction) => {
  return {
    [MobileCarrierOptions.isAnyOf]: { id: MobileCarrierOptions.isAnyOf, name: t('Is any of') },
    [MobileCarrierOptions.isNotAnyOf]: { id: MobileCarrierOptions.isAnyOf, name: t('Is not any of') },
    [MobileCarrierOptions.isWifiOnly]: { id: MobileCarrierOptions.isAnyOf, name: t('Is WiFi only') },
  };
};

export const getTypeOptionsLabels = (t: TFunction) => {
  return {
    [TechnologyAndDevicesOption.API_FRAMEWORK]: t('API Frameworks'),
    [TechnologyAndDevicesOption.BROWSER]: t('Browser'),
    [TechnologyAndDevicesOption.DEVICE_TYPE]: t('Device Type'),
    [TechnologyAndDevicesOption.LANGUAGE]: t('Language'),
    [TechnologyAndDevicesOption.OS]: t('OS'),
    [TechnologyAndDevicesOption.CONNECTION_SPEED]: t('Connection Speed'),
    [TechnologyAndDevicesOption.CONNECTION_TYPE]: t('Connection Type'),
    [TechnologyAndDevicesOption.DEVICE]: t('Device'),
    [TechnologyAndDevicesOption.DEVICE_ID]: t('Device ID'),
    [TechnologyAndDevicesOption.HTTPS]: t('HTTPS'),
    [TechnologyAndDevicesOption.MOBILE_CARRIER]: t('Mobile Carrier'),
    [TechnologyAndDevicesOption.MKT_COOKIES]: t('Cookies'),
    [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: t('Screen Resolution'),
    [TechnologyAndDevicesOption.USER_AGENT]: t('User Agent'),
  };
};

export const getFilterTitle = (t: TFunction, selectedItemsCount?: number) => {
  return {
    [TechnologyAndDevicesOption.API_FRAMEWORK]: t('API FRAMEWORKS [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.BROWSER]: t('BROWSER [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.DEVICE_TYPE]: t('Device Type [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.LANGUAGE]: t('LANGUAGE [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.OS]: t('OS [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.MKT_COOKIES]: t('Cookies'),
    [TechnologyAndDevicesOption.CONNECTION_SPEED]: t('Connection Speed [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.CONNECTION_TYPE]: t('Connection Type [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.DEVICE]: t('Device [ {count} ]', { count: selectedItemsCount }),
    [TechnologyAndDevicesOption.DEVICE_ID]: t('Device ID'),
    [TechnologyAndDevicesOption.HTTPS]: t('Https'),
    [TechnologyAndDevicesOption.SCREEN_RESOLUTION]: t('Screen Resolution [ {count} ]', { count: selectedItemsCount }),
  };
};

export const getFilterDataTest = (t: TFunction, key: TechnologyAndDevicesOption) => {
  const labels = getTypeOptionsLabels(t);
  labels[TechnologyAndDevicesOption.API_FRAMEWORK] = 'api-framework';

  return labels[key].toLowerCase().replace(/ /g, '-');
};

export const transformOptionsToMatchLegacyApi = (options: OptionsMap) => {
  const transformedOptions = {};

  Object.keys(options).forEach((key: string) => {
    const legacyId = options[key].legacy_id;

    transformedOptions[legacyId ?? key] = {
      id: legacyId ?? options[key].id,
      name: options[key].name,
    };
  });

  return transformedOptions;
};
