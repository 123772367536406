import { DialogContent, Typography } from '@mui/material';

import { OutsideLink } from '@openx/components/core/lib/Link/Link';

import { StyledOutsideLinkContainer, StyledTypography } from './Content.styled';

type DealActivationModalContentProps = {
  dealName: string;
};

export const DealActivationModalContent = ({ dealName }: DealActivationModalContentProps): JSX.Element => {
  const mailto = `mailto:deals@openx.com?subject=${dealName} - Deal Activation`;

  return (
    <DialogContent>
      <Typography variant="body1">
        Please get in touch with
        <StyledOutsideLinkContainer>
          <OutsideLink data-test="deal-activation-mailto-link" openInNewTab to={mailto}>
            deals@openx.com
          </OutsideLink>
        </StyledOutsideLinkContainer>
        to activate the following deal.
      </Typography>
      <StyledTypography variant="body1">{dealName}</StyledTypography>
    </DialogContent>
  );
};
