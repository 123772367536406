import { cloneDeep, isEqual } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TargetingProps } from '@openx/types/targeting/targeting';
import {
  type TechnologyAndDevicesData,
  type TechnologyAndDevicesFormState,
  TechnologyAndDevicesOption,
  type UserAgentPayload,
} from '@openx/types/targeting/technologyAndDevices';

import { CustomOptionsProvider } from '../../utils';
import {
  type CtvValidationReturnType,
  DimensionChipLine,
  EmptyFilterMessage,
  TargetingDrawer,
  isEmptyState,
  useDrawer,
  useGetFilteredFields,
} from '../shared';

import { Items } from './Items';
import { TechnologyAndDevicesReadOnly } from './TechnologyAndDevicesReadOnly';
import { TopBox } from './TopBox';
import { technographicDefaultOptions } from './constants';
import { mapTechnographicToApi, mapTechnographicToFormState } from './utils';

type CtvValidationMethods = Omit<
  CtvValidationReturnType,
  'setFormatType' | 'isFormatTypeOpen' | 'shouldDisplayWarningInFormatType'
>;

const defaultCustomValidationMethods: CtvValidationMethods = {
  isTechnographicOpen: false,
  setIsFormatTypeOpen: () => {},
  setIsTechnographicOpen: () => {},
  setTechnographic: () => {},
  shouldDisplayWarningInTechnographic: false,
};

export const TechnologyAndDevices = memo(function TechnologyAndDevices({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  customValidationMethods = defaultCustomValidationMethods,
  customOptions = technographicDefaultOptions,
}: TargetingProps<TechnologyAndDevicesData, CtvValidationMethods>): JSX.Element {
  const { t } = useTranslation();
  const initState = mapTechnographicToFormState(cloneDeep(targetingParams));
  const [params, updateParams] = useState<TechnologyAndDevicesFormState>(initState);
  const [type, onTypeChange] = useState<TechnologyAndDevicesOption>(
    customOptions.defaultOption ?? TechnologyAndDevicesOption.DEVICE_TYPE
  );

  const { setTechnographic, setIsTechnographicOpen, isTechnographicOpen, ...methods } = customValidationMethods;

  useEffect(() => {
    setTechnographic(params);
  }, [params, setTechnographic]);

  const isDataUpdated = useMemo(
    () => !isEqual(targetingParams, mapTechnographicToApi(params)),
    [params, targetingParams]
  );

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => {
      onFieldUpdate(field, mapTechnographicToApi(params));
      setIsTechnographicOpen(false);
    },
    restoreParams: () => updateParams({ ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams(mapTechnographicToFormState(null));
  }, [onFieldUpdate, field]);

  const onChange = useCallback(
    (options: Set<string> | UserAgentPayload, op?: string) => {
      if (options instanceof Set) {
        updateParams(params => ({ ...params, [type]: { op: op ?? params[type].op, val: options } }));
        return;
      }

      if (type === TechnologyAndDevicesOption.USER_AGENT) {
        updateParams(params => ({
          ...params,
          [TechnologyAndDevicesOption.USER_AGENT]: {
            op: options.op,
            val: options.val,
          },
        }));
      }
    },
    [type]
  );

  const targetingParamsFiltered = useGetFilteredFields(params || {});
  const isEmpty = isEmptyState(params);
  const selectedItems =
    type === TechnologyAndDevicesOption.USER_AGENT ? params[TechnologyAndDevicesOption.USER_AGENT] : params[type].val;

  return (
    <CustomOptionsProvider value={customOptions}>
      <TechnologyAndDevicesReadOnly
        name={name}
        readonly={readonly}
        targetingParams={initState}
        onOpenDrawerClick={() => {
          onDrawerOpen();
          setIsTechnographicOpen(true);
        }}
        isDisabled={isDisabled}
        onRemoveClick={onRemoveAll}
        customValidationMethods={methods}
      />
      {(isOpen || isTechnographicOpen) && (
        <TargetingDrawer
          name={name}
          onClose={() => {
            onDrawerClose();
            setIsTechnographicOpen(false);
          }}
          onApply={onDrawerApply}
          isDataUpdated={isDataUpdated}
        >
          <TopBox items={selectedItems} onChange={onChange} selectedType={type} onTypeChange={onTypeChange} />

          {isEmpty && <EmptyFilterMessage text={t('No filters applied.')} />}

          {!isEmpty &&
            targetingParamsFiltered.map((type, index) => {
              const shouldAddDimensionChipLine = targetingParamsFiltered.length - 1 > index;

              return (
                <div key={type}>
                  <Items targetingParams={params} readonly={false} type={type} onChange={updateParams} />

                  {shouldAddDimensionChipLine && <DimensionChipLine />}
                </div>
              );
            })}
        </TargetingDrawer>
      )}
    </CustomOptionsProvider>
  );
});
