import { cloneDeep, isEqual } from 'lodash';
import { useMemo, useReducer } from 'react';

import {
  type ContentObjectApi,
  type ContentObjectOption,
  ContentObjectOptions,
  type ContentObjectProps,
  type IntersectFormState,
} from '@openx/types/targeting/contentObject';
import type { TargetingProps } from '@openx/types/targeting/targeting';
import type { UseContentObjectOptionsConfig } from '@openx/types/targeting/targetingHookTypes';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { useTargetingContext } from '../../utils';
import { TargetingDrawer, useDrawer } from '../shared';

import { ContentObjectItems } from './ContentObjectItems';
import { ContentObjectReadOnly } from './ContentObjectReadOnly';
import { ContentObjectTopBox } from './ContentObjectTopBox';
import { hasHierarchyOptions, mapContentObjectStateToApi, mapContentObjectToFormState } from './utils';

const reducer = (
  state: ContentObjectProps,
  action: { type: ContentObjectOption | 'ALL'; values: any }
): ContentObjectProps => {
  const { type, values } = action;
  const param = state[type] as IntersectFormState;
  const op = param?.op || Intersect.INTERSECTS;

  switch (type) {
    case ContentObjectOptions.LANGUAGE:
    case ContentObjectOptions.EPISODE:
      return {
        ...state,
        [type]: {
          op,
          val: values,
        },
      };
    case ContentObjectOptions.CONTENT_RATING:
    case ContentObjectOptions.GENRE:
    case ContentObjectOptions.LIVESTREAM:
      return {
        ...state,
        [type]: values,
      };
    case ContentObjectOptions.NETWORK:
    case ContentObjectOptions.CHANNEL:
    case ContentObjectOptions.SERIES:
      return {
        ...state,
        [type]: {
          op,
          val: new Set([...(state[type]?.val || []), values]),
        },
      };
    case 'ALL':
      if (typeof values === 'function') return values(state);
      return { ...values };
    default:
      return state;
  }
};

// seems like this workaround would not be needed anymore, to be reviewed in EXCH-9617
export const ContentObject = (props: TargetingProps<ContentObjectApi>): JSX.Element => {
  const { useContentObjectOptionsConfig } = useTargetingContext();

  if (!useContentObjectOptionsConfig) {
    throw new Error('useContentObjectOptionsConfig is not provided');
  }

  return <ContentObjectRenderer {...props} useContentObjectOptionsConfig={useContentObjectOptionsConfig} />;
};

const ContentObjectRenderer = ({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  useContentObjectOptionsConfig,
}: TargetingProps<ContentObjectApi> & {
  useContentObjectOptionsConfig: UseContentObjectOptionsConfig;
}): JSX.Element => {
  const { useOptionFetch } = useContentObjectOptionsConfig();

  const { options: genreOptions } = useOptionFetch(
    ContentObjectOptions.GENRE,
    hasHierarchyOptions(targetingParams?.[ContentObjectOptions.GENRE])
  );

  const { options: contentratingOptions } = useOptionFetch(
    ContentObjectOptions.CONTENT_RATING,
    hasHierarchyOptions(targetingParams?.[ContentObjectOptions.CONTENT_RATING])
  );

  const initState = useMemo(
    () =>
      mapContentObjectToFormState(cloneDeep(targetingParams), {
        contentrating: contentratingOptions,
        genre: genreOptions,
      }),
    [targetingParams, contentratingOptions, genreOptions]
  );

  const [formState, dispatch] = useReducer(reducer, initState);

  const {
    isOpen,
    onDrawerClose,
    onDrawerOpen: handleOnDraweOpen,
    onDrawerApply,
  } = useDrawer({
    onApply: () => onFieldUpdate(field, mapContentObjectStateToApi(formState)),
    restoreParams: () => dispatch({ type: 'ALL', values: initState }),
  });

  const onDrawerOpen = () => {
    handleOnDraweOpen();
    dispatch({ type: 'ALL', values: initState });
  };

  const isDataUpdated = useMemo(() => !isEqual(initState, formState), [initState, formState]);
  const updateFormState = values => dispatch({ type: 'ALL', values });

  return (
    <>
      <ContentObjectReadOnly
        name={name}
        isDisabled={isDisabled}
        readonly={readonly}
        targetingParams={initState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={() => {
          dispatch({ type: 'ALL', values: mapContentObjectToFormState(null) });
          onFieldUpdate(field, null);
        }}
      />

      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <ContentObjectTopBox formState={formState} dispatch={dispatch} />
          <ContentObjectItems targetingParams={formState} onChange={updateFormState} readonly={!!readonly} />
        </TargetingDrawer>
      )}
    </>
  );
};
