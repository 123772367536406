import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useVideoFormatOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isVideoFormatLoading,
    data: videoFormats,
    refetch: refetchVideoFormat,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch video format options'),
    mapByKey: 'legacy_id',
    optionType: OptionsType.VIDEO_FORMAT,
    queryOptions,
  });

  return {
    isVideoFormatLoading,
    refetchVideoFormat,
    videoFormats,
  };
}
