import { omit } from 'lodash';
import { type SearchParamsObj, getSearchParamsObj } from 'utils';

import { CriteriaDimension, type PaginationCriteria, type SortCriteria } from '@openx/types/tableCriteria';

import { isSortCriteria } from './typeGuards';

export const changeSortCriteriaParams = (
  mappedChangeCriteria: Omit<SortCriteria, 'sortableColumns'>,
  params: SearchParamsObj['params']
) => {
  const newParams = {
    ...params,
    column: mappedChangeCriteria?.column,
    pageNumber: 1,
    sort: mappedChangeCriteria?.direction,
  };

  return omit(newParams, 'pageNumber');
};

export const changePageCriteriaParams = (
  paginationCriteria: Partial<PaginationCriteria>,
  params: SearchParamsObj['params']
) => {
  const newParams = {
    ...params,
    pageNumber: paginationCriteria?.pageNumber?.toString() || '1',
  };
  const updatedParams = paginationCriteria.pageNumber === 1 ? omit(newParams, 'pageNumber') : newParams;

  return updatedParams;
};

export const changeListCriteria = (change: SortCriteria | PaginationCriteria, dimension: CriteriaDimension) => {
  return (prevParams: URLSearchParams) => {
    const { params } = getSearchParamsObj(prevParams);

    if (dimension === CriteriaDimension.SORT && isSortCriteria(change)) {
      return changeSortCriteriaParams(change, params);
    }

    // pagination criteria change
    return changePageCriteriaParams(change as PaginationCriteria, params);
  };
};

export const changePhraseCriteriaParams = (phrase: string) => {
  return prevParams => {
    const { params } = getSearchParamsObj(prevParams);
    const updatedFilters = phrase ? { ...params, pageNumber: '1', phrase } : omit(params, 'phrase');

    return omit(updatedFilters, 'pageNumber');
  };
};
