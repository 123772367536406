import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { type AudienceData, AudienceOption } from '@openx/types/targeting/audience';

import { useTargetingContext } from '../../../utils';
import { MatchedUsersOption, type MatchedUsersOptions } from '../constants';
import { getMatchedUsersLabelsMap } from '../utils';

import { EidsDropdown } from './EidsDropdown';

type MatchedUsersDropdownProps = {
  accountUid: string;
  onChange: (value?: string | Set<string>, givenType?: AudienceOption) => void;
  params: AudienceData | null;
};

export const MatchedUsersDropdown = ({ accountUid, onChange, params }: MatchedUsersDropdownProps) => {
  const { t } = useTranslation();
  const inputState = params?.[AudienceOption.MATCHED_USERS] || null;
  // default value added for OA, because it doesn't use this hook
  const { useAccountFetch = () => {} } = useTargetingContext();
  const accountFetchHookPayload = useAccountFetch(accountUid);
  const { account, isAccountLoading } = accountFetchHookPayload || {};
  const eidsAllowedByAccount = account?.exchange?.traffic_filter_eids;

  const [showEidsDropdown, setShowEidsDropdown] = useState(false);
  const optionsLables = getMatchedUsersLabelsMap(t);

  const options = useMemo(() => {
    const availableOptions: MatchedUsersOptions[] = [
      MatchedUsersOption.Matched,
      MatchedUsersOption.Unmatched,
      MatchedUsersOption.MatchedByCookie,
    ];

    if (eidsAllowedByAccount && Object.keys(eidsAllowedByAccount).length)
      availableOptions.push(MatchedUsersOption.MatchedByEids);

    return availableOptions;
  }, [eidsAllowedByAccount]);

  const handleChange = useCallback(
    (val: string) => {
      onChange(val);
      onChange(undefined, AudienceOption.TRAFFIC_FILTER_EIDS);
    },
    [onChange]
  );

  useEffect(() => {
    const shouldShowEidsDropdown = inputState === MatchedUsersOption.MatchedByEids && !!eidsAllowedByAccount;
    setShowEidsDropdown(shouldShowEidsDropdown);
  }, [eidsAllowedByAccount, inputState]);

  return (
    <>
      <AutoComplete
        data-test="matched-users-options-input"
        getOptionLabel={option => optionsLables[option].name}
        onChange={(_, val) => val && handleChange(val)}
        options={options}
        textFieldProps={{ placeholder: t('[ Select Matched Users ]') }}
        value={inputState}
        loading={isAccountLoading}
      />

      {showEidsDropdown && (
        <EidsDropdown
          eidsAllowedByAccount={eidsAllowedByAccount}
          selectedEids={params?.[AudienceOption.TRAFFIC_FILTER_EIDS]}
          onChange={onChange}
        />
      )}
    </>
  );
};
