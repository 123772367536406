import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';
import { useOptionsFetch } from '@openx/utils/apiHooks/generics/useOptionsFetch';

import { prepareCategoryOptions } from '../../Targeting/Category/utils';

export function useCategoriesFetch() {
  const { t } = useTranslation();
  const {
    isLoading: isCategoriesLoading,
    data: categories,
    refetch: refetchCategories,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Categories'),
    optionType: OptionsType.AD_CATEGORIES,
    queryOptions: { enabled: false },
  });

  const preperedCategories = useMemo(
    () => (categories ? prepareCategoryOptions(categories, t('(All)')) : {}),
    [categories, t]
  );

  return {
    categories: preperedCategories,
    isCategoriesLoading,
    rawCategories: categories,
    refetchCategories,
  };
}
