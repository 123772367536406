import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import type { OptionsMap } from '@openx/types/options';

import { Item, type ItemProps } from './Item';
import { Title } from './Title';
import {
  StyledAddIcon,
  StyledContainer,
  StyledIconContainer,
  StyledItemsRemoveIcon,
  StyledItemsRemoveIconButton,
  StyledSpan,
  StyledTitleWrapper,
} from './styled';
import { itemToString } from './utils';

export type SelectedItemComponent = React.FC<ItemProps<string | JSX.Element>>;

type ItemsProps = {
  onRemove?: (id: string | JSX.Element) => void;
  onRemoveAll: () => void;
  ItemComponent?: SelectedItemComponent;
  selectedItems: (string | JSX.Element)[];
  hideTitle?: boolean;
  title: string;
  isAddIcon: boolean;
  readonly?: boolean;
  options?: OptionsMap;
  'data-test'?: string;
  disabledStyle: boolean;
  isRemovingAll?: boolean;
  opSwitch?: ReactElement;
};

export const Items = ({
  ItemComponent = Item,
  selectedItems,
  onRemove,
  onRemoveAll,
  hideTitle = false,
  title,
  isAddIcon = true,
  readonly,
  'data-test': dataTest,
  options,
  disabledStyle,
  isRemovingAll = false,
  opSwitch,
}: ItemsProps): JSX.Element => {
  const { t } = useTranslation();
  const Icon = isAddIcon ? StyledAddIcon : StyledItemsRemoveIcon;
  const defaultTitle = t('Included');

  return (
    <Grid container data-test="section-items">
      {!hideTitle && (
        <StyledTitleWrapper data-test="action-subtitle">
          <Title
            title={title || defaultTitle}
            data-test={`${(dataTest ?? 'tf').toLocaleLowerCase().replace(/[\s_]/g, '-')}-allow`}
          />

          {!readonly && isRemovingAll && (
            <StyledItemsRemoveIconButton
              className="removeIconButton"
              onClick={onRemoveAll}
              size="large"
              data-test="items-remove-all"
            >
              <CloseIcon data-test="close-dialog" fontSize="small" />
            </StyledItemsRemoveIconButton>
          )}
        </StyledTitleWrapper>
      )}
      {!readonly && opSwitch}
      {selectedItems.length > 0 && (
        <StyledContainer container>
          <StyledIconContainer>
            <StyledSpan>
              <Icon fontSize="small" disabledStyle={disabledStyle} data-test={isAddIcon ? 'add-icon' : 'remove-icon'} />
            </StyledSpan>
          </StyledIconContainer>
          <Grid container data-test="items-container">
            {selectedItems.map((item, index) => {
              const key = itemToString(item);

              return (
                <ItemComponent
                  key={key}
                  testId={`${(dataTest ?? 'tf').toLocaleLowerCase().replace(/\s/g, '-').replaceAll('_', '-')}-item`}
                  readonly={!!readonly}
                  item={item}
                  option={options?.[key]}
                  onRemove={onRemove}
                  disabledStyle={disabledStyle}
                >
                  {options?.[key]?.name ?? item}
                  {!opSwitch && `${index < selectedItems.length - 1 ? ',' : ''}`}
                </ItemComponent>
              );
            })}
          </Grid>
        </StyledContainer>
      )}
    </Grid>
  );
};
