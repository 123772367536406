import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useMobileCarrierOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isMobileCarrierOptionsLoading,
    data: mobileCarrierOptions,
    refetch: refetchMobileCarrierOptions,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Mobile Carrier options'),
    optionType: OptionsType.MOBILE_CARRIER,
    queryOptions,
  });

  return {
    isMobileCarrierOptionsLoading,
    mobileCarrierOptions,
    refetchMobileCarrierOptions,
  };
}
