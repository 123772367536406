import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';
import { useBulkAdd } from '@openx/components/core/lib/TransferList/bulk/hooks/useBulkAdd';
import {
  type MetacategoryData,
  MetacategoryGroup,
  type MetacategoryGroupType,
} from '@openx/types/targeting/metacategory';
import { AllowBlockType } from '@openx/types/targeting/targeting';
import { bigNumberWithCommas } from '@openx/utils/lib/bigNumberWithCommas';

import { BulkAddDrawer } from '../../shared';
import { useKeywordValidation } from '../../shared/KeywordsInput/useKeywordValidation';

import { MetacategoryAllowBlockTypeDropdown } from './MetacategoryAllowBlockTypeDropdown';
import { METACATEGORY_ITEMS_LIMIT } from './constants';

type MetacategoryBulkDrawerProps = {
  categoryType: MetacategoryGroupType;
  onChange: (categories: MetacategoryData) => void;
  items: MetacategoryData;
  allowBlockType: AllowBlockType;
  setAllowBlockType: (type: AllowBlockType) => void;
};

export const MetacategoryBulkDrawer = ({
  categoryType,
  onChange,
  items,
  allowBlockType,
  setAllowBlockType,
}: MetacategoryBulkDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const { validateBulkKeywords } = useKeywordValidation();

  const bulkPlaceholder = t(
    `Keywords may be entered as either comma, semicolon, or new line separated values.

Examples:
keyword-one,keyword-two
or
keyword-one;keyword-two
or
keyword-one
keyword-two`
  );

  const helperPlaceholder = t('Keywords targeting is limited to {formatLimit} entries and it is case-sensitive.', {
    formatLimit: bigNumberWithCommas(METACATEGORY_ITEMS_LIMIT),
  });

  const addBulkItems = useCallback(
    (values: string[]) => {
      onChange({
        ...items,
        keywords: {
          excludes:
            allowBlockType === AllowBlockType.BLOCK ? [...items.keywords.excludes, ...values] : items.keywords.excludes,
          includes:
            allowBlockType === AllowBlockType.ALLOW ? [...items.keywords.includes, ...values] : items.keywords.includes,
        },
      });
    },
    [items, onChange, allowBlockType]
  );

  const { isBulkOpen, onBulkAdd, onBulkClose, onBulkOpen } = useBulkAdd({ onAdd: addBulkItems });

  const validateBulk = useCallback(
    async (values: Array<string>) =>
      validateBulkKeywords(values, { allowKeywords: items.keywords.includes, blockKeywords: items.keywords.excludes }),
    [validateBulkKeywords, items.keywords]
  );

  const currentLimit =
    METACATEGORY_ITEMS_LIMIT -
    (allowBlockType === AllowBlockType.ALLOW ? items.keywords.includes.length : items.keywords.excludes.length);

  const isLimitReached = currentLimit <= 0;

  const showBulkAddButton = categoryType === MetacategoryGroup.KEYWORDS;

  return (
    <>
      {showBulkAddButton && (
        <Grid item xs={12}>
          <Button disabled={isLimitReached} onClick={onBulkOpen}>
            {t('Bulk Add')}
          </Button>
        </Grid>
      )}

      {isBulkOpen && (
        <BulkAddDrawer
          onApply={onBulkAdd}
          onClose={onBulkClose}
          placeholder={bulkPlaceholder}
          validate={validateBulk}
          helperText={helperPlaceholder}
          limit={currentLimit}
          // Uncomment when more than 1 bulk option will be added
          // childParams={contentOption}
        >
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={12}>
              <MetacategoryAllowBlockTypeDropdown
                allowBlockType={allowBlockType}
                setAllowBlockType={setAllowBlockType}
              />
            </Grid>
          </Grid>
        </BulkAddDrawer>
      )}
    </>
  );
};
