import { pick } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { ACL_TYPE } from '@openx/types/aclType';
import { AudienceOption } from '@openx/types/targeting/audience';
import { usePermissionContext } from '@openx/utils/permission/context/PermissionContext';
import { isAllowedByCompiledAcl } from '@openx/utils/permission/validators';

import { useCustomOptionsContext } from '../../../utils';

interface Props {
  onChange: (type: AudienceOption) => void;
  value: AudienceOption;
}

export const TypeOptionDropdown = ({ onChange, value }: Props) => {
  const { t } = useTranslation();
  const { useIsAllowed } = usePermissionContext();
  const { isAllowed } = useIsAllowed();
  const customOptions = useCustomOptionsContext<AudienceOption>();

  const labels = useMemo(() => {
    let options: Partial<Record<AudienceOption, string>> = {
      [AudienceOption.DMP_SEGMENTS]: t('3rd Party DMP'),
      [AudienceOption.INTENDED_AUDIENCE]: t('Intended Audience'),
      [AudienceOption.LIVEINTENT]: t('LiveIntent'),
      [AudienceOption.MATCHED_USERS]: t('Matched Users'),
      [AudienceOption.OPEN_AUDIENCES]: t('OpenXSelect'),
    };

    if (!isAllowed(isAllowedByCompiledAcl(ACL_TYPE.DMP_SOURCES_READ))) {
      delete options[AudienceOption.DMP_SEGMENTS];
    }

    if (customOptions) {
      options = pick(options, customOptions.availableOptions);
    }

    return options;
  }, [t, isAllowed, customOptions]);

  return (
    <AutoComplete<AudienceOption, false, true, false>
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(labels) as AudienceOption[]}
      renderOptions={{
        dataTest: 'type-option',
        getOptionValue: optionId => labels[optionId]!,
      }}
      getOptionLabel={option => labels[option] || option}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_e, value) => onChange(value)}
      value={value}
      fullWidth
      data-test="audience-type"
    />
  );
};
