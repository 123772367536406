import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useOsOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isOsLoading,
    data: os,
    refetch: refetchOs,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Os options'),
    optionType: OptionsType.OS,
    queryOptions,
  });

  return {
    isOsLoading,
    os,
    refetchOs,
  };
}
