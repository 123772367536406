import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer } from '@openx/components/core/lib/Drawer/Drawer';
import { DrawerActionBar, type DrawerActions } from '@openx/components/core/lib/Drawer/DrawerActionBar';
import { DrawerHeader } from '@openx/components/core/lib/Drawer/DrawerHeader';
import { TextField } from '@openx/components/core/lib/TextField/TextField';

import { InvalidItems } from './InvalidItems/InvalidItems';
import type { InvalidItemsError, ValidationResult } from './types';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${({ theme }) => theme.palette.background.boxLight};
  }

  .MuiPaper-root.MuiAlert-standard {
    width: 100%;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(1.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0)};
  }
`;

const StyledInputWrapperGrid = styled(Grid)`
  height: 100%;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const StyledTextField = styled(TextField)`
  .MuiInput-root {
    height: 100%;
  }

  .MuiInput-root textarea {
    height: 100% !important;
    overflow: auto !important;
  }
`;

export const BULK_SPLITER = /\n|,|;/;

interface BulkAddDrawerProps {
  onClose: () => void;
  onApply: (items: string[]) => void;
  errorText?: string;
  children?: ReactNode;
  placeholder: string;
  helperText?: string;
  validate: (value: string[]) => Promise<ValidationResult>;
  limit?: number;
  childParams?: unknown;
}

export function BulkAddDrawer({
  onClose,
  onApply,
  errorText,
  children,
  placeholder,
  helperText,
  validate,
  limit = Number.MAX_SAFE_INTEGER, // in case limit not provided use max integer as default to not show error
  childParams,
}: BulkAddDrawerProps) {
  const [bulkValue, setBulkValue] = useState('');
  const [isAddDisabled, setAddDisabled] = useState(true);
  const [bulkErrors, setBulkErrors] = useState<InvalidItemsError[]>([]);
  const [bulkValidItems, setBulkValidItems] = useState<string[]>([]);
  const [validating, setValidating] = useState(false);
  const { t } = useTranslation();

  const isLimitReached = bulkValidItems.length > limit;

  useEffect(() => {
    // disable add when params in children changed, as current values needs to be revalidated
    setAddDisabled(true);
  }, [childParams]);

  const onValidate = useCallback(async () => {
    setValidating(true);
    const items = bulkValue
      .split(BULK_SPLITER)
      .map(value => value.trim())
      .filter(value => !!value);

    const { errors, validItems } = await validate(items);
    setBulkErrors(errors);
    setBulkValidItems(validItems);

    const validItemsReformated = validItems.join('\n');
    setBulkValue(validItemsReformated);
    setAddDisabled(!validItemsReformated);
    setValidating(false);
  }, [bulkValue, validate]);

  const actions: DrawerActions = useMemo(
    () => [
      {
        color: 'primary',
        disabled: isLimitReached || isAddDisabled,
        label: t('Add'),
        onClick: () => onApply(bulkValidItems),
      },
      {
        label: t('Validate'),
        loading: validating,
        onClick: onValidate,
      },
      {
        label: t('Cancel'),
        onClick: onClose,
        variant: 'text',
      },
    ],
    [t, isLimitReached, isAddDisabled, onValidate, validating, onClose, onApply, bulkValidItems]
  );

  const onChange = useCallback(event => {
    setBulkValue(event.target.value);
    setAddDisabled(true);
  }, []);

  return (
    <StyledDrawer open onClose={onClose} dataTest="bulk-side-details" isStackedDrawer>
      <DrawerHeader onClose={onClose} marginBottom={0} dataTest="bulk-drawer-header">
        {t('Bulk Add')}
      </DrawerHeader>

      {!!bulkErrors.length && <InvalidItems errors={bulkErrors} errorText={errorText} />}

      {children}

      <StyledInputWrapperGrid container>
        <StyledTextField
          data-test="targeting-bulk-input"
          multiline
          fullWidth
          InputProps={{ disableUnderline: true }}
          helperText={isLimitReached ? t('The limit has been reached ({limit}).', { limit }) : helperText}
          error={isLimitReached}
          placeholder={placeholder}
          onChange={onChange}
          value={bulkValue}
          disabled={validating}
        />
      </StyledInputWrapperGrid>

      <DrawerActionBar
        actions={actions}
        stickyBottom
        hideDivider
        backgroundColor="inherit"
        dataTest="bulk-drawer-buttons"
      />
    </StyledDrawer>
  );
}
