import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { MetacategoryGroup, type MetacategoryGroupType } from '@openx/types/targeting/metacategory';

type InventoryContentOptionDropdownProps = {
  onChange: (type: MetacategoryGroupType) => void;
  value: MetacategoryGroupType;
};

export const MetacategoryGroupOptionDropdown = (props: InventoryContentOptionDropdownProps) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const labels: Record<MetacategoryGroupType, string> = {
    [MetacategoryGroup.ADDITIONAL_OPENX_CATEGORIES]: t('Additional OpenX Categories'),
    [MetacategoryGroup.KEYWORDS]: t('Keywords'),
  };

  return (
    <AutoComplete
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(labels)}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => labels[option]}
      textFieldReadOnly
      disableClearable
      onChange={(_, value) => onChange(value as MetacategoryGroupType)}
      value={value}
      data-test="metacategory-category-type"
    />
  );
};
