import { useCallback } from 'react';

import type { GeoLocationsList } from '@openx/types/geoLocation';
import { GeographicOption, type GeographicTargetingState, type RadiusCircle } from '@openx/types/targeting/geographic';

import { convertGeoObjToArray, filterExcludesWithoutParent, getEmptyLocationState } from '../utils';

export const useHandleGeoChange = (
  targetingParams: GeographicTargetingState,
  onChange: (state: GeographicTargetingState) => void,
  locationsSwitchState: boolean
) => {
  const { excludes_subset } = targetingParams;

  const handleChange = useCallback(
    (geoOption: GeographicOption, filteredItems: GeoLocationsList | RadiusCircle[], locationType: string) => {
      if (geoOption === GeographicOption.INCLUDES) {
        const newValueIncludes = {
          ...targetingParams[GeographicOption.INCLUDES],
          [locationType]: filteredItems as GeoLocationsList,
        };

        const withoutParent = filterExcludesWithoutParent({
          excludeItems: excludes_subset,
          includeItems: convertGeoObjToArray(newValueIncludes),
        });

        onChange({
          [GeographicOption.INCLUDES]: newValueIncludes,
          [GeographicOption.EXCLUDES]: getEmptyLocationState(),
          [GeographicOption.EXCLUDES_SUBSET]: withoutParent,
          [GeographicOption.CIRCLES]: [...targetingParams[GeographicOption.CIRCLES]],
          [GeographicOption.SOURCE]: { ...targetingParams[GeographicOption.SOURCE] },
          [GeographicOption.BOUNDING_BOX]: { ...targetingParams[GeographicOption.BOUNDING_BOX] },
        });
        return;
      }

      if (geoOption === GeographicOption.EXCLUDES) {
        onChange({
          [GeographicOption.INCLUDES]: getEmptyLocationState(),
          [GeographicOption.EXCLUDES]: {
            ...targetingParams[GeographicOption.EXCLUDES],
            [locationType]: filteredItems as GeoLocationsList,
          },
          [GeographicOption.EXCLUDES_SUBSET]: [],
          [GeographicOption.CIRCLES]: [...targetingParams[GeographicOption.CIRCLES]],
          [GeographicOption.SOURCE]: { ...targetingParams[GeographicOption.SOURCE] },
          [GeographicOption.BOUNDING_BOX]: { ...targetingParams[GeographicOption.BOUNDING_BOX] },
        });
        return;
      }

      onChange({
        ...targetingParams,
        [GeographicOption.CIRCLES]: filteredItems as RadiusCircle[],
      });
    },
    [excludes_subset, onChange, targetingParams]
  );

  const onLocationRemove = useCallback(
    (itemNames: GeoLocationsList, type: string) => {
      handleChange(locationsSwitchState ? GeographicOption.EXCLUDES : GeographicOption.INCLUDES, itemNames, type);
    },
    [handleChange, locationsSwitchState]
  );

  const encapsulateTypeToCallback = useCallback((type: string, cb: (val: GeoLocationsList, type: string) => void) => {
    return (value: GeoLocationsList) => cb(value, type);
  }, []);

  const onLocationClear = useCallback(() => {
    onChange({
      ...targetingParams,
      [GeographicOption.INCLUDES]: getEmptyLocationState(),
      [GeographicOption.EXCLUDES]: getEmptyLocationState(),
      [GeographicOption.EXCLUDES_SUBSET]: [],
    });
  }, [onChange, targetingParams]);

  return { handleChange: encapsulateTypeToCallback, onLocationClear, onLocationRemove };
};
