import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

const StyledGrid = styled(Grid)`
  margin-left: 30px;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type ExcludeItemsBoxProps = {
  children: ReactNode;
};

export const ExcludeItemsBox = ({ children }: ExcludeItemsBoxProps): JSX.Element => {
  return <StyledGrid data-test="section-items">{children}</StyledGrid>;
};
