import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FormHelperText, Grid, IconButton, Tooltip } from '@mui/material';
import { debounce, omit } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowBlockType } from '@openx/types/targeting/targeting';

import { OptionsDropdown } from '..';
import { useTargetingContext } from '../../../utils/context';

import { formatKeyword, useKeywordValidation } from './useKeywordValidation';

type KeywordsInputProps = {
  keywords: string[];
  keywordsType?: AllowBlockType;
  onChange: (keywords: Set<string>) => void;
  children?: React.ReactNode;
  hiddenOptions?: string[];
  keywordsLimit: number;
};

export const KeywordsInput = ({
  keywords,
  onChange,
  children,
  hiddenOptions = [],
  keywordsType,
  keywordsLimit,
}: KeywordsInputProps): JSX.Element => {
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState('');
  const [keyword, setKeyword] = useState('');
  const { validateKeyword } = useKeywordValidation();

  const setDebouncedKeyword = useMemo(() => debounce(setKeyword, 300), [setKeyword]);

  const { useKeywordsOptionsFetch } = useTargetingContext();

  const { keywordsOptions, keywordsOptionsLoading } = useKeywordsOptionsFetch(keyword);

  const filteredOptions = useMemo(() => {
    return omit(keywordsOptions, hiddenOptions);
  }, [keywordsOptions, hiddenOptions]);

  const selectedOptions = useMemo(() => new Set(keywords), [keywords]);

  const validate = useCallback(
    (keyword: string | undefined) => {
      const formattedKeyword = formatKeyword(keyword);
      const allKeywords = !keywordsType
        ? { generalKeywords: keywords }
        : keywordsType === AllowBlockType.ALLOW
          ? { allowKeywords: keywords, blockKeywords: hiddenOptions }
          : { allowKeywords: hiddenOptions, blockKeywords: keywords };
      const validationResult = validateKeyword({
        allExistingKeywords: allKeywords,
        keyword: formattedKeyword,
        keywords,
        keywordsLimit,
      });

      return { formattedKeyword, validationResult };
    },
    [validateKeyword, keywords, keywordsLimit, keywordsType, hiddenOptions]
  );

  const onChangeHandler = useCallback(
    (items: Set<string>) => {
      onChange(items);
    },
    [onChange]
  );

  const customOptionHandler = useCallback(
    (items: Set<string>, value: string | undefined) => {
      const { validationResult, formattedKeyword } = validate(value);
      setErrorMsg(validationResult);

      if (!validationResult && formattedKeyword) {
        const updatedItems = new Set(items);

        if (value) {
          // replace the old value with the formatted one
          updatedItems.delete(value);
          updatedItems.add(formattedKeyword);
        }

        onChange(updatedItems);
      }
    },
    [validate, onChange]
  );

  return (
    <Grid container spacing={1} alignItems="center" pb={errorMsg ? 2 : 0}>
      {children && (
        <Grid item xs={3}>
          {children}
        </Grid>
      )}
      <Grid item sx={{ flex: 1 }} mt={2}>
        <OptionsDropdown
          loading={keywordsOptionsLoading}
          options={filteredOptions}
          onChange={onChangeHandler}
          onPhraseChange={setDebouncedKeyword}
          selectedOptions={selectedOptions}
          placeholder={t('[ Select or Search Keywords ]')}
          // TODO UIG-540 - comment it for now till API will be ready
          // getSortedKeys={getSortedKeys}
          dataTest="keywords-input"
          customOptionHandler={customOptionHandler}
        />
        {errorMsg && (
          <FormHelperText error sx={{ position: 'absolute' }} data-test="keywords-error-text">
            {errorMsg}
          </FormHelperText>
        )}
      </Grid>
      <Grid item mt={1.75}>
        <Tooltip
          title={t('The list of keywords is sorted by the Best Coverage indicator.')}
          placement="right"
          data-test="keyword-tooltip"
        >
          <IconButton size="small" data-test="keyword-tooltip-icon">
            <InfoRoundedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
