import { type SxProps, TableHead, TableRow, type Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';

import {
  type ChangeSingleCriteriaAction,
  type CriteriaDimension,
  type SortCriteria,
  SortDirection,
} from '@openx/types/tableCriteria';
import type { BaseRow, Columns } from '@openx/types/tableHelpers';

import { TableHeaderCell } from './TableHeaderCell';

export interface TableHeaderProps<RowT extends BaseRow> {
  columns: Columns<RowT>;
  onChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  criteria?: SortCriteria;
  stickyTable?: boolean;
  overrideTableCellStyles?: SxProps<Theme>;
}

const StyledTableHeader = styled(TableRow)`
  .MuiTableCell-head {
    position: sticky;
    top: var(--topbar-height);
    z-index: 2;
  }
`;

export function TableHeader<RowT extends BaseRow>({
  columns,
  onChange,
  criteria,
  stickyTable,
  overrideTableCellStyles,
}: TableHeaderProps<RowT>): JSX.Element {
  const toggleSort = useCallback(
    (columnKey: string) => {
      if (!criteria || !criteria.sortableColumns.includes(columnKey)) {
        return;
      }

      const columnAlreadySortedDesc = criteria.column === columnKey && criteria.direction === SortDirection.ASC;
      const newDirection = columnAlreadySortedDesc ? SortDirection.DESC : SortDirection.ASC;
      onChange({
        ...criteria,
        column: columnKey,
        direction: newDirection,
      });
    },
    [criteria, onChange]
  );

  const TableHeaderWrapper = stickyTable ? StyledTableHeader : TableRow;

  return (
    <TableHead data-test="table-header">
      <TableHeaderWrapper data-test="table-row">
        {columns.map(column => (
          <TableHeaderCell
            column={column}
            toggleSort={criteria?.sortableColumns.includes(column.key) ? toggleSort : undefined}
            appliedSortDirection={criteria?.column === column.key ? criteria?.direction : undefined}
            key={column.key}
            overrideTableCellStyles={overrideTableCellStyles}
          />
        ))}
      </TableHeaderWrapper>
    </TableHead>
  );
}
