import type { AdPlacement } from '../adUnit';
import type { DistributionChannel } from '../distributionChannel';

import type { CriteriaValue, Intersect } from './targetingValuesTypes';

export enum InventoryFormatTypeOption {
  DISTRIBUTION_CHANNEL = 'distribution_channel',
  AD_PLACEMENT = 'ad_placement',
}

export type FormatTypeData = {
  op: CriteriaValue;
  [InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?: {
    op: Intersect;
    val: DistributionChannel;
  } | null;
  [InventoryFormatTypeOption.AD_PLACEMENT]?: {
    op: Intersect;
    val: AdPlacement;
  } | null;
};
