import i18n from 'i18next';

import { type ContentObjectOption, ContentObjectOptions } from '@openx/types/targeting/contentObject';

export const inputPlaceholder = (option: ContentObjectOption): string => {
  switch (option) {
    case ContentObjectOptions.GENRE:
      return i18n.t('[ Select Genre ]');
    case ContentObjectOptions.CONTENT_RATING:
      return i18n.t('[ Select Rating ]');
    case ContentObjectOptions.LIVESTREAM:
      return i18n.t('[ Select a Livestream Option ]');
    case ContentObjectOptions.LANGUAGE:
      return i18n.t('[ Select a Language ]');
    case ContentObjectOptions.SERIES:
      return i18n.t('Enter a Series Name and Press Enter');
    case ContentObjectOptions.EPISODE:
      return i18n.t('Enter Episode Number');
    case ContentObjectOptions.NETWORK:
      return i18n.t('Enter a Network and Press Enter');
    case ContentObjectOptions.CHANNEL:
      return i18n.t('Enter a Channel and Press Enter');
    default:
      return option;
  }
};

export const getOptionName = (option: ContentObjectOption): string => {
  switch (option) {
    case ContentObjectOptions.GENRE:
      return i18n.t('Genre');
    case ContentObjectOptions.CONTENT_RATING:
      return i18n.t('Rating');
    case ContentObjectOptions.LIVESTREAM:
      return i18n.t('Livestream');
    case ContentObjectOptions.LENGTH:
      return i18n.t('Length');
    case ContentObjectOptions.LANGUAGE:
      return i18n.t('Language');
    case ContentObjectOptions.SERIES:
      return i18n.t('Series');
    case ContentObjectOptions.EPISODE:
      return i18n.t('Episodes');
    case ContentObjectOptions.CHANNEL:
      return i18n.t('Channel');
    case ContentObjectOptions.NETWORK:
      return i18n.t('Network');
    default:
      return option;
  }
};

export const itemTitle = (option: ContentObjectOption, count: number): string => {
  switch (option) {
    case ContentObjectOptions.GENRE:
      return i18n.t('Genre [ {count} ]', { count });
    case ContentObjectOptions.CONTENT_RATING:
      return i18n.t('Rating [ {count} ]', { count });
    case ContentObjectOptions.LIVESTREAM:
      return i18n.t('Livestream [ {count} ]', { count });
    case ContentObjectOptions.LENGTH:
      return i18n.t('Length [ {count} ]', { count });
    case ContentObjectOptions.LANGUAGE:
      return i18n.t('Language [ {count} ]', { count });
    case ContentObjectOptions.SERIES:
      return i18n.t('Series [ {count} ]', { count });
    case ContentObjectOptions.EPISODE:
      return i18n.t('Episodes [ {count} ]', { count });
    case ContentObjectOptions.CHANNEL:
      return i18n.t('Channel [ {count} ]', { count });
    case ContentObjectOptions.NETWORK:
      return i18n.t('Network [ {count} ]', { count });
    default:
      return option;
  }
};

export const stateUnmatchedOption = {
  id: '0',
  name: 'Other genre',
};

export const apiUnmatchedOption = {
  id: '0',
  name: 'unmatched_genre',
};
