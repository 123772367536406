import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { type ChangeEvent, type KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import { Ellipsis } from '@openx/components/core/lib/Ellipsis/Ellipsis';
import { HighlightedPhrase } from '@openx/components/core/lib/HighlightedPhrase/HighlightedPhrase';
import type { OptionsMap } from '@openx/types/options';
import { produceSortedOptionsKeys } from '@openx/utils/lib/sortedOptions';

interface OptionsDropdownProps {
  options: OptionsMap;
  loading: boolean;
  selectedOptions: Set<string>;
  placeholder: string;
  onChange: (values: Set<string>, changedValue?: string) => void;
  onPhraseChange?: (phrase: string) => void;
  filterOptions?: (optionsIds: string[], optionsMap: OptionsMap, searchPhrase: string) => string[];
  getSortedKeys?: (options: OptionsMap) => string[];
  dataTest?: string;
  margin?: 'normal' | 'none' | 'dense';
  label?: string;
  customOptionHandler?: (values: Set<string>, value?: string) => void;
}

const StyledOptionBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(0.8)};
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  padding-top: ${({ theme }) => theme.spacing(0.8)};
  width: 100%;
`;

const defaultFilter = (optionsIds: string[], optionsMap: OptionsMap, searchPhrase: string) => {
  return searchPhrase.length > 2
    ? optionsIds.filter(option => optionsMap[option]?.name.toLowerCase().includes(searchPhrase))
    : optionsIds;
};

const getNestedPadding = (level = 1) => {
  return (level - 1) * 2 + 1.5;
};

export function OptionsDropdown(props: OptionsDropdownProps) {
  const {
    options,
    loading,
    selectedOptions,
    placeholder,
    onChange,
    onPhraseChange,
    dataTest,
    margin,
    label,
    getSortedKeys = produceSortedOptionsKeys,
    filterOptions = defaultFilter,
    customOptionHandler,
  } = props;
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const onSetSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);

      onPhraseChange && onPhraseChange(e.target.value);
    },
    [onPhraseChange]
  );

  const onClose = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const handleChange = useCallback(
    (e, value) => {
      const values = new Set(selectedOptions);
      if (values.has(value)) {
        values.delete(value);
      } else {
        values.add(value);
      }
      onChange(values, value);
      e.stopPropagation();
    },
    [onChange, selectedOptions]
  );

  const handleAddCustomOption = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!customOptionHandler || e.key !== 'Enter') {
        return;
      }

      const values = new Set(selectedOptions);
      values.add(search);
      customOptionHandler(values, search);

      e.stopPropagation();
      (e.target as HTMLElement).blur();
    },
    [search, selectedOptions, customOptionHandler]
  );

  const withEmptyOptions = useMemo(
    () => (!isEmpty(options) ? options : { no_options: { id: 'no_options', name: t('No options') } }),
    [options, t]
  );

  const optionsIds = useMemo(() => getSortedKeys(withEmptyOptions), [getSortedKeys, withEmptyOptions]);

  const renderOption = useCallback(
    (state, option) => {
      if (option === 'no_options') {
        return (
          <StyledOptionBox key={option} paddingLeft={1.5}>
            {withEmptyOptions[option]?.name}
          </StyledOptionBox>
        );
      }

      const Icon = selectedOptions.has(option) ? RemoveIcon : AddIcon;
      return (
        <li {...state} key={option}>
          <StyledOptionBox
            paddingLeft={getNestedPadding(withEmptyOptions[option]?.level)}
            onClick={e => handleChange(e, option)}
          >
            <Ellipsis width="85%" tooltip>
              <HighlightedPhrase searchPhrase={search}>{withEmptyOptions[option]?.name || option}</HighlightedPhrase>
            </Ellipsis>
            <IconButton size="small">
              <Icon />
            </IconButton>
          </StyledOptionBox>
        </li>
      );
    },
    [handleChange, withEmptyOptions, search, selectedOptions]
  );

  return (
    <AutoCompleteVirtualize
      textFieldProps={{
        label,
        margin,
        onKeyDown: handleAddCustomOption,
        placeholder,
      }}
      disableClearable
      renderOption={renderOption}
      isOptionEqualToValue={option => selectedOptions.has(option)}
      getOptionLabel={option => withEmptyOptions[option]?.name || option}
      onDebounceChange={onSetSearch}
      filterOptions={optionsIds => filterOptions(optionsIds, withEmptyOptions, search.toLowerCase())}
      onClose={onClose}
      disableCloseOnSelect
      options={optionsIds}
      loading={loading}
      value={search}
      freeSolo
      fullWidth
      data-test={dataTest}
      forcePopupIcon={true}
    />
  );
}
