import i18n from 'i18next';

export type EpisodeObject = { label: string; eps: number[] };

export const createEpisodeArray = (from: number, to: number): number[] => {
  return Array.from(new Array(to - from + 1).keys()).map(x => x + from);
};

export const createEpisodeLabel = (episodes: number[]): string => {
  const from = episodes[0].toLocaleString('en-US', { minimumIntegerDigits: 2 }).replaceAll(',', '');
  const to = episodes[episodes.length - 1].toLocaleString('en-US', { minimumIntegerDigits: 2 }).replaceAll(',', '');

  if (from === to) return `E${from}`;

  return `E${from}-${to}`;
};

export const mapEpisodeApiValuesToState = (val: string[] | undefined): EpisodeObject[] => {
  if (!val) return [];

  const array = val.map(i => Number(i));

  return mapEpisodeRanges(array);
};

export const mapEpisodeRanges = (episodes: number[]): EpisodeObject[] => {
  const result: EpisodeObject[] = [];

  if (episodes.length === 0) return result;

  const array = Array.from(new Set(episodes)).sort((a, b) => a - b);

  let start = array[0];
  let end = start;

  for (let i = 1; i < array.length; i++) {
    if (array[i] !== end + 1) {
      const eps = createEpisodeArray(start, end);

      result.push({
        eps,
        label: createEpisodeLabel(eps),
      });

      start = array[i];
    }

    end = array[i];
  }

  const eps = createEpisodeArray(start, end);
  result.push({ eps, label: createEpisodeLabel(eps) });

  return result;
};

type ValidationArgs = {
  name: 'to' | 'from';
  currentValue: string;
  fromValue: string;
  toValue: string;
};

type ValidationResult = {
  error?: string;
};

export const validateEpisodeRange = ({ name, currentValue, fromValue, toValue }: ValidationArgs): ValidationResult => {
  if (isNaN(Number(currentValue)) || !/^\d+$/.test(currentValue)) {
    return {
      error: i18n.t('Only numbers are allowed.') || 'Only numbers are allowed.',
    };
  }

  if (Number(currentValue) > 99999) {
    return {
      error: i18n.t('Value is too large.') || 'Value is too large.',
    };
  }

  if (name === 'from' && Number(currentValue) > Number(toValue)) {
    return {
      error:
        i18n.t("The 'From' value cannot be higher than the 'To' value.") ||
        "The 'From' value cannot be higher than the 'To' value.",
    };
  }

  if (name === 'to' && Number(currentValue) < Number(fromValue)) {
    return {
      error:
        i18n.t("The 'To' value cannot be lower than the 'From' value.") ||
        "The 'To' value cannot be lower than the 'From' value.",
    };
  }

  return {
    error: undefined,
  };
};
