import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import type { AdvancedTargetingData, AdvancedTargetingType } from '@openx/types/targeting/advancedTargeting';
import type { CustomVariablesData } from '@openx/types/targeting/customVariables';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { DimensionChipLine, EmptyFilterMessage, Switch } from '../shared';

import { CustomVariablesItems } from './CustomVariables/CustomVariablesItems';
import { useGetContextualSegmentsComponents } from './hooks/useGetContextualSegmentsComponents';

type UpdateParamsType = React.Dispatch<React.SetStateAction<AdvancedTargetingData>>;
type AdvanceTargetingItemsProps = {
  params: AdvancedTargetingData;
  updateParams: UpdateParamsType;
  onAdd?: (ctxSegment: CustomVariablesData) => void;
  type?: AdvancedTargetingType;
  accountUid: string;
  targetingContext?: 'deal' | 'package';
};

export const AdvanceTargetingItems = ({
  params,
  updateParams,
  onAdd,
  type,
  accountUid,
  targetingContext,
}: AdvanceTargetingItemsProps) => {
  const { t } = useTranslation();
  const { ContextualSegmentsItems, segmentsV2 } = useGetContextualSegmentsComponents();
  const { contextualV2, custom, op } = params;
  const contextualV2Empty = isEmpty(contextualV2?.includes) && isEmpty(contextualV2?.excludes);
  const customEmpty = isEmpty(custom);
  const shouldDisplaySwitch = segmentsV2 && (!contextualV2Empty || !customEmpty);
  const displayDimensionChip = segmentsV2 && !contextualV2Empty && !customEmpty;
  const switchState = op === Criteria.ANY;
  const displaySegments = (!segmentsV2 && type === 'contextual') || segmentsV2;
  const displayCustom = (!segmentsV2 && type === 'custom') || segmentsV2;
  const displayEmptyFilterMessage = !displaySegments && !displayCustom && segmentsV2;

  return (
    <>
      {shouldDisplaySwitch && (
        <Box sx={{ marginTop: 2 }}>
          <Switch
            switchState={switchState}
            onSwitchChange={() =>
              updateParams(prevParams => ({
                ...prevParams,
                op: op === Criteria.ALL ? Criteria.ANY : Criteria.ALL,
              }))
            }
            switchOffLabel={t('all')}
            switchOnLabel={t('any')}
            groupTitle={t('of the following:')}
          />
        </Box>
      )}

      {displaySegments && (
        <ContextualSegmentsItems
          targetingParams={params}
          readonly={false}
          onChange={updateParams}
          onAdd={onAdd}
          type={type}
          accountUid={accountUid}
          targetingContext={targetingContext}
        />
      )}

      {displayDimensionChip && <DimensionChipLine targetingDimension={op} />}

      {displayCustom && <CustomVariablesItems targetingParams={params} readonly={false} onChange={updateParams} />}

      {displayEmptyFilterMessage && <EmptyFilterMessage text={t('No filters applied')} />}
    </>
  );
};
