import { Typography, styled } from '@mui/material';

export const TitleTypography = styled(Typography, { shouldForwardProp: prop => prop !== 'isDetailsPage' })<{
  isDetailsPage?: boolean;
}>`
  width: 100%;
  font-size: ${({ theme, isDetailsPage }) => (isDetailsPage ? theme.spacing(4) : theme.spacing(10))};
  text-align: ${({ isDetailsPage }) => (isDetailsPage ? 'left' : 'center')};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-family: ${({ theme }) => theme.typography.fontFamilyPoppins};
  line-height: ${({ theme, isDetailsPage }) => (isDetailsPage ? theme.spacing(5.25) : '1.5')};
`;
