import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import type { RewardedVideoOptionKeys, RewardedVideoPayload, VideoStatePayload } from '@openx/types/targeting/video';

import { getOptionKeys, getRewardedVideoOptions } from '../../utils';

type Props = {
  placeholder: string;
  onChange: (typeParams: RewardedVideoPayload | VideoStatePayload | null) => void;
  value: RewardedVideoOptionKeys | null;
};

export const RewardedVideoDropdown = ({ placeholder, onChange, value }: Props): JSX.Element => {
  const { t } = useTranslation();

  const videoOptions = useMemo(() => getRewardedVideoOptions(t), [t]);

  const handleChange = useCallback(
    (_e, value) => (value ? onChange({ op: value, val: 'true' }) : onChange(null)),
    [onChange]
  );

  return (
    <AutoComplete<RewardedVideoOptionKeys, false, false, false>
      textFieldProps={{ placeholder }}
      options={getOptionKeys(videoOptions)}
      fullWidth
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => videoOptions[option].name}
      onChange={handleChange}
      value={value}
      textFieldReadOnly
      data-test="video-input"
    />
  );
};
