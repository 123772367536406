import type { Option, OptionsList, OptionsMap } from '@openx/types/options';
import type {
  CategoryData,
  CategoryFormState,
  CategoryOption,
  CategoryOptionsMap,
} from '@openx/types/targeting/category';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';
import { prepareMapBy } from '@openx/utils/lib/prepareMapBy';

export function prepareCategoryOption(apiOption: Option, apiOptionsMap: OptionsMap): CategoryOption {
  const levels = +(apiOption.cat_level || 1);
  let fullName = apiOption.name;
  let isAllowed = apiOption.standard === 'allowed';
  let currentOption = apiOption;

  for (let i = 1; i < levels; i++) {
    if (!currentOption.parent) {
      break;
    }

    const parentOption = apiOptionsMap[currentOption.parent] as Option;

    fullName = `${parentOption.name}: ${fullName}`;
    isAllowed = isAllowed && parentOption.standard === 'allowed';

    currentOption = parentOption;
  }

  return {
    fullName,
    id: apiOption.id,
    isAllowed,
    level: apiOption.cat_level as number,
    name: apiOption.name,
  };
}

export function prepareCategoryOptions(apiOptionsMap: OptionsMap, postfix: string): CategoryOptionsMap {
  const optionsWithFullName = (Object.values(apiOptionsMap) as OptionsList).map(option => {
    const preperedOption = prepareCategoryOption(option, apiOptionsMap);
    if (preperedOption.level === 1) {
      return {
        ...preperedOption,
        fullName: `${preperedOption.fullName} ${postfix}`,
        name: `${preperedOption.name} ${postfix}`,
      };
    }

    return preperedOption;
  });

  return prepareMapBy(optionsWithFullName, 'id');
}

export function getAllowedSortedIds(categoryOptionsMap: CategoryOptionsMap): string[] {
  return Object.keys(categoryOptionsMap)
    .filter(optionId => categoryOptionsMap[optionId].isAllowed)
    .sort((a, b) => categoryOptionsMap[a].fullName.localeCompare(categoryOptionsMap[b].fullName));
}

export function filterOptions(
  optionsIds: string[],
  categoryOptionsMap: CategoryOptionsMap,
  searchPhrase: string
): string[] {
  if (searchPhrase === '') {
    return optionsIds;
  }

  let parentOptions: string[] = [];

  return optionsIds.reduce((result, optionId) => {
    const currentOption = categoryOptionsMap[optionId];
    const isOptionEligible = currentOption.fullName.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase());

    if (currentOption.level <= parentOptions.length) {
      parentOptions = parentOptions.slice(0, currentOption.level - 1);
    }

    if (currentOption.level > parentOptions.length && !isOptionEligible) {
      parentOptions.push(optionId);
    }

    if (isOptionEligible) {
      result = [...result, ...parentOptions, optionId];
      parentOptions = [];
    }

    return result;
  }, [] as string[]);
}

export function mapCategoryToApi(categories: CategoryFormState): CategoryData | null {
  if (!categories.val.size) return null;
  return { op: categories.op, val: [...categories.val].join(',') };
}

export const getDefaultParams = () => ({ op: Intersect.INTERSECTS, val: new Set<string>() });

export function mapCategoryToFormState(categories?: CategoryData | null): CategoryFormState {
  if (!categories) return getDefaultParams();

  return { op: categories.op, val: new Set(categories.val.split(',')) };
}
