import type { BaseRow } from './tableHelpers';

export enum SelectOperation {
  SELECT = 'select',
  UNSELECT = 'unselect',
}

export type ToggleSelect<RowT extends BaseRow> = (items: RowT[], operation: SelectOperation) => void;

export interface SelectListProps<RowT extends BaseRow> {
  selectedItems: RowT[];
  toggleSelect: ToggleSelect<RowT>;
}

export interface SelectableTableProps<RowT extends BaseRow> extends SelectListProps<RowT> {
  customManagementSection?: undefined;
}
