import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { NumberFilterInputs } from './NumberFilterInputs';
import { type NumberFilterData, NumberFilterFields, type NumberFilterProps } from './types';
import { useNumberFilterValidationSchema } from './validationSchema';

const initialValues: NumberFilterData = {
  [NumberFilterFields.OPTION]: null,
  [NumberFilterFields.VALUE]: '',
  [NumberFilterFields.VALUE_TO]: '',
};

export const NumberFilter = (props: NumberFilterProps) => {
  const { min, max, maxFractionDigits, setCurrentOption, currencySymbol, setError } = props;
  const validationSchema = useNumberFilterValidationSchema(min, max, maxFractionDigits);
  const formMethods = useForm<NumberFilterData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return (
    <Grid>
      <FormProvider {...formMethods}>
        <NumberFilterInputs setCurrentOption={setCurrentOption} currencySymbol={currencySymbol} setError={setError} />
      </FormProvider>
    </Grid>
  );
};
