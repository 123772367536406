import type { AudienceOption } from '@openx/types/targeting/audience';
import type { OptionsFetchResponse, UseAudienceOptionsDetailsFetchFn } from '@openx/types/targeting/targetingHookTypes';

import { useAudienceOptionsFetch } from './useAudienceOptionsFetch';

export const useAudienceOptionsDetailsFetch: UseAudienceOptionsDetailsFetchFn = (
  accountUid: string,
  type: AudienceOption | null
): OptionsFetchResponse => {
  return useAudienceOptionsFetch(accountUid, type);
};
