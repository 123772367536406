export function prepareMapBy<T>(list: T[], keyName: string | number): Record<string | number, T> {
  return list.reduce(
    (listMap, currentOption) => {
      // Check if currentOption is defined and has the property
      if (currentOption && Object.prototype.hasOwnProperty.call(currentOption, keyName)) {
        listMap[currentOption[keyName]] = currentOption;
      }
      return listMap;
    },
    {} as Record<string | number, T>
  );
}
