import type { SelectRateFormState } from '@openx/types/targeting/selectRate';
import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

export const defaultParams: SelectRateFormState = {
  op: ComparisonType.GREATER_THAN_OR_EQUAL_TO,
  val: null,
};

export const viewabilityTierLabel = 'Viewability Tier';
export const viewThroughRateTierLabel = 'View Through Rate Tier';
