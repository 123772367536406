import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';
import type { ChipFilterValue, FilterConfig, FilterOption } from '@openx/types/chipFilters';

import { MultipleSelectFilter } from './MultipleSelectFilter/MultipleSelectFilter';
import { NumberFilter } from './NumberFilter/NumberFilter';
import { SelectFilter } from './SelectFilter/SelectFilter';

const StyledAutocompleteContainerDiv = styled('div')`
  display: flex;
  flex-flow: column;
  max-width: 600px;
  min-width: 300px;
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing(3, 2, 1, 2)};
`;

const StyledPopover = styled(Popover)`
  margin-top: ${({ theme }) => theme.spacing(4.5)};
`;

const StyledFilterNameSpan = styled('span')`
  font-size: 16px;
  font-weight: 600;
`;

const StyledCloseIconButton = styled(IconButton)`
  height: 28px;
  padding: 0px;
  width: 28px;
`;

const StyledApplyButton = styled(Button)`
  margin-left: auto;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledTopContainerDiv = styled('div')`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

interface FilterItemsMenuProps {
  category: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onApplyFilter: (category: string, value: ChipFilterValue) => void;
  onClose: () => void;
  currentValue: ChipFilterValue;
  filterConfig: FilterConfig;
}

export const filterOptions = (opts: FilterOption[], value: string): FilterOption[] => {
  const valueLower = value.toLowerCase();
  return opts.filter(opt => (opt.displayName || opt.value).toString().toLowerCase().includes(valueLower));
};

export const getCustomValue = (inputValue: string | number, title = 'Any includes') => ({
  displayName: `${title}: ${inputValue}`,
  value: inputValue,
});

export function FilterItemsMenu({
  category,
  anchorEl,
  open,
  onApplyFilter,
  onClose,
  currentValue,
  filterConfig,
}: FilterItemsMenuProps) {
  const { name, options = [], loading, onInputChange, clearOnBlur, numberFilter, multiple } = filterConfig;

  const [error, setError] = useState<boolean>(false);
  const [filters, setFilters] = useState<ChipFilterValue>(currentValue);

  const filteredEmptyOptions = useMemo(
    () => options.filter(option => !option.hidden && (!!option.displayName || !!option.value)),
    [options]
  );
  const { t } = useTranslation();

  const handleApplyFilter = useCallback(() => {
    onApplyFilter(category, filters);
  }, [filters, category, onApplyFilter]);

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<{}>, value: string) => {
      if (event?.type !== 'change') {
        // call only on keyboard input
        return;
      }

      onInputChange && onInputChange(value);
    },
    [onInputChange]
  );

  const handleBlur = useCallback(() => {
    if (clearOnBlur && onInputChange) {
      onInputChange('');
    }
  }, [clearOnBlur, onInputChange]);

  const getOptionLabel = useCallback((option: FilterOption) => {
    return option.displayName || option.value?.toString() || option.toString();
  }, []);

  const applyCurrentFilter = useCallback(ft => onApplyFilter(category, ft), [onApplyFilter, category]);

  const onClearInputState = useCallback(() => {
    if (onInputChange && !currentValue) {
      onInputChange('');
    }
  }, [currentValue, onInputChange]);

  const InputValueComponent = useMemo(() => {
    if (numberFilter) {
      return (
        <NumberFilter
          {...numberFilter}
          name={name}
          onInputChange={onInputChange}
          setCurrentOption={setFilters}
          setError={setError}
        />
      );
    }

    if (multiple) {
      return (
        <MultipleSelectFilter
          setCurrentFilter={setFilters}
          currentValue={currentValue}
          filterConfig={filterConfig}
          filteredEmptyOptions={filteredEmptyOptions}
          handleBlur={handleBlur}
          getOptionLabel={getOptionLabel}
          handleOnInputChange={handleOnInputChange}
          applyCurrentFilter={applyCurrentFilter}
        />
      );
    }
    return (
      <SelectFilter
        setCurrentFilter={setFilters}
        currentValue={currentValue}
        filterConfig={filterConfig}
        filteredEmptyOptions={filteredEmptyOptions}
        handleBlur={handleBlur}
        getOptionLabel={getOptionLabel}
        handleOnInputChange={handleOnInputChange}
        applyCurrentFilter={applyCurrentFilter}
      />
    );
  }, [
    currentValue,
    applyCurrentFilter,
    filterConfig,
    multiple,
    numberFilter,
    filteredEmptyOptions,
    handleBlur,
    getOptionLabel,
    onInputChange,
    handleOnInputChange,
    name,
  ]);

  return (
    <StyledPopover
      id="filter-items"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => {
        onClose();
        onClearInputState();
      }}
    >
      <div>
        <StyledTopContainerDiv>
          <StyledFilterNameSpan data-test="filter-name">{name}</StyledFilterNameSpan>
          <StyledCloseIconButton
            onClick={() => {
              onClose();
              onClearInputState();
            }}
            data-test="close-filter-dialog"
            size="large"
          >
            <CloseIcon />
          </StyledCloseIconButton>
        </StyledTopContainerDiv>

        <StyledAutocompleteContainerDiv>
          {InputValueComponent}
          <StyledApplyButton
            disabled={!filters || filters === currentValue || loading || error}
            variant="text"
            color="primary"
            onClick={handleApplyFilter}
            data-test="apply-button"
          >
            {t('Apply')}
          </StyledApplyButton>
        </StyledAutocompleteContainerDiv>
      </div>
    </StyledPopover>
  );
}
