import { Box, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { type UrlTargeting, UrlTargetingType } from '@openx/types/targeting/domains';
import { bigNumberWithCommas } from '@openx/utils/lib/bigNumberWithCommas';

import { FiltersTitle, Switch, TextFieldEditor, type TextFieldEditorHandle, getItemsMaxRows } from '../../shared';
import type { TargetingItemsProps } from '../../types';
import { DOMAINS_LIMIT, useBulkAddPlaceholder } from '../constants';
import { useValidate } from '../utils/validate';

interface EditOnlyTargetingItems extends TargetingItemsProps<UrlTargeting> {
  handleReset: () => UrlTargeting;
  shouldDisplayResetButton: boolean;
}

export const DomainsItemsEditOnly = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  setApplyEnabled = () => {},
  handleReset = () => ({ type: UrlTargetingType.blacklist, urls: [] }),
  shouldDisplayResetButton = false,
}: EditOnlyTargetingItems) => {
  const textFieldEditorRef = useRef<TextFieldEditorHandle | null>(null);
  const { t } = useTranslation();
  const bulkPlaceholder = useBulkAddPlaceholder();
  const { type, urls } = targetingParams;
  const switchState = type === UrlTargetingType.blacklist;
  const itemsMaxRows = getItemsMaxRows(readonly);
  const currentLimit = DOMAINS_LIMIT - urls.length;

  const handleChange = useCallback(
    (values: string[]) => {
      onChange({
        type,
        urls: values,
      });
    },
    [onChange, type]
  );

  const handleClear = useCallback(() => {
    handleChange([]);
    setApplyEnabled(true);
    textFieldEditorRef.current?.initializeEditor('');
  }, [handleChange, setApplyEnabled]);

  const validate = useValidate();

  return (
    <Box
      data-test="sub-section"
      sx={theme => ({
        background: theme.palette.background.boxLight,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(0, -2.5, -1),
        padding: theme.spacing(0.1, 2.5, 1),
      })}
    >
      <FiltersTitle
        onClear={handleClear}
        title={t('Domains [ {count} ]', { count: urls.length })}
        readonly={readonly}
      />

      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange({
              type: type === UrlTargetingType.blacklist ? UrlTargetingType.whitelist : UrlTargetingType.blacklist,
              urls,
            })
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}

      <TextFieldEditor
        ref={textFieldEditorRef}
        initItems={urls.join('\n')}
        onApply={handleChange}
        placeholder={bulkPlaceholder}
        validate={validate}
        setApplyEnabled={setApplyEnabled}
        helperText={t('Enter up to {limit} domains, subdomains, or specific page URLs.', {
          limit: bigNumberWithCommas(currentLimit),
        })}
        limit={DOMAINS_LIMIT}
        maxRows={itemsMaxRows}
        handleReset={handleReset}
        shouldDisplayResetButton={shouldDisplayResetButton}
      >
        <Typography
          sx={theme => ({
            color: theme.palette.text.hint,
            fontSize: `${theme.typography.body2.fontSize}px`,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.caption.lineHeight,
            marginLeft: theme.spacing(1.5),
            marginTop: theme.spacing(1),
          })}
        >
          {t('Domains')}
        </Typography>
      </TextFieldEditor>
    </Box>
  );
};
