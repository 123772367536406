import i18n from 'i18next';

import { GeoLocationTypes } from '@openx/types/geoLocation';
import {
  BoundingBoxOptions,
  type GeoLocationType,
  GeographicOption,
  type GeographicTargetingState,
} from '@openx/types/targeting/geographic';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

const emptyLocalization: GeoLocationType = {
  [GeoLocationTypes.CONTINENT]: [],
  [GeoLocationTypes.COUNTRY]: [],
  [GeoLocationTypes.REGION]: [],
  [GeoLocationTypes.DMA]: [],
  [GeoLocationTypes.MSA]: [],
  [GeoLocationTypes.STATE]: [],
  [GeoLocationTypes.CITY]: [],
  [GeoLocationTypes.POSTAL_CODE]: [],
};

export const geographicLabels = {
  [GeoLocationTypes.CONTINENT]: 'Continent',
  [GeoLocationTypes.COUNTRY]: 'Country',
  [GeoLocationTypes.REGION]: 'Region',
  [GeoLocationTypes.STATE]: 'State',
  [GeoLocationTypes.DMA]: 'DMA',
  [GeoLocationTypes.MSA]: 'MSA',
  [GeoLocationTypes.CITY]: 'City',
  [GeoLocationTypes.POSTAL_CODE]: 'Postal Code',
};

export const getDefaultGeographicState = (): GeographicTargetingState => ({
  [GeographicOption.BOUNDING_BOX]: {
    [BoundingBoxOptions.LATMAX]: '',
    [BoundingBoxOptions.LATMIN]: '',
    [BoundingBoxOptions.LONGMAX]: '',
    [BoundingBoxOptions.LONGMIN]: '',
  },
  [GeographicOption.CIRCLES]: [],
  [GeographicOption.INCLUDES]: {
    ...emptyLocalization,
  },
  [GeographicOption.EXCLUDES]: {
    ...emptyLocalization,
  },
  [GeographicOption.EXCLUDES_SUBSET]: [],
  [GeographicOption.SOURCE]: { op: Intersect.INTERSECTS, val: new Set<string>() },
});

export const locationHierarchy = [
  GeoLocationTypes.CONTINENT,
  GeoLocationTypes.COUNTRY,
  GeoLocationTypes.REGION, // Region, dma, msa is on the same hierarchy but state/city/postal_code
  GeoLocationTypes.DMA, //  will never match to dma/msa so we can leave it here
  GeoLocationTypes.MSA,
  GeoLocationTypes.STATE,
  GeoLocationTypes.CITY,
  GeoLocationTypes.POSTAL_CODE,
];

export const BULK_ADD_DEFAULT_COUNTRY = 'us';

export const useBulkAddPlaceholder = () => {
  return i18n.t(`Postal code may be entered as either comma, semicolon,
or new line separated values.

Examples:
77502, 77506
or
77502;77506
or
77502
77506`);
};

export const geographicPlaceholder = {
  [GeoLocationTypes.CONTINENT]: 'Enter a continent and press enter',
  [GeoLocationTypes.COUNTRY]: 'Enter a country and press enter',
  [GeoLocationTypes.REGION]: 'Enter a region and press enter',
  [GeoLocationTypes.DMA]: 'Enter a DMA and press enter',
  [GeoLocationTypes.MSA]: 'Enter a MSA and press enter',
  [GeoLocationTypes.STATE]: 'Enter a state and press enter',
  [GeoLocationTypes.CITY]: 'Enter a city and press enter',
  [GeoLocationTypes.POSTAL_CODE]: 'Enter a postal code and press enter',
};

export const geographicDefaultOptions = {
  availableOptions: [
    GeographicOption.EXCLUDES,
    GeographicOption.EXCLUDES_SUBSET,
    GeographicOption.INCLUDES,
    GeographicOption.CIRCLES,
  ],
  defaultOption: GeographicOption.INCLUDES,
};
