import { isEmpty } from 'lodash';

import type { AdPlacement } from '@openx/types/adUnit';
import type { DistributionChannel } from '@openx/types/distributionChannel';
import { type FormatTypeData, InventoryFormatTypeOption } from '@openx/types/targeting/formatType';

export const countItems = (adPlacement?: AdPlacement[], distributionChannel?: DistributionChannel[]) =>
  (adPlacement?.length ?? 0) + (distributionChannel?.length ?? 0);

export const checkForEmptyParams = (params: FormatTypeData | null): boolean => {
  if (!params) return true;

  return (
    isEmpty(params[InventoryFormatTypeOption.AD_PLACEMENT]) &&
    isEmpty(params[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]) &&
    !!params.op
  );
};
