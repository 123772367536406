import type { InvalidItemsError } from '@openx/components/core/lib/TransferList/bulk/types';
import type { ConstValues } from '@openx/types/helpers';

export type State = {
  value: string;
  errors: InvalidItemsError[];
  validItems: string[];
  isValidating: boolean;
  isResetEnabled: boolean;
};

export enum BulkAction {
  SetValue,
  SetErrors,
  SetValidItems,
  SetValidating,
  SetResetEnabled,
  InitValues,
}

export type BulkActionType = ConstValues<typeof BulkAction>;

export type Action =
  | { type: BulkAction.SetValue; value: string }
  | { type: BulkAction.SetErrors; errors: InvalidItemsError[] }
  | { type: BulkAction.SetValidItems; validItems: string[] }
  | { type: BulkAction.SetValidating; isValidating: boolean }
  | { type: BulkAction.SetResetEnabled; isResetEnabled: boolean }
  | {
      type: BulkAction.InitValues;
      errors?: InvalidItemsError[];
      isResetEnabled?: boolean;
      isValidating?: boolean;
      validItems?: string[];
      value: string;
    };
