import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import { useOptionsFetch } from '@openx/utils/apiHooks/generics/useOptionsFetch';

export type EidsOptionsFetchResponse = {
  isLoading: boolean;
  trafficFilterEids: OptionsMap;
};

export function useTrafficFilterEidsOptionsFetch() {
  const { t } = useTranslation();
  const { isLoading, data: trafficFilterEids } = useOptionsFetch({
    errorMessage: t('Failed to fetch traffic filter eids'),
    optionType: OptionsType.TRAFFIC_FILTER_EIDS,
  });

  return {
    isLoading,
    trafficFilterEids,
  };
}
