import { FormLabel, InputAdornment } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type ContentObjectAction,
  type ContentObjectOption,
  ContentObjectOptions,
  type ContentObjectProps,
} from '@openx/types/targeting/contentObject';

import { inputPlaceholder } from '../../../constants';

import { StyledButton, StyledButtonContainer, StyledGrid, StyledTextField } from './components';
import { useEpisodeValidation } from './useEpisodeValidation';
import { createEpisodeArray, mapEpisodeRanges } from './utils';

export const EpisodeOptions = (props: {
  type: ContentObjectOption;
  formState: Pick<ContentObjectProps, 'episode'>;
  dispatch: (action: ContentObjectAction) => void;
}) => {
  const { dispatch, formState, type } = props;

  const { t } = useTranslation();
  const { fromField, toField, onChange: handleOnChange, isValid, resetFields } = useEpisodeValidation();
  const episodes = formState[type].val;

  const placeholder = inputPlaceholder(ContentObjectOptions.EPISODE);
  const inputAdornment = <InputAdornment position="start">E</InputAdornment>;

  const onChange = useCallback(
    episodes => {
      dispatch({ type, values: episodes });
    },
    [dispatch, type]
  );

  const handleAddEpisodes = useCallback(() => {
    const newEpisodes = createEpisodeArray(Number(fromField.value), Number(toField.value));
    const previousEpisodes = episodes.reduce((acc, obj) => {
      acc.push(...obj.eps);
      return acc;
    }, [] as number[]);

    onChange(mapEpisodeRanges([...previousEpisodes, ...newEpisodes]));
    resetFields();
  }, [fromField.value, toField.value, episodes, onChange, resetFields]);

  return (
    <>
      <StyledGrid item xs={4} data-test={'episode-from'}>
        <FormLabel error={!!fromField.error}>{t('From')}</FormLabel>
        <StyledTextField
          fullWidth
          margin="none"
          placeholder={placeholder}
          value={fromField.value}
          helperText={fromField.error}
          error={!!fromField.error}
          onChange={e => handleOnChange('from', e.currentTarget.value)}
          variant={'outlined' as 'standard'}
          InputProps={{
            startAdornment: inputAdornment,
          }}
        />
      </StyledGrid>
      <StyledGrid item xs={4} data-test={'episode-to'}>
        <FormLabel error={!!toField.error}>{t('To')}</FormLabel>
        <StyledTextField
          fullWidth
          margin="none"
          placeholder={placeholder}
          value={toField.value}
          helperText={toField.error}
          error={!!toField.error}
          onChange={e => handleOnChange('to', e.currentTarget.value)}
          variant={'outlined' as 'standard'}
          InputProps={{
            startAdornment: inputAdornment,
          }}
        />
      </StyledGrid>
      <StyledButtonContainer item xs={1}>
        <StyledButton color="primary" onClick={handleAddEpisodes} disabled={!isValid}>
          {t('Add')}
        </StyledButton>
      </StyledButtonContainer>
    </>
  );
};
