import type { UseConfirmationDialogState } from '../types';

import { ConfirmationDialog } from './ConfirmationDialog';

export const GenericModal = ({ config, close }: { config: UseConfirmationDialogState; close: () => void }) => (
  <ConfirmationDialog
    title={config.title}
    isOpen={config.isOpen}
    isLoading={config.isLoading}
    onConfirmed={config.onConfirmed}
    onCancel={() => {
      config.onCancel();
      close();
    }}
    onExtraAction={config.onExtraAction}
    confirmationButtonColor={config.confirmationButtonColor}
    confirmLabel={config.confirmationLabel}
    cancelLabel={config.cancelLabel}
    extraBtnLabel={config.extraBtnLabel}
    customContent={config.customContent}
    confirmationDisabled={config.confirmationDisabled}
    hideConfirm={config.hideConfirm}
    showExtraBtn={config.showExtraBtn}
    data-test="confirmation-dialog"
  >
    {config.content}
  </ConfirmationDialog>
);
