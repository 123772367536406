import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type Dispatch, type KeyboardEvent, type SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyAction } from 'redux';

import {
  type IntersectOptionPayload,
  type InventoryAndContentOptionPayload,
  InventoryContentOption,
} from '@openx/types/targeting/inventoryAndContent';
import type { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';
import { removeWhiteSpaces } from '@openx/utils/lib/removeWhiteSpaces';

import { setComparisonOpValue } from '../../../shared/InventoryAndContent/inventoryHelpers';
import { InventoryComparisonTypeDropdown } from '../InventoryComparisonTypeDropdown';
import { InventoryInput } from '../InventoryInput';

const DropdownContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

interface ComparisonProps {
  comparisonType: ComparisonType;
  dispatchInventory: (action: AnyAction) => void;
  inventoryContent: InventoryAndContentOptionPayload | IntersectOptionPayload;
  itemsCount: number;
  setComparisonType: Dispatch<SetStateAction<ComparisonType>>;
  contentOption: InventoryContentOption;
}

export const Comparison = ({
  comparisonType,
  dispatchInventory,
  inventoryContent,
  itemsCount,
  setComparisonType,
  contentOption,
}: ComparisonProps): JSX.Element => {
  const [errorText, setErrorText] = useState('');
  const { t } = useTranslation();

  const handleOnKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!comparisonType) {
        return;
      }

      const targetElement = e.target as HTMLInputElement;
      let { value } = targetElement;

      if (e.key === 'Enter' && value) {
        const inventoryContentOptionComparisonData = inventoryContent.val[comparisonType] || [];

        value = value.trim();

        if (contentOption === InventoryContentOption.PAGE_URL) {
          value = removeWhiteSpaces(value);
        }

        const alreadyExists = inventoryContentOptionComparisonData.includes(value);
        const isEmpty = value === '';

        if (alreadyExists) {
          setErrorText(t('This item is already on the list.'));

          return false;
        }

        if (isEmpty) {
          setErrorText(t('This field cannot be empty.'));
          return false;
        }

        dispatchInventory({
          payload: { comparisonType, values: [...inventoryContentOptionComparisonData, value] },
          type: setComparisonOpValue[contentOption],
        });
        targetElement.value = '';
      }

      setErrorText('');
    },
    [comparisonType, contentOption, dispatchInventory, inventoryContent.val, t]
  );

  return (
    <Grid container spacing={1}>
      <DropdownContainer item xs={4}>
        <InventoryComparisonTypeDropdown option={contentOption} value={comparisonType} onChange={setComparisonType} />
      </DropdownContainer>
      <DropdownContainer item xs={8}>
        <InventoryInput option={contentOption} onKeyUp={handleOnKeyUp} errorText={errorText} itemsCount={itemsCount} />
      </DropdownContainer>
    </Grid>
  );
};
