import { omit } from 'lodash';
import { useMemo } from 'react';

import type { TargetingReadOnlyProps } from '@openx/types/targeting/targeting';
import { type VideoFormState, VideoOption } from '@openx/types/targeting/video';

import { DimensionChipLine, TargetingReadOnly, useGetFilteredFields } from '../shared';

import { Items } from './Items';
import { PlacementAndFormat, RewardedVideoItem, VideoSkipabilityItem } from './components';
import { placementAndFormat } from './constants';
import { isEmptyState } from './utils';

export const VideoReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  readonly,
  isDisabled,
  dataTest,
}: TargetingReadOnlyProps<VideoFormState>): JSX.Element => {
  const targetingFields = omit(targetingParams, [VideoOption.REWARDED_VIDEO, VideoOption.VIDEO_SKIPABILITY]);

  const payloadVideoTypes = useGetFilteredFields(targetingFields || {});

  const hasBothPlcmntFormat = placementAndFormat.every(type => payloadVideoTypes.includes(type));

  const videoTypes = useMemo(
    () =>
      hasBothPlcmntFormat
        ? payloadVideoTypes.filter(type => type !== VideoOption.PLACEMENT && type !== VideoOption.PRESENTATION_FORMAT)
        : payloadVideoTypes,
    [hasBothPlcmntFormat, payloadVideoTypes]
  );

  return (
    <TargetingReadOnly
      name={name}
      readonly={readonly}
      isEmpty={isEmptyState(targetingParams)}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      isDisabled={isDisabled}
      dataTest={dataTest}
    >
      {targetingParams && !isEmptyState(targetingParams) && (
        <>
          {videoTypes.map((type, index) => {
            const shouldAddDimensionChipLine =
              videoTypes.length - 1 > index ||
              !!targetingParams[VideoOption.REWARDED_VIDEO] ||
              !!targetingParams[VideoOption.VIDEO_SKIPABILITY] ||
              hasBothPlcmntFormat;

            return (
              !!targetingParams[type].val.size && (
                <div key={type}>
                  <Items params={targetingParams[type]} type={type} />

                  {shouldAddDimensionChipLine && <DimensionChipLine />}
                </div>
              )
            );
          })}

          {hasBothPlcmntFormat && (
            <PlacementAndFormat
              placementAndFormat={placementAndFormat}
              params={targetingParams}
              readonly
              shouldAddDimensionLine={!!targetingParams[VideoOption.REWARDED_VIDEO]}
            />
          )}
          {!!targetingParams[VideoOption.REWARDED_VIDEO] && (
            <RewardedVideoItem
              params={targetingParams[VideoOption.REWARDED_VIDEO]}
              shouldAddDimensionLine={!!targetingParams[VideoOption.VIDEO_SKIPABILITY]}
            />
          )}
          {!!targetingParams[VideoOption.VIDEO_SKIPABILITY] && (
            <VideoSkipabilityItem params={targetingParams[VideoOption.VIDEO_SKIPABILITY]} />
          )}
        </>
      )}
    </TargetingReadOnly>
  );
};
