import { OptionsType } from '@openx/types/options';
import type { SelectRateFormState } from '@openx/types/targeting/selectRate';
import { TargetingFields, type TargetingProps } from '@openx/types/targeting/targeting';

import { SelectRate } from '../shared';
import { viewabilityTierLabel } from '../shared/SelectRate/constants';

export function Viewability({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  isDisabled,
}: TargetingProps<SelectRateFormState>) {
  return (
    <SelectRate
      targetingParams={targetingParams}
      onFieldUpdate={onFieldUpdate}
      name={name}
      readonly={readonly}
      field={TargetingFields.VIEWABILITY}
      isDisabled={isDisabled}
      placeholder={viewabilityTierLabel}
      filterTitle={viewabilityTierLabel}
      optionsFetch={OptionsType.VIEWABILITY}
      dataTest="viewability"
    />
  );
}
