import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type InventoryAndContentState, InventoryContentOption } from '@openx/types/targeting/inventoryAndContent';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { DimensionChipLine, FiltersTitle, SelectedItems, Switch } from '../../shared';
import { INVENTORY_IDS_DISPLAY_LIMIT, useInventoryAndContentLabels } from '../../shared/InventoryAndContent/constants';
import {
  resetFilter,
  setComparisonOpValue,
  setContentCategoryOp,
} from '../../shared/InventoryAndContent/inventoryHelpers';
import type { TargetingItemsProps } from '../../types';

export const KeywordItems = ({
  dispatchInventory = () => {},
  readonly = true,
  targetingParams,
  isNotLastItem,
  switchState: chipLineSwitchState,
}: TargetingItemsProps<InventoryAndContentState>) => {
  const { t } = useTranslation();

  const optionPayload = targetingParams[InventoryContentOption.KEYWORDS];
  const { op: dimension, val } = optionPayload;

  const labels = useInventoryAndContentLabels();
  const label = labels[InventoryContentOption.KEYWORDS].label;

  const switchState = dimension === Intersect.NOT_INTERSECTS;
  const filtersTitle = dimension === Intersect.NOT_INTERSECTS ? t('Excluded') : t('Included');
  const itemsCount = val.length;

  return (
    <Grid data-test="sub-section">
      <FiltersTitle
        onClear={() =>
          dispatchInventory({
            payload: {
              contentOption: InventoryContentOption.KEYWORDS,
            },
            type: resetFilter,
          })
        }
        title={`${label} [ ${itemsCount} ]`}
        readonly={readonly}
      />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            dispatchInventory({
              payload: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
              type: setContentCategoryOp[InventoryContentOption.KEYWORDS],
            })
          }
          switchOffLabel={t('include')}
          switchOnLabel={t('exclude')}
        />
      )}

      <Grid data-test="section-items">
        <SelectedItems
          filtersTitle={filtersTitle}
          isAddIcon={dimension === Intersect.INTERSECTS}
          selectedItems={val}
          readonly={readonly}
          handleChange={values => {
            dispatchInventory({
              payload: values,
              type: setComparisonOpValue[InventoryContentOption.KEYWORDS],
            });
          }}
          isRemovingAll={true}
          customDisplayLimit={INVENTORY_IDS_DISPLAY_LIMIT}
          dataTest="keywords"
        />
      </Grid>
      {isNotLastItem && <DimensionChipLine switchState={chipLineSwitchState} />}
    </Grid>
  );
};
