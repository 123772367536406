import { cloneDeep, isEqual } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';

import { type UrlTargeting, UrlTargetingType } from '@openx/types/targeting/domains';
import type { TargetingProps } from '@openx/types/targeting/targeting';

import { TargetingDrawer, useDrawer } from '../shared';

import { DomainsReadOnly } from './DomainsReadOnly';
import { DomainsItemsEditOnly } from './components/index';
import { useResetDomainsToAccountDefault } from './hooks';

const defaultState: UrlTargeting = {
  type: UrlTargetingType.whitelist,
  urls: [],
};

export const Domains = memo(function Domains({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  accountUid,
}: TargetingProps<UrlTargeting>) {
  const initState = useMemo(() => (targetingParams ? cloneDeep(targetingParams) : defaultState), [targetingParams]);
  const [params, updateParams] = useState<UrlTargeting>(initState);
  const isDataUpdated = useMemo(() => !isEqual(initState, params), [initState, params]);
  const [isApplyEnabled, setApplyEnabled] = useState(false);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => onFieldUpdate(field, params.urls.length ? params : null),
    restoreParams: () => updateParams({ ...initState }),
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    updateParams(defaultState);
  }, [onFieldUpdate, field]);

  const { handleResetToAccountDefault, inheritanceInfoComponent, shouldDisplayResetButton } =
    useResetDomainsToAccountDefault({
      accountUid,
      field,
      initState,
      onFieldUpdate,
      onRemoveAll,
      updateParams,
    });

  return (
    <>
      <DomainsReadOnly
        name={name}
        readonly={readonly}
        targetingParams={targetingParams}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
        isDisabled={isDisabled}
        infoComponent={inheritanceInfoComponent}
      />
      {isOpen && (
        <TargetingDrawer
          name={name}
          onClose={onDrawerClose}
          onApply={onDrawerApply}
          isDataUpdated={isApplyEnabled && isDataUpdated}
        >
          <DomainsItemsEditOnly
            targetingParams={params}
            readonly={false}
            onChange={updateParams}
            setApplyEnabled={setApplyEnabled}
            handleReset={handleResetToAccountDefault}
            shouldDisplayResetButton={shouldDisplayResetButton}
          />
        </TargetingDrawer>
      )}
    </>
  );
});
