import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';
import { useOptionsFetch } from '@openx/utils/apiHooks/generics/useOptionsFetch';

export function usePmpSizesFetch() {
  const { t } = useTranslation();
  const { isLoading: isSizesLoading, data: sizes } = useOptionsFetch({
    errorMessage: t('Failed to fetch ad unit sizes'),
    optionType: OptionsType.PMP_SIZE,
  });

  return {
    isSizesLoading,
    sizes,
  };
}
