import { createReducer } from '@reduxjs/toolkit';
import { useReducer } from 'react';

import { clearSelectedInstance, setSelectedInstance } from '../../shared/InventoryAndContent/inventoryHelpers';

export const useInstanceState = (initialState: string) => {
  const reducer = createReducer(initialState, builder => {
    builder
      .addCase(setSelectedInstance, (_state, action) => {
        return action.payload;
      })
      .addCase(clearSelectedInstance, () => {
        return '';
      });
  });

  const [instanceState, dispatchInstanceState] = useReducer(reducer, initialState);

  return { dispatchInstanceState, instanceState };
};
