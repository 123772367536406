import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ReactQueryStaleTime } from '@openx/constants/react-query';
import { type OptionsMap, ParameterizedOptionsType } from '@openx/types/options';

import { prepareMapBy } from '../lib/prepareMapBy';
import { fetchOptions } from '../state/parameterizedOptions/api';

export function useOpenAudiencesOptionsFetch(accountUid: string) {
  const { t } = useTranslation();

  const {
    isLoading: isAudiencsesLoading,
    data: audiences,
    refetch: refetchAudiences,
  } = useQuery<OptionsMap>({
    enabled: false,
    meta: {
      errorMessage: t('Failed to fetch Open Audiences'),
    },
    queryFn: async () => {
      if (!accountUid) {
        return {};
      }
      const list = await fetchOptions({
        params: { account_uid: accountUid },
        type: ParameterizedOptionsType.OPEN_AUDIENCES,
      });
      return prepareMapBy(list, 'id');
    },
    queryKey: [ParameterizedOptionsType.OPEN_AUDIENCES, accountUid],
    staleTime: ReactQueryStaleTime.MINUTES_15,
  });

  return {
    audiences: audiences || {},
    isAudiencsesLoading,
    refetchAudiences,
  };
}
