import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useTargetingLanguagesOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isTargetingLanguagesLoading,
    data: targetingLanguages,
    refetch: refetchTargetingLanguages,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch targeting languages'),
    optionType: OptionsType.TARGETING_LANGUAGE,
    queryOptions,
  });

  return {
    isTargetingLanguagesLoading,
    refetchTargetingLanguages,
    targetingLanguages,
  };
}
