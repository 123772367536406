import type { Account } from './account';
import type { ConstValues } from './helpers';
import type { Provider } from './provider';
import type { Audience } from './targeting/audience';

export const segmentExpiredText = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has expired segment.'
    : 'Segment data is expired and cannot be used for audience creation.';

export const segmentSoonWillBeExpired = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has segments that will expire in next 10 days.'
    : 'Segment is going to expire within the next 10 days.';

// calculated status
export const expiringSoonStatus = 'expiring_soon';
export const segmentStatus = {
  AWAITING_DATA: 'awaiting_data',
  ERROR: 'error',
  EXPIRED: 'expired',
  PUBLISHING: 'publishing',
  READY: 'ready',
} as const;
export type SegmentStatus = ConstValues<typeof segmentStatus>;

export interface SegmentAudiences {
  audience: Audience;
  audience_id: string;
  id: string;
  segment: Segment;
  segment_id: string;
}

type SegmentDetailedInfo = {
  provided: number;
  matched: number;
};

type SegmentKeys =
  | 'choreograph'
  | 'cookie'
  | 'idl'
  | 'liveintent'
  | 'device'
  | 'merkle'
  | 'epsilon'
  | 'analyticsiq'
  | 'iqvia'
  | 'email'
  | 'phone'
  | 'rtbhouse'
  | 'klover'
  | 'horizon'
  | 'healthlink'
  | 'ip'
  | 'contanuity'
  | 'id5'
  | 'bombora'
  | 'fantix'
  | 'catalist'
  | 'azerion'
  | 'growthcode';

export type SegmentDetailedCounts = {
  [Key in SegmentKeys]?: SegmentDetailedInfo;
};
export interface CreateSegment {
  account: Pick<Account, 'name'>;
  category?: string | null;
  created_date?: string;
  expiration_date?: string;
  full_name?: string | null;
  id: string;
  name: string;
  provider?: Pick<Provider, 'name'> | null;
  reach?: number | null;
  status?: string | null;
  sub_category?: string | null;
  sub_type?: string | null;
  type?: string | null;
  updated_date?: string;
}

export interface Segment extends CreateSegment {
  account_id: string;
  description: string;
  external_id?: string;
  full_name?: string;
  is_archived?: boolean;
  is_leaf?: boolean;
  is_nav_only?: boolean;
  propensity?: string;
  provider_id?: string;
  segment_audiences: SegmentAudiences[];
  source_matched_count?: bigint;
  source_provided_count?: bigint;
  source_detailed_counts?: SegmentDetailedCounts | null;
}

export const renameSegmentFormField = 'new_name';

export type ListSegment = Pick<
  Segment,
  'id' | 'name' | 'reach' | 'status' | 'created_date' | 'updated_date' | 'expiration_date' | 'account' | 'provider'
>;

export const segmentRowActionType = {
  DELETE: 'ARCHIVE',
  RENAME: 'RENAME',
} as const;
export type SegmentRowActionType = ConstValues<typeof segmentRowActionType>;

export const uploadSegmentFormFields = {
  DATA_TYPE: 'dataType',
  FILE: 'file',
  NAME: 'name',
} as const;
export type UploadSegmentFormFields = ConstValues<typeof uploadSegmentFormFields>;

export const defaultSegmentCategory = 'direct';
export const defaultSegmentSubCategory = 'uploaded';
export const defaultSegmentProvider = 'uploaded';
