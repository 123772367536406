import { type Theme, css, styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const sharedLinkStyles = (theme: Theme, isDisabled: boolean) => css`
  color: ${theme.palette.primary.main};
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  padding: 0px;
  text-transform: none;

  ${
    isDisabled &&
    css`
    color: #666666;
    pointer-events: none;
  `
  }
`;

const StyledOutsideLink = styled('a')<{ isDisabled: boolean }>`
  ${({ theme, isDisabled }) => sharedLinkStyles(theme, isDisabled)}
`;

interface LinkProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  ['data-test']?: string;
  state?: Record<string, unknown>;
}

interface OutsideLinkProps extends LinkProps {
  openInNewTab?: boolean;
}

export const Link = (props: LinkProps) => {
  const { to, children, onClick, isDisabled = false, state } = props;
  const dataTest = props['data-test'] || 'page-link';

  const StyledLink = styled(RouterLink, { shouldForwardProp: prop => prop !== 'isDisabled' })<{ isDisabled: boolean }>`
    ${({ theme, isDisabled }) => sharedLinkStyles(theme, isDisabled)}
  `;

  return (
    <StyledLink isDisabled={isDisabled} data-test={dataTest} to={to} onClick={onClick} state={state}>
      {children}
    </StyledLink>
  );
};

export const OutsideLink = (props: OutsideLinkProps) => {
  const { to, children, onClick, isDisabled = false, openInNewTab = false } = props;
  const dataTest = props['data-test'] || 'page-link';

  return (
    <StyledOutsideLink
      isDisabled={isDisabled}
      onClick={onClick ? onClick : () => {}}
      href={to}
      target={openInNewTab ? '_blank' : '_self'}
      data-test={dataTest}
      rel="noreferrer"
    >
      {children}
    </StyledOutsideLink>
  );
};
