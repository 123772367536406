import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import type { Account } from '@openx/types/account';
import { QueryKeys } from '@openx/utils/api/queryKeys';
import { fetchApiResponseHandler } from '@openx/utils/api/utils';

export const useAccountFetch = (accountUid?: string) => {
  const { t } = useTranslation();

  const {
    isLoading: isAccountLoading,
    data: account,
    refetch,
  } = useQuery<Account>({
    enabled: !!accountUid,
    meta: {
      errorMessage: t('Failed to fetch Account.'),
    },
    queryFn: () => fetchApiResponseHandler<Account>(axios.get(`/account/${accountUid}`), undefined, true),
    queryKey: [QueryKeys.ACCOUNT, accountUid],
    retry: false,
  });

  return {
    account: account || null,
    isAccountLoading,
    refetch,
  };
};
