import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useAPiFrameworksOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isApiFrameworksLoading,
    data: apiFrameworks,
    refetch: refetchApiFrameworks,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch api framework options'),
    optionType: OptionsType.VIDEO_API_FRAMEWORK,
    queryOptions,
  });

  return {
    apiFrameworks,
    isApiFrameworksLoading,
    refetchApiFrameworks,
  };
}
