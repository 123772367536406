import { GlobalStyles, StyledEngineProvider, ThemeProvider, css } from '@mui/material';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProviders } from 'configs';
import type { SnackbarProvider as ISnackbarProvider } from 'notistack';
import { useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ConfirmationDialogProvider } from '@openx/components/core/lib/Dialog/ConfirmationDialog/ConfirmationDialogProvider';
import { SiteUnavailablePopup } from '@openx/components/core/lib/SiteUnavailablePopup/SiteUnavailablePopup';
import { SnackbarProvider } from '@openx/components/core/lib/Snackbar/SnackbarProvider';
import { theme } from '@openx/components/core/lib/theme/theme';
import { queryClientOptions } from '@openx/constants/react-query';
import { onQueryError } from '@openx/utils/api/onQueryError';

import { AppRoutes } from 'components/AppRoutes';
import { ErrorPage } from 'components/ErrorPage';
import { DEAL_LIBRARY_VERSION } from 'config';
import { store } from 'store';

const globalStyles = css`
  * {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
`;

export function App() {
  const snackbarRef = useRef<ISnackbarProvider>(null);
  const queryClient = useRef(
    new QueryClient({
      ...queryClientOptions,
      queryCache: new QueryCache({
        onError: (error, query) => onQueryError(error, query, snackbarRef.current),
      }),
    })
  );

  useEffect(() => {
    console.info(`%cDeal Library version: ${DEAL_LIBRARY_VERSION}`, 'font-weight:bold;');
  }, []);

  return (
    <QueryClientProvider client={queryClient.current}>
      <ReactQueryDevtools />
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ConfigProviders>
              <SnackbarProvider ref={snackbarRef}>
                <GlobalStyles styles={globalStyles} />
                <ErrorBoundary fallbackRender={() => <ErrorPage />}>
                  <ConfirmationDialogProvider>
                    {/* added redux in order to make libs work */}
                    <Provider store={store}>
                      <SiteUnavailablePopup />
                      <AppRoutes />
                    </Provider>
                  </ConfirmationDialogProvider>
                </ErrorBoundary>
              </SnackbarProvider>
            </ConfigProviders>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
