import { capitalize } from 'lodash';

import {
  type GeoLocation,
  type GeoLocationName,
  GeoLocationTypes,
  type GeoLocationsList,
  type GeographyEntities,
} from '@openx/types/geoLocation';
import type { RadiusCircle } from '@openx/types/targeting/geographic';

export function buildSearchQueryFromGeographyBulk(searchConfig: GeographyEntities): string {
  const queries = Object.entries(searchConfig).map(value => {
    if (value[0] === 'postal_code') {
      return `type:${value[0]} AND ${value[0]}:(${value[1]
        .split(',')
        .map(code => `"${code}"`)
        .join(' OR ')})`;
    }

    return `${value[0]}:"${value[1]}"`;
  });

  return `(${queries.join(' AND ')})`;
}

export function buildSearchQueryFromGeography(searchConfig: GeographyEntities): string {
  const queries = Object.entries(searchConfig).map(value => {
    return `(type:${value[0]} AND ${value[0]}:${value[1]}*)`;
  });

  return `(${queries.join(' OR ')})`;
}

export function buildSearchQueryFromGeographyById(searchConfig: GeographyEntities): string {
  const queries = Object.entries(searchConfig).map(value => {
    const ids = value[1]
      .split(',')
      .map(i => `"${i}"`)
      .join(' OR ');
    return `(type:${value[0]} AND id:(${ids}))`;
  });

  return `(${queries.join(' OR ')})`;
}

export function buildGeographyEntitiesSearchObject(
  searchPhrase: string,
  radius = false,
  geographicType?: GeoLocationTypes
): GeographyEntities {
  const escapedPhrase = searchPhrase.replace(/[-&~"![.*+?^${}()|/[\]\\]/g, '\\$&');

  if (radius) {
    return {
      city: escapedPhrase,
      postal_code: escapedPhrase,
    };
  }

  if (geographicType) {
    return {
      [geographicType]: escapedPhrase,
    };
  }

  return {
    city: escapedPhrase,
    continent: escapedPhrase,
    country: escapedPhrase,
    dma: escapedPhrase,
    msa: escapedPhrase,
    postal_code: escapedPhrase,
    region: escapedPhrase,
    state: escapedPhrase,
  };
}

export function getGeoLocationDisplayNameWithType(geoLocation: GeoLocation): string {
  const { type } = geoLocation;

  switch (type) {
    case GeoLocationTypes.CONTINENT:
    case GeoLocationTypes.COUNTRY:
      return getType(geoLocation);
    case GeoLocationTypes.CITY:
      return `${capitalizeLocation(geoLocation.country)}${
        geoLocation.state ? ' > ' + capitalizeLocation(geoLocation.state) : ''
      } > ${getType(geoLocation)}`;
    case GeoLocationTypes.MSA:
    case GeoLocationTypes.DMA:
      return `${capitalizeLocation(geoLocation.country)} > ${geoLocation[type]} (${type})`;
    case GeoLocationTypes.REGION:
    case GeoLocationTypes.STATE:
    case GeoLocationTypes.POSTAL_CODE:
      return `${capitalizeLocation(geoLocation.country)} > ${getType(geoLocation)}`;
  }

  return `Unknown[${type}]`;
}

export function getGeoLocationDisplayName(geoLocation: GeoLocation, excludeSubset?: boolean | number): string {
  const { type } = geoLocation;

  if (typeof excludeSubset === 'number' || typeof excludeSubset === 'undefined') {
    excludeSubset = false;
  }

  switch (type) {
    case GeoLocationTypes.CONTINENT:
    case GeoLocationTypes.COUNTRY:
      return excludeSubset ? getType(geoLocation) : `${capitalizeLocation(geoLocation[type])}`;
    case GeoLocationTypes.CITY:
      return `${capitalizeLocation(geoLocation.country)}${
        geoLocation.state ? ' > ' + capitalizeLocation(geoLocation.state) : ''
      } > ${excludeSubset ? getType(geoLocation) : capitalizeLocation(geoLocation[type])}`;
    case GeoLocationTypes.MSA:
    case GeoLocationTypes.DMA:
      return `${capitalizeLocation(geoLocation.country)} > ${geoLocation[type]} (${type})`;
    case GeoLocationTypes.REGION:
    case GeoLocationTypes.STATE:
    case GeoLocationTypes.POSTAL_CODE:
      return `${capitalizeLocation(geoLocation.country)} > ${
        excludeSubset ? getType(geoLocation) : capitalizeLocation(geoLocation[type])
      }`;
  }

  return `Unknown[${type}]`;
}

export function getCircleDisplayName(circle: RadiusCircle): string {
  return `${circle.lat}°,${circle.lon}°;Radius:${circle.rad} mi`;
}

export function getGeoLocationDisplayNameByTypeId(locations: GeoLocationsList, locationTypeId: string): string {
  const locationInfoArray = locationTypeId.split('.');
  const locationId = locationInfoArray.pop();
  const locationType = locationInfoArray.pop();
  const location = locations.find(loc => loc.id === locationId && loc.type === locationType) as GeoLocation;

  return location ? getGeoLocationDisplayNameWithType(location) : locationTypeId;
}

export function getType(geoLocation: GeoLocation) {
  const { type } = geoLocation;
  return `${capitalizeLocation(geoLocation[type])} (${type})`;
}

export function capitalizeLocation(location) {
  return location.split(' ').map(capitalize).join(' ');
}

export function transformGeolocationsToRulesDict(geographyList: GeoLocationsList): GeographyEntities {
  const resultDict = geographyList.reduce((acc, geoLocation) => {
    if (acc[geoLocation.type]) {
      acc[geoLocation.type].push(geoLocation.id);
    } else {
      acc[geoLocation.type] = [geoLocation.id];
    }
    return acc;
  }, {});

  for (const key of Object.keys(resultDict)) {
    resultDict[key] = resultDict[key].join();
  }

  return resultDict;
}

const addLocationIds = (locations: GeographyEntities, locationType: string, ids: string) => {
  if (!locations[locationType]) {
    // use Set to prevent locations ids duplications
    locations[locationType] = new Set();
  }
  if (ids) {
    ids.split(',').forEach(locationId => {
      locations[locationType].add(locationId);
    });
  }
};

export function getGeolocationsIdsFromAuditTrail(auditTrail): GeographyEntities {
  const geoLocations = auditTrail.objects.reduce((acc, obj) => {
    // created geographic entities in audit trail have different structure then updated
    if (obj.action === 'create' && obj.changes.new_value?.whitelist_rules?.geographic?.includes) {
      const locations = obj.changes.new_value.whitelist_rules.geographic.includes;
      for (const locationType of Object.keys(locations)) {
        addLocationIds(acc, locationType, locations[locationType]);
      }

      return acc;
    }

    const changeObj = obj.changes;
    for (const key of Object.keys(changeObj)) {
      if (key.startsWith('whitelist_rules')) {
        // handle case when geographic was added after creation
        if (typeof changeObj[key].new_value === 'object') {
          const locations = changeObj[key]?.new_value?.includes || changeObj[key]?.new_value?.geographic?.includes;

          if (!locations) {
            continue;
          }

          Object.keys(locations).forEach(locationType => {
            addLocationIds(acc, locationType, locations[locationType]);
          });

          continue;
        }

        const locationType = key.split('.').pop() as string;

        if (locationType === 'excludes') {
          // excludes are valid geo input but is not used in this whitelist_rules
          continue;
        }

        if (changeObj[key].new_value) {
          addLocationIds(acc, locationType, changeObj[key].new_value);
        }

        if (changeObj[key].old_value) {
          addLocationIds(acc, locationType, changeObj[key].old_value);
        }
      }
    }

    return acc;
  }, {});

  Object.keys(geoLocations).forEach(key => {
    geoLocations[key] = Array.from(geoLocations[key]).join(',');
  });

  return geoLocations;
}

export function transformGeolocationsToAuditTrailFormat(auditTrail) {
  auditTrail.objects.forEach(obj => {
    const changesObject = obj.changes;

    if (obj.action === 'create' && obj.changes.new_value?.whitelist_rules?.geographic?.includes) {
      // created geographic entities in audit trail have different structure then updated
      const locations = obj.changes.new_value?.whitelist_rules?.geographic?.includes;

      delete changesObject.new_value.whitelist_rules.geographic; // clean value to prevent duplications
      for (const key of Object.keys(locations)) {
        if (locations[key]) {
          locations[key].split(',').forEach(id => {
            // transform and extract geography object to type accepted by history as new added entity
            changesObject.new_value.whitelist_rules[`geographic.includes.${key}.${id}`] = true;
          });
        }
      }

      return;
    }

    for (const key of Object.keys(changesObject)) {
      if (key.startsWith('whitelist_rules')) {
        let locations = changesObject[key];
        delete changesObject[key]; // clean value to prevent duplications

        if (key.includes('.excludes')) {
          // excludes are valid geo input but is not used in this whitelist_rules
          continue;
        }
        if (locations.new_value) {
          // handle case when geographic was added after creation
          if (typeof locations.new_value === 'object') {
            locations = locations.new_value?.includes || locations.new_value?.geographic?.includes;
            if (!locations) {
              continue;
            }
            Object.keys(locations).forEach(locationType => {
              locations[locationType].split(',').forEach(id => {
                changesObject[`whitelist_rules.geographic.includes.${locationType}.${id}`] = { new_value: true };
              });
            });
          } else {
            locations.new_value.split(',').forEach(id => {
              changesObject[`${key}.${id}`] = { new_value: true };
            });
          }
        }

        if (locations?.old_value) {
          locations.old_value.split(',').forEach(id => {
            if (!changesObject[`${key}.${id}`]) {
              changesObject[`${key}.${id}`] = { old_value: true };
            } else {
              // value in new and old values, remove it as there were no changes for value in audittrail
              delete changesObject[`${key}.${id}`];
            }
          });
        }
      }
    }
  });
}

export function parseGeoListToDisplayNames(geoList: GeoLocationsList): GeoLocationName[] {
  const displayNames: GeoLocationName[] = [];
  for (const geo of geoList) {
    const { id, name, type } = geo;
    displayNames.push({ displayName: getGeoLocationDisplayName(geo), id, name, type });
  }
  return displayNames;
}

export function getGeoLocationLimit(config: GeographyEntities): number {
  return Object.values(config).reduce((acc, value) => {
    if (!value) return acc;
    return acc + value.split(',').length;
  }, 0);
}

export const getSearchQuery = (searchConfig: GeographyEntities, searchById?: boolean, isBulk?: boolean) => {
  if (isBulk) {
    return buildSearchQueryFromGeographyBulk(searchConfig);
  }

  if (searchById) {
    return buildSearchQueryFromGeographyById(searchConfig);
  }

  return buildSearchQueryFromGeography(searchConfig);
};
