import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ACL_TYPE } from '@openx/types/aclType';
import { type AudienceData, AudienceOption } from '@openx/types/targeting/audience';
import type { TargetingProps } from '@openx/types/targeting/targeting';
import { ComparisonType, Intersect } from '@openx/types/targeting/targetingValuesTypes';
import { usePermissionContext } from '@openx/utils/permission/context/PermissionContext';
import { isAllowedByCompiledAcl } from '@openx/utils/permission/validators';

import { CustomOptionsProvider } from '../../utils/hooks/useCustomOptionsContext';
import { EmptyFilterMessage, TargetingDrawer, useDrawer } from '../shared';

import { AudienceReadOnly } from './AudienceReadOnly';
import { Items } from './Items';
import { TopBox } from './TopBox';
import { AudienceTypeOption, audienceDefaultOptions } from './constants';

export const Audience = memo(function Audience({
  targetingParams,
  onFieldUpdate = () => {},
  name,
  accountUid,
  readonly,
  field,
  isDisabled,
  customOptions,
}: TargetingProps<AudienceData>) {
  const { t } = useTranslation();
  const { useIsAllowed, isOa } = usePermissionContext();
  const { isAllowed } = useIsAllowed();

  const defaultType = useMemo(() => {
    if (customOptions?.defaultOption) {
      return customOptions.defaultOption;
    }

    if (isOa) {
      return AudienceOption.OPEN_AUDIENCES;
    }

    if (targetingParams) {
      return Object.keys(targetingParams)[0] as AudienceOption;
    }

    if (isAllowed(isAllowedByCompiledAcl(ACL_TYPE.DMP_SOURCES_READ))) {
      return AudienceOption.DMP_SEGMENTS;
    }

    return AudienceOption.OPEN_AUDIENCES;
  }, [isAllowed, customOptions?.defaultOption, isOa, targetingParams]);

  const defaultOperator = useMemo(
    () => (defaultType === AudienceOption.DMP_SEGMENTS ? ComparisonType.INTERSECTS : Intersect.INTERSECTS),
    [defaultType]
  );

  const intendedAudienceDefaultValue: AudienceData = useMemo(
    () => ({
      [AudienceOption.INTENDED_AUDIENCE]: { op: Intersect.INTERSECTS, val: AudienceTypeOption.Over_12 },
    }),
    []
  );

  const isIntendedAudienceEnabled = customOptions?.availableOptions.includes(AudienceOption.INTENDED_AUDIENCE);

  const initState: AudienceData | null = useMemo(() => {
    if (targetingParams) return { ...targetingParams };
    if (isIntendedAudienceEnabled) return intendedAudienceDefaultValue;

    return null;
  }, [targetingParams, isIntendedAudienceEnabled, intendedAudienceDefaultValue]);

  const [params, updateParams] = useState<AudienceData | null>(initState);
  const [type, setType] = useState<AudienceOption>(defaultType);
  const [localAccountUid, setLocalAccountUid] = useState<string | undefined>(accountUid);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => {
      const filteredParams = Object.keys(params || {}).reduce((acc, key) => {
        if (params?.[key]) {
          acc[key] = params[key];
        }
        return acc;
      }, {});

      onFieldUpdate(field, Object.keys(filteredParams).length ? filteredParams : null);
    },
    restoreParams: () => updateParams({ ...initState }),
  });

  const onTypeChange = (type: AudienceOption) => {
    setType(type);

    if (type !== AudienceOption.INTENDED_AUDIENCE) {
      updateParams(params => ({ [AudienceOption.INTENDED_AUDIENCE]: params?.[AudienceOption.INTENDED_AUDIENCE] }));
    }
  };

  const onRemoveAll = useCallback(() => {
    const initialValue: AudienceData | null = isIntendedAudienceEnabled ? intendedAudienceDefaultValue : null;

    onFieldUpdate(field, initialValue);
    updateParams(initialValue);
  }, [isIntendedAudienceEnabled, intendedAudienceDefaultValue, field, onFieldUpdate]);

  const onChange = useCallback(
    (val?: string | Set<string>, givenType?: AudienceOption) => {
      if (type !== AudienceOption.MATCHED_USERS && type !== AudienceOption.TRAFFIC_FILTER_EIDS) {
        updateParams(params => ({ ...params, [type]: { op: Intersect.INTERSECTS, val } }));
      } else {
        updateParams(params => ({ ...params, [givenType ?? type]: val }));
      }
    },
    [type]
  );

  const onChangeMulti = useCallback(
    (values: Set<string>) => {
      if (type !== AudienceOption.MATCHED_USERS && type !== AudienceOption.TRAFFIC_FILTER_EIDS) {
        updateParams({
          [type]: {
            ...(params?.[type] ? params?.[type] : { op: defaultOperator, val: null }),
            val: Array.from(values).join(','),
          },
        });
      }
    },
    [type, params, updateParams, defaultOperator]
  );

  //for ALL is ComparisonType.INVERSE_IN, for ANY is ComparisonType.INTERSECTS, ANY is deafult
  const onAllAnyOperatorChange = useCallback(() => {
    const currentOperator = params?.[type];
    if (typeof currentOperator !== 'object' || !('op' in currentOperator)) {
      return;
    }

    const newOperator =
      currentOperator.op === ComparisonType.INTERSECTS ? ComparisonType.INVERSE_IN : ComparisonType.INTERSECTS;

    updateParams({ [type]: { ...currentOperator, op: newOperator } });
  }, [type, params, updateParams]);

  useEffect(() => {
    if (isIntendedAudienceEnabled && accountUid !== localAccountUid) {
      onRemoveAll();
      setLocalAccountUid(accountUid);
    }
  }, [accountUid, isIntendedAudienceEnabled, localAccountUid, onRemoveAll]);

  const isEmpty = useMemo(() => Object.values(params ?? {}).every(value => !value), [params]);

  const isOnlyIntended = useMemo(
    () => Object.keys(params ?? {}).length === 1 && params?.[AudienceOption.INTENDED_AUDIENCE],
    [params]
  );

  const isDataUpdated = useMemo(() => {
    return !isEqual(targetingParams, params);
  }, [params, targetingParams]);

  return (
    <CustomOptionsProvider value={customOptions ?? audienceDefaultOptions}>
      <AudienceReadOnly
        name={name}
        targetingParams={initState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={isOnlyIntended ? undefined : onRemoveAll}
        accountUid={accountUid}
        readonly={readonly}
        isDisabled={isDisabled}
      />

      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <TopBox
            accountUid={accountUid as string}
            onChange={onChange}
            onChangeMulti={onChangeMulti}
            selectedType={type}
            onTypeChange={onTypeChange}
            params={params}
          />

          {isEmpty ? (
            <EmptyFilterMessage text={t('No filters applied.')} />
          ) : (
            <Items
              targetingParams={params}
              readonly={false}
              onChange={updateParams}
              accountUid={accountUid as string}
              onAllAnyOperatorChange={onAllAnyOperatorChange}
            />
          )}
        </TargetingDrawer>
      )}
    </CustomOptionsProvider>
  );
});
