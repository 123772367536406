import { useTranslation } from 'react-i18next';

import { ComparisonType, type CriteriaValue } from './targetingValuesTypes';

export const segmentIdsPlaceholder = `Segment IDs may be entered as either comma, semicolon, or new line separated values.

Examples:
12345678, 87654321
or
12345678;87654321
or
12345678
87654321`;

export const segmentIdsLimit = 15;

export const segmentIdMaxLength = 256;

export const contextualSegmentPrefix = 'ctx_';

export interface ContextualSegmentsData {
  op: CriteriaValue;
  val: ContextualSegmentsDataPayload[];
}

export interface ContextualSegmentsDataPayload {
  attr: string;
  includes: string;
  excludes: string;
}

export const useContextualSegmentsDropdownLabels = () => {
  const { t } = useTranslation();

  return {
    [ComparisonType.INTERSECTS]: t('Contains any of'),
    [ComparisonType.NOT_INTERSECTS]: t('Contains none of'),
  };
};

export const dropdownOptions = [ComparisonType.INTERSECTS, ComparisonType.NOT_INTERSECTS];
