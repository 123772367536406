import { useTranslation } from 'react-i18next';

import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

export const useCustomVariablesPlaceholder = () => {
  const { t } = useTranslation();

  const placeholders = {
    date: t('Enter a date (example: 2021-06-30)'),
    list: t('Enter a list'),
    numeric: t('Enter a numeric value'),
    string: t('Enter a string value'),
    value: t('Enter a value'),
  };

  return {
    [ComparisonType.EQUALS]: placeholders.value,
    [ComparisonType.DOES_NOT_EQUAL]: placeholders.string,
    [ComparisonType.ENDS_WITH]: placeholders.string,
    [ComparisonType.CONTAINS]: placeholders.string,
    [ComparisonType.BEGINS_WITH]: placeholders.string,
    [ComparisonType.DOES_NOT_END_WITH]: placeholders.string,
    [ComparisonType.DOES_NOT_CONTAIN]: placeholders.string,
    [ComparisonType.DOES_NOT_BEGIN_WITH]: placeholders.string,
    [ComparisonType.LESS_THAN]: placeholders.numeric,
    [ComparisonType.LESS_THAN_OR_EQUAL_TO]: placeholders.numeric,
    [ComparisonType.GREATER_THAN]: placeholders.numeric,
    [ComparisonType.GREATER_THAN_OR_EQUAL_TO]: placeholders.numeric,
    [ComparisonType.BEFORE]: placeholders.date,
    [ComparisonType.AFTER]: placeholders.date,
    [ComparisonType.INTERSECTS]: placeholders.list,
    [ComparisonType.INVERSE_IN]: placeholders.list,
    [ComparisonType.EQ]: placeholders.list,
    [ComparisonType.NOT_INTERSECTS]: placeholders.list,
  };
};

export const useCustomVariablesDropdownLabels = () => {
  const { t } = useTranslation();

  return {
    [ComparisonType.AFTER]: t('After'),
    [ComparisonType.BEFORE]: t('Before'),
    [ComparisonType.BEGINS_WITH]: t('Begins with'),
    [ComparisonType.CONTAINS]: t('Contains'),
    [ComparisonType.INVERSE_IN]: t('Contains all of'),
    [ComparisonType.INTERSECTS]: t('Contains any of'),
    [ComparisonType.NOT_INTERSECTS]: t('Contains none of'),
    [ComparisonType.EQ]: t('Contains only'),
    [ComparisonType.DOES_NOT_BEGIN_WITH]: t('Does not begin with'),
    [ComparisonType.DOES_NOT_CONTAIN]: t('Does not contain'),
    [ComparisonType.DOES_NOT_END_WITH]: t('Does not end with'),
    [ComparisonType.DOES_NOT_EQUAL]: t('Does not equal'),
    [ComparisonType.ENDS_WITH]: t('Ends with'),
    [ComparisonType.EQUALS]: t('Equals'),
    [ComparisonType.GREATER_THAN]: t('Greater than'),
    [ComparisonType.GREATER_THAN_OR_EQUAL_TO]: t('Greater than or equal to'),
    [ComparisonType.LESS_THAN]: t('Less than'),
    [ComparisonType.LESS_THAN_OR_EQUAL_TO]: t('Less than or equal to'),
  };
};

export const dropdownOptions = [
  ComparisonType.AFTER,
  ComparisonType.BEFORE,
  ComparisonType.BEGINS_WITH,
  ComparisonType.CONTAINS,
  ComparisonType.INVERSE_IN,
  ComparisonType.INTERSECTS,
  ComparisonType.NOT_INTERSECTS,
  ComparisonType.EQ,
  ComparisonType.DOES_NOT_BEGIN_WITH,
  ComparisonType.DOES_NOT_CONTAIN,
  ComparisonType.DOES_NOT_END_WITH,
  ComparisonType.DOES_NOT_EQUAL,
  ComparisonType.ENDS_WITH,
  ComparisonType.EQUALS,
  ComparisonType.GREATER_THAN,
  ComparisonType.GREATER_THAN_OR_EQUAL_TO,
  ComparisonType.LESS_THAN,
  ComparisonType.LESS_THAN_OR_EQUAL_TO,
];
