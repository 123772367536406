import { Grid } from '@mui/material';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { OptionsMap } from '@openx/types/options';
import type { GeographicTargetingState } from '@openx/types/targeting/geographic';

import { useTargetingContext } from '../../../utils';
import { OptionsDropdown } from '../../shared';

type LocationSourceProps = {
  children: ReactNode;
  locationSourceState: GeographicTargetingState['location_source'];
  onSelect: (newData: GeographicTargetingState['location_source']) => void;
};

export const LocationSource = ({ children, locationSourceState, onSelect }: LocationSourceProps) => {
  const { useLocationSourceOptionsFetch } = useTargetingContext();

  if (useLocationSourceOptionsFetch) {
    return (
      <RenderLocationSource
        children={children}
        locationSourceState={locationSourceState}
        onSelect={onSelect}
        useLocationSourceOptionsFetch={useLocationSourceOptionsFetch}
      />
    );
  }

  return null;
};

const RenderLocationSource = ({
  children,
  locationSourceState,
  onSelect,
  useLocationSourceOptionsFetch,
}: LocationSourceProps & {
  useLocationSourceOptionsFetch: () => { isLoading: boolean; locationSourceOptions: OptionsMap };
}) => {
  const { t } = useTranslation();
  const { isLoading, locationSourceOptions } = useLocationSourceOptionsFetch();

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={3}>
        {children}
      </Grid>

      <Grid item xs={9}>
        <OptionsDropdown
          loading={isLoading}
          options={locationSourceOptions}
          onChange={value => onSelect({ ...locationSourceState, val: value })}
          selectedOptions={locationSourceState.val as Set<string>}
          placeholder={t('[ Select a Location Source ]')}
          dataTest="location-source-input"
        />
      </Grid>
    </Grid>
  );
};
