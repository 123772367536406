import type { ComparisonMap, ComparisonType, CriteriaValue, Intersect } from './targetingValuesTypes';

export enum InventoryContentOption {
  APP_BUNDLE_ID = 'app_bundle_id',
  INVENTORY = 'inventory',
  PAGE_URL = 'page_url',
  ADUNIT = 'adunit',
  ADUNIT_SIZE = 'adunit_size',
  PUBLISHER_ID = 'account',
  INSTANCE_HASH = 'instance',
  SITE = 'site',
  CONTENT_INTER_DIMENSION_OPERATOR = 'content_inter_dimension_operator',
  KEYWORDS = 'keywords',
  LINE_ITEM_APP_BUNDLE_ID = 'line_item_app_bundle_id',
}

export type InventoryContentIdsTypeOption =
  | InventoryContentOption.PUBLISHER_ID
  | InventoryContentOption.INSTANCE_HASH
  | InventoryContentOption.ADUNIT
  | InventoryContentOption.SITE
  | InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID;

export enum TargetableInventoryOption {
  INCLUDES = 'includes',
  EXCLUDES = 'excludes',
  ID_BY_INSTANCES = '_idsByInstances',
}

export interface InventoryAndContentApi {
  [InventoryContentOption.APP_BUNDLE_ID]?: {
    op: CriteriaValue;
    val: {
      val: string;
      op: ComparisonType;
    }[];
  } | null;
  [InventoryContentOption.PAGE_URL]?: {
    op: CriteriaValue;
    val: {
      val: string;
      op: ComparisonType;
    }[];
  } | null;
  [InventoryContentOption.ADUNIT]?: {
    op: Intersect;
    val: string;
  } | null;
  [InventoryContentOption.ADUNIT_SIZE]?: {
    op: Intersect;
    val: string;
  } | null;
  [TargetableInventoryOption.INCLUDES]?:
    | {
        [K in TargetableInventoryType]?: string | null;
      }
    | null;
  [TargetableInventoryOption.EXCLUDES]?:
    | {
        [K in TargetableInventoryType]?: string | null;
      }
    | null;
  [InventoryContentOption.PUBLISHER_ID]?: {
    op: Intersect;
    val: string;
  } | null;
  [InventoryContentOption.INSTANCE_HASH]?: {
    op: Intersect;
    val: string;
  } | null;
  [InventoryContentOption.SITE]?: {
    op: Intersect;
    val: string;
  } | null;
  [TargetableInventoryOption.ID_BY_INSTANCES]?: InstancesByIdObject;
  [InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR]?: CriteriaValue;
  [InventoryContentOption.KEYWORDS]?: {
    op: Intersect;
    val: string[];
  } | null;
  [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]?: {
    op: Intersect;
    val: string;
  } | null;
}

type InstanceId = string;

interface IncludesExcludes {
  [TargetableInventoryOption.INCLUDES]?:
    | {
        [K in TargetableInventoryType]?: string | null;
      }
    | null;
  [TargetableInventoryOption.EXCLUDES]?:
    | {
        [K in TargetableInventoryType]?: string | null;
      }
    | null;
}

export type InstancesByIdObject = Record<InstanceId, IncludesExcludes> | null;

export interface InventoryAndContentState {
  [InventoryContentOption.ADUNIT]: IntersectOptionPayload;
  [InventoryContentOption.APP_BUNDLE_ID]: InventoryAndContentOptionPayload;
  [InventoryContentOption.PAGE_URL]: InventoryAndContentOptionPayload;
  [InventoryContentOption.ADUNIT_SIZE]: IntersectOptionPayload;
  [InventoryContentOption.INVENTORY]: InventoryOptionPayload;
  [InventoryContentOption.PUBLISHER_ID]: IntersectOptionPayload;
  [InventoryContentOption.INSTANCE_HASH]: IntersectOptionPayload;
  [InventoryContentOption.SITE]: IntersectOptionPayload;
  [InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR]: CriteriaValue | null;
  [InventoryContentOption.KEYWORDS]: IntersectOptionPayload;
  [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: IntersectOptionPayload;
}

export interface InventoryAndContentOptionPayload {
  op: CriteriaValue;
  val: ComparisonMap;
}

export interface IntersectOptionPayload {
  op: Intersect;
  val: string[];
}

export interface InventoryOptionPayload {
  [TargetableInventoryOption.INCLUDES]: TargetableInventoryList;
  [TargetableInventoryOption.EXCLUDES]: TargetableInventoryList;
}

export type InventoryOptionPayloadApiFormat = {
  [K in TargetableInventoryType]?: string | null;
};

export enum TargetableInventoryType {
  INSTANCE = 'instance',
  ADUNITGROUP = 'adunitgroup',
  ACCOUNT = 'account',
  SITE = 'site',
  ADUNIT = 'adunit',
  SITESECTION = 'sitesection',
  NETWORK = 'network',
}

export const TargetableInventoryParentSearch = {
  [TargetableInventoryType.ACCOUNT]: 'account_uid',
  [TargetableInventoryType.SITE]: 'site_uid',
};

export interface TargetableInventoryEntities {
  adunitgroup?: string | null;
  account?: string | null;
  site?: string | null;
  adunit?: string | null;
  sitesection?: string | null;
}

export interface TargetableInventory {
  uid: string;
  id: string;
  name: string;
  type: string;
  account_uid?: string | null;
  parent_name?: string | null;
  type_full?: string | null;
  instance_uid?: string;
}

export type TargetableInventoryList = TargetableInventory[];

export interface TargetableInventoryExcludes {
  grouped: Record<string, TargetableInventoryList>;
  other: TargetableInventoryList;
}
export type InstanceProps = {
  id: string;
  market_operator_id: string;
  name: string;
};
export interface inventoryTargetingContent<T> {
  includes: T;
  excludes: T;
}
