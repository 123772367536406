import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { groupBy } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AdvancedTargetingData } from '@openx/types/targeting/advancedTargeting';
import type { CustomVariablesDataPayload } from '@openx/types/targeting/customVariables';
import { ListIconType } from '@openx/types/targeting/targeting';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { mapTypeToIconType, useComparisonLabels } from '../../constants';
import { DimensionChip, EmptyFilterMessage, FiltersTitle, SelectedItems, Switch } from '../../shared';
import type { TargetingItemsProps } from '../../types';
import { defaultState } from '../helper';
import { useGetContextualSegmentsComponents } from '../hooks/useGetContextualSegmentsComponents';

const StyledSpan = styled('span')`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CustomVariablesItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<AdvancedTargetingData>): JSX.Element => {
  const { t } = useTranslation();
  const { op, custom: val } = targetingParams;
  const comparisonLabels = useComparisonLabels();
  const { segmentsV2 } = useGetContextualSegmentsComponents();
  const groupValuesByComparisonType = useMemo(() => groupBy(val, key => key.op), [val]);
  const switchState = op === Criteria.ANY;
  const handleChange = useCallback(
    (values: CustomVariablesDataPayload[]) => {
      onChange((prevValues: any) => {
        if (segmentsV2) {
          return {
            ...prevValues,
            custom: values,
          };
        }

        return {
          ...defaultState,
          custom: values,
          op,
        };
      });
    },
    [onChange, op, segmentsV2]
  );

  const handleRemove = useCallback(
    (comparisonType: string, newState: CustomVariablesDataPayload[]) => {
      const groups = { ...groupValuesByComparisonType };
      groups[comparisonType] = newState;

      handleChange(Object.values(groups).flat());
    },
    [groupValuesByComparisonType, handleChange]
  );

  const CustomVariablesList = useMemo(() => {
    const optionsEntries = Object.entries(groupValuesByComparisonType);

    return optionsEntries.map(([comparisonType, items], idx) => {
      return (
        <Fragment key={comparisonType}>
          {items.length ? (
            <Grid data-test={`section-items-${comparisonLabels[comparisonType].toLowerCase().replaceAll(' ', '-')}`}>
              <SelectedItems
                filtersTitle={comparisonLabels[comparisonType]}
                isAddIcon={mapTypeToIconType[comparisonType] === ListIconType.INCLUDE}
                selectedItems={items}
                readonly={readonly}
                handleChange={newState => handleRemove(comparisonType, newState)}
                getItemLabel={item => (
                  <>
                    <span>{item.attr + ' '}</span>
                    <StyledSpan>{comparisonLabels[item.op].toLowerCase() + ' '}</StyledSpan>
                    <span>{item.val}</span>
                  </>
                )}
                isRemovingAll={true}
                dataTest={'custom-variables'}
              />
              {idx < optionsEntries.length - 1 && <DimensionChip isInsideOption={true} targetingDimension={op} />}
            </Grid>
          ) : null}
        </Fragment>
      );
    });
  }, [handleRemove, readonly, groupValuesByComparisonType, op, comparisonLabels]);

  const emptyComponentRender = useMemo(
    () => (!segmentsV2 ? <EmptyFilterMessage text={t('No filters applied.')} /> : <></>),
    [segmentsV2, t]
  );

  return val && val.length !== 0 ? (
    <Box data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('Custom Variables [ {count} ]', { count: val.length })}
        readonly={readonly}
      />
      {!readonly && !segmentsV2 && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange({
              ...defaultState,
              custom: val,
              op: op === Criteria.ALL ? Criteria.ANY : Criteria.ALL,
            })
          }
          switchOffLabel={t('all')}
          switchOnLabel={t('any')}
          groupTitle={t('of the following:')}
        />
      )}
      {CustomVariablesList}
    </Box>
  ) : (
    emptyComponentRender
  );
};
