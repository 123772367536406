import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import type { OptionsMap } from '@openx/types/options';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';
import {
  type TechnologyAndDevicesFormState,
  TechnologyAndDevicesOption,
} from '@openx/types/targeting/technologyAndDevices';

import { useTargetingContext } from '../../utils';
import { FiltersTitle, SelectedItems, Switch } from '../shared';
import type { TargetingItemsProps } from '../types';

import { MobileCarrierItems, SelectedItemsSingleOption, UserAgentItems } from './TechnologyAndDevicesItems';
import { optionsToFetch } from './constants';
import { getFilterDataTest, getFilterTitle, transformOptionsToMatchLegacyApi } from './utils';

export const Items = ({
  targetingParams,
  type,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<TechnologyAndDevicesFormState> & { type: TechnologyAndDevicesOption }) => {
  const { t } = useTranslation();
  const { useTechnologyOptionsFetch } = useTargetingContext();

  const { op, val } = targetingParams[type];
  const isNotUserAgentOption = val instanceof Set && type !== TechnologyAndDevicesOption.USER_AGENT;
  const isSingleSelectOption = !optionsToFetch[type];
  const shouldFetchOptions = !isSingleSelectOption && isNotUserAgentOption && !!val.size;
  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('Excluded') : t('Included');
  const filtersTitle = isNotUserAgentOption && !!val.size ? typeLabel : '';
  const switchState = op === Intersect.NOT_INTERSECTS;
  const selectedItemsCount = isNotUserAgentOption ? val.size : undefined;

  const { options, isLoading } = useTechnologyOptionsFetch(type, shouldFetchOptions);
  const title = useMemo(() => getFilterTitle(t, selectedItemsCount), [selectedItemsCount, t]);
  const dataTest = useMemo(() => getFilterDataTest(t, type), [t, type]);
  let transformedOptions: OptionsMap = {};

  if (type === TechnologyAndDevicesOption.SCREEN_RESOLUTION) {
    transformedOptions = transformOptionsToMatchLegacyApi(options);
  }

  const optionsToDisplay = Object.keys(transformedOptions).length ? transformedOptions : options;

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);

      onChange(state => ({
        ...state,
        [type]: { op, val: selected },
      }));
    },
    [type, onChange, op]
  );

  const onSwitchChange = useCallback(() => {
    onChange(state => ({
      ...state,
      [type]: { op: op === Intersect.INTERSECTS ? Intersect.NOT_INTERSECTS : Intersect.INTERSECTS, val },
    }));
  }, [onChange, op, type, val]);

  const selectedItems = useMemo(() => {
    if (isNotUserAgentOption) {
      return [...val];
    }

    return [];
  }, [isNotUserAgentOption, val]);

  if (isLoading) {
    return <Loader />;
  }

  if (type === TechnologyAndDevicesOption.MOBILE_CARRIER) {
    return <MobileCarrierItems targetingParams={targetingParams} readonly={readonly} onChange={onChange} />;
  }

  if (type === TechnologyAndDevicesOption.USER_AGENT) {
    return <UserAgentItems targetingParams={targetingParams} readonly={readonly} onChange={onChange} />;
  }

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={() => handleChange([])} title={title[type]} readonly={readonly} />
      {!readonly && !isSingleSelectOption && (
        <Switch
          switchState={switchState}
          onSwitchChange={onSwitchChange}
          switchOffLabel={t('include')}
          switchOnLabel={t('exclude')}
          groupTitle={t('the following:')}
        />
      )}

      {isSingleSelectOption && (
        <SelectedItemsSingleOption
          filtersTitle={filtersTitle}
          selectedItems={targetingParams}
          readonly={readonly}
          handleChange={handleChange}
          dataTest={dataTest}
          selectedType={type}
        />
      )}

      {!isSingleSelectOption && (
        <SelectedItems
          filtersTitle={filtersTitle}
          isAddIcon={!switchState}
          selectedItems={selectedItems}
          readonly={readonly}
          handleChange={handleChange}
          options={optionsToDisplay}
          dataTest={dataTest}
        />
      )}
    </div>
  );
};
