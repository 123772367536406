import type { ChipFilterValue, FilterConfig, NumberFilterConfig } from '@openx/types/chipFilters';

export enum NumberFilterFields {
  OPTION = 'OPTION',
  VALUE = 'VALUE',
  VALUE_TO = 'VALUE_TO',
}

export interface NumberFilterData {
  [NumberFilterFields.OPTION]: null | RangeOptions;
  [NumberFilterFields.VALUE]: string;
  [NumberFilterFields.VALUE_TO]: string;
}

export enum RangeOptions {
  GT = 'GT',
  LT = 'LT',
  RANGE = 'RANGE',
  EQUAL = 'EQUAL',
}

export interface NumberFilterProps extends NumberFilterConfig, Pick<FilterConfig, 'onInputChange' | 'name'> {
  setCurrentOption: (val: ChipFilterValue) => void;
  setError: (val: boolean) => void;
}
