import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useDeviceTypesOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isDeviceTypesLoading,
    data: deviceTypes,
    refetch: refetchDeviceTypes,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Device types'),
    optionType: OptionsType.DEVICE_TYPE,
    queryOptions,
  });

  return {
    deviceTypes,
    isDeviceTypesLoading,
    refetchDeviceTypes,
  };
}
