import { OutsideLink } from '@openx/components/core/lib/Link/Link';

import {
  StyledBoldText,
  StyledInfoIcon,
  StyledOutsideLinkContainer,
  StyledPanelContainer,
  StyledTypography,
} from './styled';

export const TopInfoPanel = () => {
  // to be confirmed if subject is correct
  const mailto = 'mailto:deals@openx.com?subject=Custom Cookieless Deal Request';

  return (
    <StyledPanelContainer>
      <StyledTypography data-test="deal-list-header">
        <StyledInfoIcon />
        <StyledBoldText>Don’t see a deal that works for your objectives?</StyledBoldText>
        <span> Contact us now to build a custom cookieless deal today.</span>
      </StyledTypography>

      <StyledOutsideLinkContainer>
        <OutsideLink data-test="deal-list-contact-mailto-link" openInNewTab to={mailto}>
          REQUEST DEAL
        </OutsideLink>
      </StyledOutsideLinkContainer>
    </StyledPanelContainer>
  );
};
