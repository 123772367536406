import { DealLibraryList, Details } from 'modules';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { trackPageView } from '@openx/utils/lib/googleAnalytics';

import { useDealLibraryListFetch } from 'api/hooks';
import { ErrorPage } from 'components/ErrorPage';

export const AppRoutes = (): JSX.Element => {
  const location = useLocation();
  const { dealTemplates, popularTags, isLoading, isError } = useDealLibraryListFetch();
  const [backButtonQueryParams, setBackButtonQueryParams] = useState(location.search);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  if (isError) {
    return <ErrorPage errorType={500} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <DealLibraryList
            dealTemplates={dealTemplates}
            popularTags={popularTags}
            isLoading={isLoading}
            setQueryParamsForBackButton={setBackButtonQueryParams}
          />
        }
      />
      <Route
        path="/details/:id"
        element={<Details dealTemplates={dealTemplates} isLoading={isLoading} searchParams={backButtonQueryParams} />}
      />
      <Route path="*" element={<ErrorPage errorType={404} />} />
    </Routes>
  );
};
