import type { Option, OptionsMap } from '@openx/types/options';
import type { AllowBlock, AllowBlockFormState } from '@openx/types/targeting/contentObject';
import { AllowBlockType } from '@openx/types/targeting/targeting';
import { prepareMapBy } from '@openx/utils/lib/prepareMapBy';

export type HierarchyOption = {
  id: number;
  name: string;
  parent_id: number | null;
  nodes: HierarchyOption[];
  level?: number;
  sort_id?: number;
};

type PreparedOptionsForHierarchy = {
  leveledOptionsMap: OptionsMap;
  hierarchyOptions: HierarchyOption[];
};

export const getParentName = (leveledOptionsMap: OptionsMap, childName: string): string | null => {
  const mappedLeveledOptionsMap = Object.keys(leveledOptionsMap).map(key => leveledOptionsMap[key]);

  const child = mappedLeveledOptionsMap.find(option => option.name === childName);

  if (child && child.parent_id) {
    const parent = mappedLeveledOptionsMap.find(option => option.id === child.parent_id);

    return parent ? parent.name : null;
  }

  return null;
};

export const prepareOptionsForHierarchy = (options: OptionsMap): PreparedOptionsForHierarchy => {
  const optionsList = prepareOptionsList(options);
  const itemMap = createItemMap(optionsList);
  const hierarchyOptions = buildHierarchy(itemMap, optionsList);
  assignLevels(hierarchyOptions);

  const traversedOptions = traverseHierarchy(hierarchyOptions);
  return { hierarchyOptions, leveledOptionsMap: prepareMapBy(traversedOptions, 'id') };
};

const prepareOptionsList = (options: OptionsMap): Option[] => {
  return Object.values(options).map(item => ({
    id: item.id.toString(),
    name: item.name,
    parent_id: item.parent_id !== null ? item.parent_id?.toString() : null,
    sort_id: item.sort_id,
  }));
};

const createItemMap = (optionsList: Option[]): Record<string, HierarchyOption> => {
  const itemMap: Record<string, HierarchyOption> = {};
  optionsList.forEach(item => {
    const id = item.id;
    const parent_id = item.parent_id;

    const itemNode: HierarchyOption = {
      id: Number(id),
      name: item.name,
      nodes: [],
      parent_id: parent_id !== null ? Number(parent_id) : null,
      sort_id: item.sort_id,
    };

    itemMap[id] = itemNode;
  });
  return itemMap;
};

const buildHierarchy = (itemMap: Record<string, HierarchyOption>, optionsList: Option[]): HierarchyOption[] => {
  const hierarchyOptions: HierarchyOption[] = [];
  optionsList.forEach(item => {
    const id = item.id;
    const parent_id = item.parent_id;
    const itemNode = itemMap[id];

    if (!parent_id && parent_id !== '0') {
      hierarchyOptions.push(itemNode);
    } else {
      const parentNode = itemMap[parent_id];
      if (parentNode) {
        parentNode.nodes.push(itemNode);
      } else {
        hierarchyOptions.push(itemNode);
      }
    }
  });

  return hierarchyOptions;
};

const assignLevels = (nodes: HierarchyOption[], level = 1): void => {
  nodes.forEach(node => {
    node.level = level;
    if (node.nodes.length > 0) {
      assignLevels(node.nodes, level + 1);
    }
  });
};

export const traverseHierarchy = (hierarchyOptions: HierarchyOption[]): Option[] => {
  return hierarchyOptions.reduce((acc, option) => {
    traverseNodes(option, acc);
    return acc;
  }, [] as Option[]);
};

export const traverseNodes = (node: HierarchyOption, acc: Option[]): void => {
  acc.push({
    id: node.id.toString(),
    level: node.level,
    name: node.name,
    parent_id: node.parent_id !== null ? node.parent_id.toString() : null,
    sort_id: node.sort_id,
  });

  node.nodes.forEach(childNode => traverseNodes(childNode, acc));
};

export const groupOptionsByParent = (inputObject: OptionsMap): HierarchyOption[] => {
  const map = createMapWithChildren(inputObject);
  attachChildrenToParents(map);

  const topLevelItems = getTopLevelItems(map);
  const hasUniqueSortId =
    topLevelItems.some(item => item.sort_id !== undefined) && topLevelItems.map(item => item.sort_id).length > 1;

  topLevelItems.sort((a, b) => {
    if (hasUniqueSortId) {
      return (a.sort_id ?? 0) - (b.sort_id ?? 0);
    }
    return a.name.localeCompare(b.name);
  });

  const result: HierarchyOption[] = [];
  topLevelItems.forEach(item => {
    flattenStructure(item, result);
  });

  return result;
};

export const createMapWithChildren = (inputObject: OptionsMap): Map<string, any> => {
  const map = new Map();
  Object.values(inputObject).forEach(item => {
    map.set(item.id, { ...item, children: [] });
  });
  return map;
};

export const attachChildrenToParents = (map: Map<string, any>): void => {
  map.forEach(item => {
    if (item.parent_id !== null) {
      const parent = map.get(item.parent_id);
      if (parent) {
        parent.children.push(item);
      }
    }
  });
};

export const getTopLevelItems = (map: Map<string, any>): any[] => {
  return Array.from(map.values()).filter(item => item.parent_id === null);
};

export const flattenStructure = (item: any, result: HierarchyOption[]): void => {
  const { children, ...rest } = item;
  result.push(rest);

  const hasUniqueSortId =
    children.some(item => item.sort_id !== undefined) && new Set(children.map(item => item.sort_id)).size > 1;

  children.sort((a, b) => {
    if (hasUniqueSortId) {
      return (a.sort_id ?? 0) - (b.sort_id ?? 0);
    }
    return a.name.localeCompare(b.name);
  });

  children.forEach(child => {
    flattenStructure(child, result);
  });
};

export const getChildren = (options: OptionsMap, parentId: string): string[] => {
  const optionsList = Object.values(options).reduce<Option[]>(
    (acc, item) => [
      ...acc,
      {
        id: item.id,
        name: item.name,
        parent_id: item.parent_id,
      },
    ],
    []
  );

  const result: string[] = [];
  findChildren(parentId, optionsList, result);
  return result;
};

export const findChildren = (id: string, optionsList: Option[], result: string[]): void => {
  optionsList.forEach(option => {
    if (option.id === id) {
      result.push(option.name);
    }
    if (option.parent_id === id) {
      findChildren(option.id, optionsList, result);
    }
  });
};

export const findHighestLevel = (options: OptionsMap, selectedItems: string[]): number => {
  const optionsList = Object.values(options).reduce<Option[]>(
    (acc, item) => [
      ...acc,
      {
        id: item.id,
        level: item.level,
        name: item.name,
        parent_id: item.parent_id,
      },
    ],
    []
  );

  const levels = selectedItems.reduce<number[]>((acc, item) => {
    const option = optionsList.find(option => option.name === item);
    if (option && option.level) {
      acc.push(option.level);
    }
    return acc;
  }, []);

  return Math.min(...levels);
};

export const prepareItemsForApi = (items: string[], optionsMap: any): string[] => {
  const selectedOptions: { [name: string]: any } = {};
  const result: string[] = [];
  const highestLevelItems: { [name: string]: boolean } = {};

  for (const id in optionsMap) {
    const option = optionsMap[id];

    if (items.includes(option.name)) {
      selectedOptions[option.name] = option;
    }
  }

  for (const name in selectedOptions) {
    const option = selectedOptions[name];
    const parentOption = option.parent_id ? optionsMap[option.parent_id] : null;

    if (!parentOption || !selectedOptions[parentOption.name]) {
      highestLevelItems[name] = true;
    }
  }

  for (const name in highestLevelItems) {
    result.push(name);
  }

  return result;
};

export const prepareOptionsKeys = (options: OptionsMap): string[] => {
  const groupedOptions = groupOptionsByParent(options);

  const keys = groupedOptions.map(option => String(option.id));
  keys.unshift('clear_all');

  return keys;
};

type PrepareItemsForActionArgs = {
  optionsMap: OptionsMap;
  optionId: string;
  selectedItems: AllowBlock;
  action: 'allow' | 'block';
  canUnselect: boolean;
};

export const prepareItemsForAction = ({
  optionsMap,
  optionId,
  selectedItems,
  action,
  canUnselect,
}: PrepareItemsForActionArgs) => {
  const children = getChildren(optionsMap, optionId);
  const oppositeAction = action === 'allow' ? 'block' : 'allow';

  if (canUnselect) {
    return {
      [action]: selectedItems[action].filter(item => !children.includes(item)),
      [oppositeAction]: selectedItems[oppositeAction].filter(item => !children.includes(item)),
    };
  }

  const actionItems = selectedItems[action].includes(optionId)
    ? selectedItems[action].filter(item => !children.includes(item))
    : Array.from(new Set([...selectedItems[action], ...children]));

  const oppositeItems = selectedItems[oppositeAction].filter(item => !children.includes(item));

  return {
    [action]: actionItems,
    [oppositeAction]: oppositeItems,
  };
};

type GetPossibleActionsArgs = {
  options: OptionsMap;
  optionId: string;
  rule: AllowBlockType | null;
  selectedItems: AllowBlockFormState;
  isSelected: boolean;
};

export const getPossibleActions = ({ options, optionId, rule, selectedItems, isSelected }: GetPossibleActionsArgs) => {
  const option = options[optionId];
  const parentOption = option.parent_id ? options[option.parent_id] : null;
  const parentName = parentOption ? options[parentOption.id].name : '';

  const isOptionSelected = isSelected;
  const isParentSelectedAllowed = selectedItems.allow.includes(parentName);
  const isParentSelectedBlocked = selectedItems.block.includes(parentName);

  const canUnselect = isOptionSelected && !isParentSelectedAllowed && !isParentSelectedBlocked;

  const highestAllowLevel = findHighestLevel(options, selectedItems.allow);
  const isNotHighestLevel = highestAllowLevel !== option.level;

  const isBlockScenario = rule === AllowBlockType.BLOCK;
  const isAllowScenario = rule === AllowBlockType.ALLOW;
  const hasNoScenario = rule == null;

  const canAllow = !isParentSelectedBlocked && !isBlockScenario;
  const canBlock =
    hasNoScenario ||
    (isAllowScenario ? (isNotHighestLevel && isParentSelectedAllowed) || isParentSelectedAllowed : false) ||
    isBlockScenario;

  return {
    canAllow,
    canBlock,
    canUnselect,
  };
};
