import { useCallback, useState } from 'react';

interface useDrawerProps {
  restoreParams: () => void;
  onApply: () => void;
}

export const useDrawer = (props: useDrawerProps) => {
  const { restoreParams, onApply } = props;
  const [isOpen, setOpen] = useState<boolean>(false);

  const onDrawerClose = useCallback(() => {
    restoreParams();
    setOpen(false);
  }, [restoreParams]);

  const onDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onDrawerApply = useCallback(() => {
    onApply();
    setOpen(false);
  }, [onApply]);

  return {
    isOpen,
    onDrawerApply,
    onDrawerClose,
    onDrawerOpen,
  };
};
