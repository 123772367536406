import { shallowEqual, useSelector } from 'react-redux';

import type { SessionState } from '@openx/utils/state/session/types';

export interface InstanceStateType {
  session: SessionState;
}

interface InstanceInfo {
  currentInstanceUid: string | undefined;
  isBefInstance: boolean;
}

export const useCurrentInstanceState = () => {
  const instanceUid = useSelector<InstanceStateType, InstanceInfo>(
    state => ({
      currentInstanceUid: state.session.details?.instance?.uid,
      isBefInstance: state.session.details?.instance?.experience === 'bef',
    }),
    shallowEqual
  );

  return instanceUid;
};
