import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  type InstanceProps,
  type InventoryAndContentState,
  InventoryContentOption,
} from '@openx/types/targeting/inventoryAndContent';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { EmptyFilterMessage, Switch } from '../../shared';
import { setContentInterDimentionOperator } from '../../shared/InventoryAndContent/inventoryHelpers';
import type { TargetingItemsProps } from '../../types';

import { AdunitItems } from './AdunitItems';
import { DefaultItems } from './DefaultItems';
import { InventoryIDs } from './InventoryIDs';
import { InventoryItems } from './InventoryItems';
import { KeywordItems } from './KeywordItems';
import { getInventoriesWithSettings } from './utils';

interface contentItemsProps extends TargetingItemsProps<InventoryAndContentState> {
  inputInstanceValue?: InstanceProps | null;
  isBefInstance: boolean;
}

type InventoryContentWithoutContentOperator = Exclude<
  InventoryContentOption,
  InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR
>;

type InventoryAndContentT = {
  [key in InventoryContentWithoutContentOperator]: (isNotLastItem: boolean) => JSX.Element | null;
};

export const InventoryAndContentItems = ({
  targetingParams,
  dispatchInventory = () => {},
  readonly,
  instanceState,
  inputInstanceValue = null,
  isBefInstance,
}: contentItemsProps) => {
  const { t } = useTranslation();

  const inventoriesWithSettings = getInventoriesWithSettings(targetingParams);
  const inventoriesLength = inventoriesWithSettings.length;

  const inventoryAndContent: InventoryAndContentT = {
    [InventoryContentOption.ADUNIT]: isNotLastItem => (
      <InventoryIDs
        dispatchInventory={dispatchInventory}
        readonly={readonly}
        option={InventoryContentOption.ADUNIT}
        targetingParams={targetingParams}
        key={InventoryContentOption.ADUNIT}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'ad-unit-ids'}
      />
    ),
    [InventoryContentOption.ADUNIT_SIZE]: isNotLastItem => (
      <AdunitItems
        dispatchInventory={dispatchInventory}
        readonly={readonly}
        targetingParams={targetingParams}
        key={InventoryContentOption.ADUNIT_SIZE}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'adunit-size'}
      />
    ),
    [InventoryContentOption.APP_BUNDLE_ID]: isNotLastItem => (
      <DefaultItems
        readonly={readonly}
        dispatchInventory={dispatchInventory}
        targetingParams={targetingParams}
        option={InventoryContentOption.APP_BUNDLE_ID}
        key={InventoryContentOption.APP_BUNDLE_ID}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'app-bundle-id'}
      />
    ),
    [InventoryContentOption.INVENTORY]: isNotLastItem => (
      <InventoryItems
        readonly={readonly}
        dispatchInventory={dispatchInventory}
        targetingParams={targetingParams}
        instanceState={instanceState}
        key={InventoryContentOption.INVENTORY}
        isInventorySelected={!!inputInstanceValue}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
      />
    ),
    [InventoryContentOption.PAGE_URL]: isNotLastItem => (
      <DefaultItems
        readonly={readonly}
        dispatchInventory={dispatchInventory}
        targetingParams={targetingParams}
        option={InventoryContentOption.PAGE_URL}
        key={InventoryContentOption.PAGE_URL}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'page-url'}
      />
    ),
    [InventoryContentOption.PUBLISHER_ID]: isNotLastItem =>
      isBefInstance ? (
        <InventoryIDs
          dispatchInventory={dispatchInventory}
          readonly={readonly}
          option={InventoryContentOption.PUBLISHER_ID}
          targetingParams={targetingParams}
          key={InventoryContentOption.PUBLISHER_ID}
          isNotLastItem={isNotLastItem}
          switchState={switchState}
          dataTest={'publisher-ids'}
        />
      ) : null,
    [InventoryContentOption.INSTANCE_HASH]: isNotLastItem =>
      isBefInstance ? (
        <InventoryIDs
          dispatchInventory={dispatchInventory}
          readonly={readonly}
          option={InventoryContentOption.INSTANCE_HASH}
          targetingParams={targetingParams}
          key={InventoryContentOption.INSTANCE_HASH}
          isNotLastItem={isNotLastItem}
          switchState={switchState}
          dataTest={'instance-hash'}
        />
      ) : null,
    [InventoryContentOption.SITE]: isNotLastItem => (
      <InventoryIDs
        dispatchInventory={dispatchInventory}
        readonly={readonly}
        option={InventoryContentOption.SITE}
        targetingParams={targetingParams}
        key={InventoryContentOption.SITE}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'site-ids'}
      />
    ),
    [InventoryContentOption.KEYWORDS]: isNotLastItem => (
      <KeywordItems
        dispatchInventory={dispatchInventory}
        readonly={readonly}
        targetingParams={targetingParams}
        key={InventoryContentOption.KEYWORDS}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
      />
    ),
    [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: isNotLastItem => (
      <InventoryIDs
        dispatchInventory={dispatchInventory}
        readonly={readonly}
        option={InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID}
        targetingParams={targetingParams}
        key={InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID}
        isNotLastItem={isNotLastItem}
        switchState={switchState}
        dataTest={'line-items-app-bundle-ids'}
      />
    ),
  };
  const showSwitch = !(readonly || typeof readonly === 'undefined' || inventoriesWithSettings.length === 0);
  const switchState = targetingParams[InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR] === Criteria.ANY;

  return (
    <Grid>
      {showSwitch && (
        <Box sx={{ marginTop: theme => theme.spacing(3) }}>
          <Switch
            switchState={switchState}
            onSwitchChange={() => {
              dispatchInventory({
                payload: switchState ? Criteria.ALL : Criteria.ANY,
                type: setContentInterDimentionOperator,
              });
            }}
            switchOffLabel={t('MATCH ALL')}
            switchOnLabel={t('MATCH ANY')}
          />
        </Box>
      )}
      {inventoriesLength ? (
        <Grid>
          {inventoriesWithSettings.map((inventory, index) =>
            inventoryAndContent[inventory](inventoriesLength - 1 > index)
          )}
        </Grid>
      ) : (
        <EmptyFilterMessage text={t('No filters applied.')} />
      )}
    </Grid>
  );
};
