import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useCountriesOptionsFetch() {
  const { t } = useTranslation();
  const {
    isLoading: isCountriesLoading,
    data: countries,
    refetch: refetchCountries,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch countries options'),
    optionType: OptionsType.COUNTRIES,
  });

  return {
    countries,
    isCountriesLoading,
    refetchCountries,
  };
}
