import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import type { ReactEventHandler } from 'react';

import { Ellipsis } from '../Ellipsis/Ellipsis';

import { DrawerDivider } from './DrawerDivider';

export interface DrawerHeaderProps {
  children?: string | JSX.Element;
  onClose?: ReactEventHandler;
  marginBottom?: number;
  dataTest?: string;
}

export function DrawerHeader(props: DrawerHeaderProps): JSX.Element {
  const { children, onClose, marginBottom = 2, dataTest } = props;

  return (
    <Box
      sx={{
        marginBottom: theme => theme.spacing(marginBottom),
      }}
      data-test={dataTest}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme => theme.spacing(1.5),
        }}
      >
        <Typography
          variant="h3"
          data-test="details-title"
          sx={{
            overflowX: 'hidden',
          }}
        >
          <Ellipsis width="100%" tooltip={true} detectTooltipResize={true}>
            {children}
          </Ellipsis>
        </Typography>
        {onClose && (
          <IconButton
            aria-label="Close drawer"
            data-test="close-drawer"
            onClick={onClose}
            sx={{
              marginLeft: theme => theme.spacing(3),
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
      <DrawerDivider />
    </Box>
  );
}
