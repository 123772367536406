import { type ChangeEvent, useCallback, useEffect, useState } from 'react';

import { DebounceField } from '@openx/components/core/lib/DebounceField/DebounceField';
import type { TextFieldProps } from '@openx/components/core/lib/TextField/TextField';
import type { PaginationCriteria } from '@openx/types/tableCriteria';
import { maxPageNumber } from '@openx/utils/lib/tableHelpers';

export interface PaginationTextFieldProps {
  paginationParams: PaginationCriteria;
  loading?: boolean;
  onChange: (paginationParams: PaginationCriteria) => void;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  className?: string;
}

export function PaginationTextField(props: PaginationTextFieldProps): JSX.Element {
  const { onChange, textFieldProps, paginationParams, disabled, className } = props;
  const [value, setValue] = useState<string>(String(paginationParams.pageNumber));

  const onChangeField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const numberedValue = Number(newValue);
      const ignoreChange = Number.isNaN(numberedValue) || !newValue;
      const hasMore = paginationParams.hasMore;
      const adjustedToMax = hasMore ? numberedValue : Math.min(numberedValue, maxPageNumber(paginationParams));
      const adjustedToMin = Math.max(1, adjustedToMax);

      setValue(ignoreChange ? '' : String(adjustedToMin));

      if (!ignoreChange && adjustedToMin !== paginationParams.pageNumber) {
        onChange({
          ...paginationParams,
          pageNumber: adjustedToMin,
        });
      }
    },
    [setValue, onChange, paginationParams]
  );

  useEffect(() => setValue(String(paginationParams.pageNumber)), [paginationParams.pageNumber]);

  return (
    <DebounceField
      className={className}
      data-test="current-page"
      disabled={disabled}
      {...textFieldProps}
      value={value}
      onChange={onChangeField}
    />
  );
}
