import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useVideoPlacementOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isVideoPlacementLoading,
    data: videoPlacement,
    refetch: refetchVideoPlacement,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch placement options'),
    mapByKey: 'id',
    optionType: OptionsType.VIDEO_PLACEMENT,
    queryOptions,
  });

  return {
    isVideoPlacementLoading,
    refetchVideoPlacement,
    videoPlacement,
  };
}
