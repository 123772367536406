import { Typography, type TypographyProps } from '@mui/material';

interface TitleProps extends TypographyProps {
  title: string;
  ['data-test']?: string;
}

export const Title = ({ title, sx, ...props }: TitleProps): JSX.Element => {
  return (
    <Typography
      {...props}
      data-test={props['data-test'] ?? ''}
      variant="overline"
      sx={{
        color: theme => theme.palette.text.secondary,
        fontWeight: theme => theme.typography.fontWeightBold,
        lineHeight: theme => theme.typography.body2.lineHeight,
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
};
