import { cloneDeep, isObject } from 'lodash';

import { difference } from '@openx/components/form';
import { getContextualSegments, getCustomVariables, targetingDimension } from '@openx/components/targeting';
import {
  PackageFields,
  type PackageFormState,
  type PackageWithTargeting,
  type PrivateMarket,
} from '@openx/types/package';
import type { AdvancedTargetingData } from '@openx/types/targeting/advancedTargeting';
import { type ContentObjectApi, ContentObjectOptions } from '@openx/types/targeting/contentObject';
import { contextualSegmentPrefix } from '@openx/types/targeting/contextualSegments';
import type { CustomVariablesData } from '@openx/types/targeting/customVariables';
import { type FormatTypeData, InventoryFormatTypeOption } from '@openx/types/targeting/formatType';
import { GeographicOption, type GeographicTargetingApi } from '@openx/types/targeting/geographic';
import { InventoryContentOption } from '@openx/types/targeting/inventoryAndContent';
import { SelectRateOption } from '@openx/types/targeting/selectRate';
import { type Targeting, TargetingFields } from '@openx/types/targeting/targeting';
import { ComparisonType, Criteria, type CriteriaValue, Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { getInitialPckValues, packageUsedKeys } from '../constants';

const formatPrice = (price: string | null | undefined): string =>
  price
    ? new Intl.NumberFormat('en-EN', {
        maximumFractionDigits: 4,
        minimumFractionDigits: 2,
      }).format(Number(price))
    : '';

export const formatGeographic = (geographic?: GeographicTargetingApi | null): GeographicTargetingApi | null => {
  if (!geographic) return null;

  if (typeof geographic?.[GeographicOption.CIRCLES]?.val === 'object') {
    geographic[GeographicOption.CIRCLES].val = JSON.stringify(geographic[GeographicOption.CIRCLES].val);
  }

  return geographic;
};

const splitCustomVariables = (
  customVariables?: CustomVariablesData | null
): { contextual: CustomVariablesData | null; custom: CustomVariablesData | null } => {
  return {
    contextual: getContextualSegments(customVariables),
    custom: getCustomVariables(customVariables),
  };
};

export function cleanUpTargetingNulls(targetingParams) {
  if (!targetingParams || typeof targetingParams !== 'object') {
    return null;
  }

  Object.entries(targetingParams).forEach(([field, value]) => {
    if (isObject(value)) {
      targetingParams[field] = cleanUpTargetingNulls(value);
    }

    if (targetingParams[field] === null) {
      delete targetingParams[field];
    }
  });

  const targetingSize = Object.keys(targetingParams).length;
  const isSingleDimensionOperator =
    Object.keys(targetingParams).includes(InventoryContentOption.CONTENT_INTER_DIMENSION_OPERATOR) &&
    targetingSize === 1;

  return targetingSize > 0 && !isSingleDimensionOperator ? targetingParams : null;
}

const sortFormatTypeValues = (formatTypeTargeting: FormatTypeData | null): FormatTypeData | null => {
  return (
    formatTypeTargeting && {
      ...formatTypeTargeting,
      ...(formatTypeTargeting.ad_placement?.val && {
        ad_placement: {
          op: Intersect.INTERSECTS,
          val: formatTypeTargeting.ad_placement?.val,
        },
      }),
      ...(formatTypeTargeting.distribution_channel?.val && {
        distribution_channel: {
          op: Intersect.INTERSECTS,
          val: formatTypeTargeting.distribution_channel?.val,
        },
      }),
    }
  );
};

export const packageToFormState = (pckgInput: Partial<PackageWithTargeting>): PackageFormState => {
  const pckg = cloneDeep(pckgInput);
  const initValues = getInitialPckValues();
  let inventoryAndContent, viewability, vtr, excludeIndirectValue, includeOpenBiddingInventory, contentObject;

  if (pckg) {
    Object.keys(pckg).forEach(k => {
      if (packageUsedKeys.includes(k) && pckg[k]) {
        switch (k) {
          case PackageFields.PRIVATE_MARKET:
            break;
          case PackageFields.RATE:
            initValues[k] = formatPrice(pckg[k]);
            break;
          case PackageFields.URLS:
            initValues[k] = Object.keys(pckg[k] || {});
            break;
          case TargetingFields.URL_TARGETING:
            initValues[PackageFields.TARGETING][k] = pckg[k] || null;
            break;
          case PackageFields.TARGETING:
            initValues[k][TargetingFields.DIMENSION] =
              pckg[k]?.inter_dimension_operator === Criteria.ALL ? Criteria.ALL : Criteria.ANY;

            excludeIndirectValue = !!pckg[k]?.exclude_non_direct;
            initValues[k][TargetingFields.EXCLUDE_INDIRECT] = excludeIndirectValue;

            includeOpenBiddingInventory = pckg[k]?.include_open_bidding_inventory || null;
            initValues[k][TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY] = !!includeOpenBiddingInventory;

            inventoryAndContent = cleanUpTargetingNulls(pckg[k]?.[TargetingFields.INVENTORY_AND_CONTENT]);
            initValues[k][TargetingFields.INVENTORY_AND_CONTENT] = inventoryAndContent;

            initValues[k][TargetingFields.CATEGORY] = cleanUpTargetingNulls(
              pckg[k]?.[TargetingFields.DOMAIN]?.[TargetingFields.CATEGORY]
            );

            initValues[k][TargetingFields.METACATEGORY] = cleanUpTargetingNulls(
              pckg[k]?.[TargetingFields.METACATEGORY]
            );

            if (initValues[k][TargetingFields.METACATEGORY] === null) {
              initValues[k][TargetingFields.METACATEGORY] = {
                exclude_mfa: false,
                excludes: [],
                includes: null,
                inter_dimension_operator: Criteria.ALL,
                keywords: {
                  excludes: [],
                  includes: [],
                },
              };
            }

            initValues[k][TargetingFields.TECHNOLOGY_AND_DEVICES] = cleanUpTargetingNulls(
              pckg[k]?.[TargetingFields.TECHNOLOGY_AND_DEVICES]
            );

            initValues[k][TargetingFields.GEOGRAPHIC] = cleanUpTargetingNulls(
              formatGeographic(pckg[k]?.[TargetingFields.GEOGRAPHIC])
            );

            initValues[k][TargetingFields.VIDEO] = cleanUpTargetingNulls(pckg[k]?.[TargetingFields.VIDEO]);

            if (pckg[k]?.[TargetingFields.CUSTOM_VARIABLES]) {
              const { contextual, custom } = splitCustomVariables(
                cleanUpTargetingNulls(pckg[k]?.[TargetingFields.CUSTOM_VARIABLES])
              );

              initValues[k][TargetingFields.CUSTOM_VARIABLES] = {
                contextual: contextual?.val ?? [],
                contextualV2: { attr: '', excludes: [], includes: [], op: ComparisonType.INTERSECTS },
                custom: custom?.val ?? [],
                op: (custom?.op ?? contextual?.op) as CriteriaValue,
              };
            }

            vtr = pckg[k]?.[TargetingFields.VIEW_THROUGH_RATE];
            initValues[k][TargetingFields.VIEW_THROUGH_RATE] =
              cleanUpTargetingNulls(vtr?.[SelectRateOption.VTR_SCORE]) || null;

            viewability = pckg[k]?.[TargetingFields.VIEWABILITY];
            initValues[k][TargetingFields.VIEWABILITY] =
              cleanUpTargetingNulls(viewability?.[SelectRateOption.VIEWABILITY_SCORE]) || null;

            contentObject = cleanUpTargetingNulls(pckg[k]?.[TargetingFields.CONTENT_OBJECT]) || null;
            initValues[k][TargetingFields.CONTENT_OBJECT] = contentObject;

            initValues[k][TargetingFields.FORMAT_TYPE] =
              cleanUpTargetingNulls(pckg[k]?.[TargetingFields.FORMAT_TYPE]) || null;
            sortFormatTypeValues(initValues[k][TargetingFields.FORMAT_TYPE]);

            initValues[k][TargetingFields.AUDIENCE] = cleanUpTargetingNulls(pckg[k]?.[TargetingFields.AUDIENCE]);
            break;
          default:
            initValues[k] = pckg[k];
        }
      }
    });

    const pckgPrivMarket = pckg[PackageFields.PRIVATE_MARKET];

    if (pckgPrivMarket) {
      const initPrivMarket = initValues[PackageFields.PRIVATE_MARKET];
      const { discoverable, default_discounted_cpm, default_rate_card_cpm } = pckgPrivMarket;
      initPrivMarket['discoverable'] = discoverable === '1' ? true : false;
      initPrivMarket['default_discounted_cpm'] = formatPrice(default_discounted_cpm);
      initPrivMarket['default_rate_card_cpm'] = formatPrice(default_rate_card_cpm);
    }

    if (!pckg.targeting) {
      initValues[PackageFields.TARGETING][TargetingFields.EXCLUDE_MFA] = false;
    }
  }

  return initValues;
};

export const getHierarchyAttrsContentObjectData = (
  contentObject: ContentObjectApi,
  targetingData: Targeting,
  option: typeof ContentObjectOptions.CONTENT_RATING | typeof ContentObjectOptions.GENRE
) => {
  const targetingField = targetingData[TargetingFields.CONTENT_OBJECT]![option]!;

  const hasAllowItems = targetingField?.apiAllow || targetingField?.allow;
  const hasBlockItems = targetingField?.apiBlock || targetingField?.block;

  let newField = targetingField;

  if (hasAllowItems) {
    newField = {
      allow: contentObject[option]!.apiAllow ?? contentObject[option]!.allow,
      block: contentObject[option]!.block,
    };
  }

  if (hasBlockItems) {
    newField = {
      allow: contentObject[option]!.allow,
      block: contentObject[option]!.apiBlock ?? contentObject[option]!.block,
    };
  }

  if (newField.apiAllow) {
    delete newField.apiAllow;
  }

  if (newField.apiBlock) {
    delete newField.apiBlock;
  }

  return newField;
};

export const mapCustomVariables = (customVariables: AdvancedTargetingData) => {
  const isLegacyStateUpdate = !customVariables?.contextualV2;
  const op = customVariables?.op;

  if (isLegacyStateUpdate) {
    const val = customVariables.custom?.length
      ? customVariables.custom
      : customVariables?.contextual?.map(ctxSegment => ({
          ...ctxSegment,
          attr: `${contextualSegmentPrefix}${ctxSegment.attr}`,
        }));

    return val?.length ? { op, val } : undefined;
  }

  const mappedContextual = customVariables?.contextual?.map(ctxSegment => ({
    ...ctxSegment,
    attr: `${contextualSegmentPrefix}${ctxSegment.attr}`,
  }));
  const val = [...(customVariables?.custom || []), ...mappedContextual];

  return val?.length ? { op, val } : undefined;
};

export const formStateToPackage = (formState: PackageFormState): Partial<PackageWithTargeting> => {
  const pckg: Partial<PackageWithTargeting> = {};

  let targetingData: Targeting,
    inventoryAndContent,
    category,
    metacategory,
    geographic,
    technographic,
    video,
    customVariables,
    viewability,
    audience,
    formatType,
    vtr,
    excludeIndirect,
    includeOpenBiddingInventory,
    contentObject;
  Object.keys(formState).forEach(k => {
    switch (k) {
      case PackageFields.PRIVATE_MARKET:
        break;
      case PackageFields.TARGETING:
        targetingData = {
          ...targetingDimension[formState[k][TargetingFields.DIMENSION]],
        };
        inventoryAndContent = formState[k][TargetingFields.INVENTORY_AND_CONTENT];
        excludeIndirect = formState[k][TargetingFields.EXCLUDE_INDIRECT];
        includeOpenBiddingInventory = formState[k][TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY];
        category = formState[k][TargetingFields.CATEGORY];
        metacategory = formState[k][TargetingFields.METACATEGORY];
        geographic = formState[k][TargetingFields.GEOGRAPHIC];
        technographic = formState[k][TargetingFields.TECHNOLOGY_AND_DEVICES];
        video = formState[k][TargetingFields.VIDEO];
        customVariables = formState[k][TargetingFields.CUSTOM_VARIABLES];
        viewability = formState[k][TargetingFields.VIEWABILITY];
        vtr = formState[k][TargetingFields.VIEW_THROUGH_RATE];
        formatType = formState[k][TargetingFields.FORMAT_TYPE];
        audience = formState[k][TargetingFields.AUDIENCE];
        contentObject = formState[k][TargetingFields.CONTENT_OBJECT];

        if (inventoryAndContent) {
          targetingData[TargetingFields.INVENTORY_AND_CONTENT] = inventoryAndContent;
        }

        if (excludeIndirect) {
          targetingData[TargetingFields.EXCLUDE_INDIRECT] = excludeIndirect;
        }

        if (metacategory) {
          targetingData[TargetingFields.METACATEGORY] = metacategory;

          const { keywords } = targetingData[TargetingFields.METACATEGORY] || {};
          if (!keywords?.includes?.length && !keywords?.excludes?.length) {
            delete targetingData[TargetingFields.METACATEGORY]?.keywords;
          }
        }

        if (includeOpenBiddingInventory && excludeIndirect) {
          targetingData[TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY] = '1';
        }

        if (category) {
          targetingData[TargetingFields.DOMAIN] = { [TargetingFields.CATEGORY]: category };
        }

        if (geographic) {
          targetingData[TargetingFields.GEOGRAPHIC] = geographic;
        }

        if (technographic) {
          targetingData[TargetingFields.TECHNOLOGY_AND_DEVICES] = technographic;
        }

        if (video) {
          targetingData[TargetingFields.VIDEO] = video;
        }

        if (vtr) {
          targetingData[TargetingFields.VIEW_THROUGH_RATE] = { [SelectRateOption.VTR_SCORE]: vtr };
        }
        if (customVariables) {
          targetingData[TargetingFields.CUSTOM_VARIABLES] = mapCustomVariables(customVariables);
        }

        if (viewability) {
          targetingData[TargetingFields.VIEWABILITY] = { [SelectRateOption.VIEWABILITY_SCORE]: viewability };
        }

        if (contentObject) {
          targetingData[TargetingFields.CONTENT_OBJECT] = contentObject;

          if (targetingData[TargetingFields.CONTENT_OBJECT]?.[ContentObjectOptions.GENRE]) {
            targetingData[TargetingFields.CONTENT_OBJECT][ContentObjectOptions.GENRE] =
              getHierarchyAttrsContentObjectData(contentObject, targetingData, ContentObjectOptions.GENRE);
          }

          if (targetingData[TargetingFields.CONTENT_OBJECT]?.[ContentObjectOptions.CONTENT_RATING]) {
            targetingData[TargetingFields.CONTENT_OBJECT][ContentObjectOptions.CONTENT_RATING] =
              getHierarchyAttrsContentObjectData(contentObject, targetingData, ContentObjectOptions.CONTENT_RATING);
          }
        }

        if (formatType) {
          targetingData[TargetingFields.FORMAT_TYPE] = formatType;

          if (
            formatType[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL] &&
            !formatType[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.val
          ) {
            delete targetingData[TargetingFields.FORMAT_TYPE]?.[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL];
          }

          if (
            formatType[InventoryFormatTypeOption.AD_PLACEMENT] &&
            !formatType[InventoryFormatTypeOption.AD_PLACEMENT]?.val
          ) {
            delete targetingData[TargetingFields.FORMAT_TYPE]?.[InventoryFormatTypeOption.AD_PLACEMENT];
          }
        }

        if (audience) {
          targetingData[TargetingFields.AUDIENCE] = audience;
        }

        pckg[k] = targetingData;
        // url targeting is stored as separate package key (not included into 'targeting' object)
        pckg[TargetingFields.URL_TARGETING] = formState[k][TargetingFields.URL_TARGETING];
        break;
      default:
        pckg[k] = formState[k];
    }
  });

  const formPrivMarket = formState[PackageFields.PRIVATE_MARKET];
  const { discoverable, default_discounted_cpm, default_rate_card_cpm } = formPrivMarket;

  const pckgMarket = {} as PrivateMarket;

  pckgMarket['discoverable'] = discoverable ? '1' : '0';
  pckgMarket['default_discounted_cpm'] = default_discounted_cpm || null;
  pckgMarket['default_rate_card_cpm'] = default_rate_card_cpm || null;

  pckg[PackageFields.PRIVATE_MARKET] = pckgMarket;

  return pckg;
};

export const getDiff = (
  formValues: PackageFormState,
  initialValues: PackageFormState
): Partial<PackageWithTargeting> => {
  const formValuesToApi = formStateToPackage(formValues);
  const diff = difference(formValuesToApi, formStateToPackage(initialValues));
  const targetingChanged = diff[PackageFields.TARGETING] || diff[TargetingFields.URL_TARGETING];

  if (targetingChanged) {
    diff[PackageFields.TARGETING] = formValuesToApi[PackageFields.TARGETING];
  }

  return diff;
};
