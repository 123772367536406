import { differenceInDays, format, isValid, parse, setSeconds } from 'date-fns';

type DateType = Date | number | string | null | undefined;

export const fromUTCDate = (inputDate: string) => {
  return parse(inputDate, 'yyyy-MM-dd', new Date());
};

export const fromUTCDateTime = (inputDate: string) => {
  return parse(inputDate, 'yyyy-MM-dd HH:mm:ss', new Date());
};

export function isSoonToDate(date: DateType, soonWindow = 10): boolean {
  if (!date) {
    return false;
  }

  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    return false;
  }

  const daysRemaining = differenceInDays(parsedDate, new Date());

  return daysRemaining <= soonWindow;
}

export const toUTCDate = (inputDate: DateType): string | null => {
  return toUTCString(inputDate, 'yyyy-MM-dd');
};

export const toUTCDateTime = (inputDate: DateType): string | null => {
  return toUTCString(inputDate);
};

export const toUTCString = (inputDate: DateType, pattern = 'yyyy-MM-dd HH:mm:ss'): string | null => {
  if (!inputDate) {
    return null;
  }

  const parsedDate = new Date(inputDate);

  if (!isValid(parsedDate)) {
    return null;
  }

  const utcDate = setSeconds(parsedDate, 0);

  return format(utcDate, pattern);
};
