import { createContext, useCallback, useState } from 'react';

import { GenericModal } from './components/GenericModal';
import type { UseConfirmationDialogParams, UseConfirmationDialogState } from './types';

const useConfirmationDialogDefaultState: UseConfirmationDialogState = {
  content: 'Are you sure?',
  customModal: null,
  isLoading: false,
  isOpen: false,
  onCancel: () => undefined,
  onConfirmed: () => undefined,
  onExtraAction: () => undefined,
  showExtraBtn: false,
  title: 'Confirm operation',
};

export type ConfirmationDialogContextProps = {
  open: (openParams?: Partial<UseConfirmationDialogParams>) => void;
  close: () => void;
  setCancelLabel: (label: string) => void;
  setLoading: (loading: boolean) => void;
  refresh: (content?: UseConfirmationDialogParams['content']) => void;
  toogleConfirmationDisabled: (confirmationDisabled?: boolean) => void;
  toogleConfirmationHidden: (confirmationHidden?: boolean) => void;
  openCustomModal: (Modal: JSX.Element) => void;
  confirmationDialog: UseConfirmationDialogState;
};

export const ConfirmationDialogContext = createContext<ConfirmationDialogContextProps>({
  close: () => undefined,
  confirmationDialog: useConfirmationDialogDefaultState,
  open: () => undefined,
  openCustomModal: () => undefined,
  refresh: () => undefined,
  setCancelLabel: () => undefined,
  setLoading: () => undefined,
  toogleConfirmationDisabled: () => undefined,
  toogleConfirmationHidden: () => undefined,
});

export function ConfirmationDialogProvider({ children }): JSX.Element {
  const [confirmationDialog, setConfirmationDialog] = useState<UseConfirmationDialogState>({
    ...useConfirmationDialogDefaultState,
  });

  const openCustomModal = useCallback(
    (Modal: JSX.Element) => {
      setConfirmationDialog(() => ({
        content: '',
        customModal: Modal,
        isLoading: false,
        isOpen: true,
        onCancel: () => undefined,
        onConfirmed: () => undefined,
        showExtraBtn: false,
        title: '',
      }));
    },
    [setConfirmationDialog]
  );

  const close = useCallback(() => {
    setConfirmationDialog(prevConfirmationDialog => ({
      ...prevConfirmationDialog,
      customModal: null,
      isOpen: false,
    }));
  }, [setConfirmationDialog]);

  const open = useCallback(
    (openParams?: Partial<UseConfirmationDialogParams>) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        ...openParams,
        isOpen: true,
      }));
    },
    [setConfirmationDialog]
  );

  const setCancelLabel = useCallback(
    (label: string) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        cancelLabel: label,
      }));
    },
    [setConfirmationDialog]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        isLoading: loading,
      }));
    },
    [setConfirmationDialog]
  );

  const toogleConfirmationDisabled = useCallback(
    (confirmationDisabled?: boolean) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        confirmationDisabled: confirmationDisabled ?? prevConfirmationDialog.confirmationDisabled,
      }));
    },
    [setConfirmationDialog]
  );

  const toogleConfirmationHidden = useCallback(
    (confirmationHidden?: boolean) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        hideConfirm: confirmationHidden ?? prevConfirmationDialog.hideConfirm,
      }));
    },
    [setConfirmationDialog]
  );

  const refresh = useCallback(
    content => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        content,
      }));
    },
    [setConfirmationDialog]
  );

  return (
    <ConfirmationDialogContext.Provider
      value={{
        close,
        confirmationDialog,
        open,
        openCustomModal,
        refresh,
        setCancelLabel,
        setLoading,
        toogleConfirmationDisabled,
        toogleConfirmationHidden,
      }}
    >
      {children}
      {!confirmationDialog.customModal ? (
        <GenericModal config={confirmationDialog} close={close} />
      ) : (
        confirmationDialog.customModal
      )}
    </ConfirmationDialogContext.Provider>
  );
}
