import { IconButton, Tooltip } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useState } from 'react';

import { copyToClipboard } from '@openx/utils/lib/copyToClipboard';

import { COLORS } from '../theme/palette';

const StyledIconButton = styled(IconButton, { shouldForwardProp: prop => prop !== 'isSmall' })<{ isSmall: boolean }>`
  ${({ isSmall, theme }) =>
    isSmall &&
    css`
      margin-top: ${theme.spacing(-0.25)};
      padding: ${theme.spacing(0.5)};
      height: 22px;
      width: 22px;
    `}
`;

function CopyIcon(props): JSX.Element {
  return (
    <svg
      width={11.2}
      height={13}
      color={COLORS.TEXT_LIGHT}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.357.518H1.286c-.648 0-1.179.53-1.179 1.178v8.25h1.179v-8.25h7.071V.518zm1.768 2.357H3.643c-.648 0-1.179.53-1.179 1.179v8.25c0 .648.53 1.178 1.179 1.178h6.482c.648 0 1.179-.53 1.179-1.178v-8.25c0-.649-.53-1.179-1.179-1.179zm0 9.429H3.643v-8.25h6.482v8.25z"
        fill="#666"
      />
    </svg>
  );
}

interface CopyToClipboardProps {
  text: string;
  iconSize?: 'small' | 'medium';
  tooltipText?: string;
}

const sizesConfig = {
  medium: { height: 16, width: 16 },
  small: { height: 13, width: 11.2 },
};

export const CopyToClipboard = ({ text, tooltipText, iconSize = 'small' }: CopyToClipboardProps): JSX.Element => {
  const initialState = tooltipText ?? 'Copy to clipboard';
  const [copyText, setCopyText] = useState<string>(initialState);
  const size = sizesConfig[iconSize];

  const onClick = e => {
    e.stopPropagation();
    setCopyText('Copied!');
    copyToClipboard(text);
  };

  const onMouseLeave = () => {
    setTimeout(() => setCopyText(initialState), 500);
  };

  return (
    <Tooltip title={copyText} placement={'top'}>
      <StyledIconButton
        isSmall={iconSize === 'small'}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        data-test="copy-to-clipboard"
      >
        <CopyIcon style={{ cursor: 'pointer' }} {...size}></CopyIcon>
      </StyledIconButton>
    </Tooltip>
  );
};
