import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useAdPlacementOptionsFetch() {
  const { t } = useTranslation();
  const {
    isLoading: isAdPlacementLoading,
    data: adPlacement,
    refetch: refetchAdPlacement,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Ad Placement options'),
    optionType: OptionsType.AD_PLACEMENT,
  });

  return {
    adPlacement,
    isAdPlacementLoading,
    refetchAdPlacement,
  };
}
