import { styled } from '@mui/material/styles';

import type { BaseRow, RowActionButtonBaseProps } from '@openx/types/tableHelpers';

import { RowActionButton } from './RowActionButton';

const StyledActionsContainerDiv = styled('div')`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.sky};
  bottom: 0;
  box-shadow: ${({ theme }) => `${theme.spacing(-2.5)} 0 ${theme.spacing(1.5)} ${theme.spacing(-1)}
      ${theme.palette.background.sky}`};
  display: none;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  position: absolute;
  right: calc(100% + 1px);
  top: 0;
  white-space: nowrap;

  & > * {
    margin: ${({ theme }) => theme.spacing(0, 0.5)} !important;
  }

  .expandContentRow tr:hover &,
  tr:not(.expandContentRow):hover & {
    display: flex;
  }
`;

export interface RowActionsProps<RowT extends BaseRow> {
  rowData: RowT;
  primaryAction?: RowActionButtonBaseProps<RowT>;
  secondaryAction?: RowActionButtonBaseProps<RowT>;
  dangerAction?: RowActionButtonBaseProps<RowT>;
  defaultAction?: RowActionButtonBaseProps<RowT>;
  customRowActions?: RowActionButtonBaseProps<RowT>[];
}

export function RowActions<RowT extends BaseRow>({
  rowData,
  primaryAction,
  secondaryAction,
  dangerAction,
  defaultAction,
  customRowActions,
}: RowActionsProps<RowT>): JSX.Element {
  const showAction = (action?: RowActionButtonBaseProps<RowT>) => {
    if (!action) {
      return false;
    }

    if (!action.hide) {
      return true;
    }

    if (typeof action.hide === 'boolean') {
      return !action.hide;
    } else {
      return !action.hide(rowData);
    }
  };

  return (
    <StyledActionsContainerDiv>
      {customRowActions ? (
        customRowActions.map(
          action =>
            showAction(action) && (
              <RowActionButton
                key={typeof action.label === 'string' ? action.label : action.label(rowData)}
                {...action}
                rowData={rowData}
                color={action.color ?? 'primary'}
              />
            )
        )
      ) : (
        <>
          {showAction(primaryAction) && primaryAction && (
            <RowActionButton {...primaryAction} rowData={rowData} color="primary" />
          )}
          {showAction(secondaryAction) && secondaryAction && (
            <RowActionButton {...secondaryAction} rowData={rowData} color="secondary" />
          )}
          {showAction(dangerAction) && dangerAction && (
            <RowActionButton {...dangerAction} rowData={rowData} color="error" />
          )}
          {showAction(defaultAction) && defaultAction && (
            <RowActionButton {...defaultAction} rowData={rowData} color="default" />
          )}
        </>
      )}
    </StyledActionsContainerDiv>
  );
}
