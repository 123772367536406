import { Divider, MenuItem, Tooltip } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import type { SyntheticEvent } from 'react';

interface MenuButtonItemProps {
  disabled: boolean;
  isAllowedMessage: string;
  topDivider?: boolean;
  danger?: boolean;
  label: string;
  onClick: (e: SyntheticEvent) => void;
  hide: boolean;
}

const StyledMenuItem = styled(MenuItem, { shouldForwardProp: prop => prop !== 'isDanger' })<{ isDanger?: boolean }>`
  min-width: 150px;

  ${({ isDanger, theme }) =>
    isDanger &&
    css`
      color: ${theme.palette.error.main};
      :hover {
        color: ${theme.palette.error.contrastText};
        background-color: ${theme.palette.error.main};
      }
    `}
`;

export default function MenuButtonItem(props: MenuButtonItemProps): JSX.Element | null {
  const { disabled, topDivider, onClick, label, isAllowedMessage, danger, hide } = props;

  if (hide) {
    return null;
  }

  return (
    <Tooltip title={isAllowedMessage}>
      <div>
        {topDivider && <Divider />}
        <StyledMenuItem
          onClick={e => onClick(e)}
          disabled={disabled}
          isDanger={danger}
          data-test={props['data-test'] || 'actions-item'}
        >
          {label}
        </StyledMenuItem>
      </div>
    </Tooltip>
  );
}
