import { Grid } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { Button } from '@openx/components/core/lib/Button/Button';
import {
  type AdvancedTargetingData,
  type AdvancedTargetingType,
  AdvancedTargetingTypeLabel,
} from '@openx/types/targeting/advancedTargeting';
import type { CustomVariablesData } from '@openx/types/targeting/customVariables';
import { TargetingFields } from '@openx/types/targeting/targeting';

import { DrawerTopBox, useBulkAdd } from '../shared';

import { ContextualSegmentsBulkV2 } from './ContextualSegmentsV2/ContextualSegmentsBulkV2';
import { CustomVariablesTopBox } from './CustomVariables/CustomVariablesTopBox';
import { useGetContextualSegmentsComponents } from './hooks/useGetContextualSegmentsComponents';

type AdvancedTargetingTopBoxProps = {
  items: AdvancedTargetingData;
  onEnter: (items: CustomVariablesData) => void;
  onTypeChange: (value: AdvancedTargetingType) => void;
  type?: AdvancedTargetingType;
  updateParams: Dispatch<SetStateAction<AdvancedTargetingData>>;
  accountUid: string;
};

export const AdvancedTargetingTopBox = ({
  items,
  onEnter,
  onTypeChange,
  type,
  updateParams,
  accountUid,
}: AdvancedTargetingTopBoxProps) => {
  const { t } = useTranslation();
  const { ContextualSegmentsTopBox, segmentsV2 } = useGetContextualSegmentsComponents();
  const { isBulkOpen, onBulkClose, onBulkOpen } = useBulkAdd({ onAdd: () => {} });

  const displayBulkAdd = segmentsV2 && type === TargetingFields.CONTEXTUAL_SEGMENTS;
  const bulkBtnDisabled = !items.contextualV2?.attr;

  return (
    <DrawerTopBox>
      <Grid container>
        <Grid item xs={4}>
          <AutoComplete
            textFieldProps={{ label: 'Targeting Type', placeholder: t('[ Select Targeting Type ]') }}
            disableClearable
            options={Object.keys(AdvancedTargetingTypeLabel)}
            fullWidth
            renderOptions={{
              dataTest: 'type-option',
              tooltip: true,
            }}
            getOptionLabel={option => AdvancedTargetingTypeLabel[option] || option}
            onChange={(_, value) => onTypeChange(value as AdvancedTargetingType)}
            value={type ?? ''}
            textFieldReadOnly
            data-test="select-targeting-type"
          />
        </Grid>

        {type === TargetingFields.CONTEXTUAL_SEGMENTS && (
          <ContextualSegmentsTopBox
            items={items}
            onEnter={onEnter}
            sx={{ borderBottom: 0, paddingBottom: 0, paddingTop: 0 }}
            updateParams={updateParams}
            accountUid={accountUid}
          />
        )}

        {type === TargetingFields.CUSTOM_VARIABLES && (
          <CustomVariablesTopBox
            items={items}
            onEnter={onEnter}
            sx={{ borderBottom: 0, paddingBottom: 0, paddingTop: 0 }}
          />
        )}

        {displayBulkAdd && (
          <>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Button onClick={onBulkOpen} disabled={bulkBtnDisabled}>
                {t('Bulk Add')}
              </Button>
            </Grid>

            <ContextualSegmentsBulkV2
              isOpen={isBulkOpen}
              closeDrawer={onBulkClose}
              contextualItems={items.contextualV2}
              updateParams={updateParams}
            />
          </>
        )}
      </Grid>
    </DrawerTopBox>
  );
};
