import axios from 'axios';
import _ from 'lodash';

import type { GeoLocationsList, GeographyEntities } from '@openx/types/geoLocation';

import { fetchApiResponseHandler } from '../../api/utils';

import { getSearchQuery } from './utils';

export function searchGeoLocations(
  searchConfig: GeographyEntities,
  searchById?: boolean,
  limit?: number,
  extendQuery = '',
  isBulk?: boolean
): Promise<GeoLocationsList> {
  const fixedSearchConfig = _.omitBy(searchConfig, v => v === null);

  if (!Object.keys(fixedSearchConfig).length) {
    return Promise.resolve([]);
  }
  const params = new FormData();
  const searchPhrase = getSearchQuery(fixedSearchConfig, searchById, isBulk) + extendQuery;

  params.append('q', searchPhrase);
  params.append('order', 'asc');
  params.append('sort', 'name.none');
  if (limit) {
    params.append('size', `${limit}`);
  }

  return fetchApiResponseHandler(axios.post('/geo/search', params), 'hits.hits');
}
