import { isEqual, omit } from 'lodash';
import { type Ref, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import type { AdvancedTargetingData, AdvancedTargetingType } from '@openx/types/targeting/advancedTargeting';
import type { CustomVariablesData } from '@openx/types/targeting/customVariables';
import { TargetingFields, type TargetingProps } from '@openx/types/targeting/targeting';

import { TargetingDrawer, useDrawer } from '../shared';

import { AdvanceTargetingItems } from './AdvancedTargetingItems';
import { AdvancedTargetingReadOnly } from './AdvancedTargetingReadOnly';
import { AdvancedTargetingTopBox } from './AdvancedTargetingTopBox';
import { defaultState, getCurrentType, getInitialState, isContextualUpdatedCheck, mapContextualToApi } from './helper';
import { useGetContextualSegmentsComponents } from './hooks/useGetContextualSegmentsComponents';

export type AdvancedTargetingMethods = {
  clearContextualSegments: () => void;
};

export const AdvancedTargeting = memo(
  forwardRef(function AdvancedTargeting(
    {
      targetingParams,
      onFieldUpdate = () => {},
      name,
      readonly,
      field,
      isDisabled,
      accountUid = '',
      targetingContext,
    }: TargetingProps<AdvancedTargetingData>,
    ref: Ref<AdvancedTargetingMethods>
  ): JSX.Element {
    const [type, setType] = useState<AdvancedTargetingType | undefined>(getCurrentType(targetingParams));
    const { segmentsV2 } = useGetContextualSegmentsComponents();
    const initState = useMemo(
      () => getInitialState(targetingParams, type, segmentsV2),
      [segmentsV2, targetingParams, type]
    );

    const [params, updateParams] = useState<AdvancedTargetingData>(initState);

    const isContextualUpdated = isContextualUpdatedCheck(initState, params, segmentsV2);

    const isCustomUpdated = useMemo(() => !isEqual(initState, params), [params, initState]);

    const isDataUpdated = useCallback(
      (type?: AdvancedTargetingType) => {
        if (segmentsV2) {
          return isCustomUpdated || isContextualUpdated;
        }

        const data = {
          [TargetingFields.CONTEXTUAL_SEGMENTS]: isContextualUpdated,
          [TargetingFields.CUSTOM_VARIABLES]: isCustomUpdated,
        };

        return type ? data[type] : false;
      },
      [isCustomUpdated, isContextualUpdated, segmentsV2]
    );

    const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
      onApply: () => {
        if (!segmentsV2) {
          const legacyParams = omit(structuredClone(params), 'contextualV2');

          return (
            type &&
            onFieldUpdate(type, {
              ...legacyParams,
              op: legacyParams.op,
              [type]: legacyParams[type],
            } as AdvancedTargetingData)
          );
        }

        type &&
          onFieldUpdate(type, {
            contextual: mapContextualToApi(params),
            contextualV2: params.contextualV2,
            custom: params.custom,
            op: params.op,
          });
      },
      restoreParams: () => updateParams(initState),
    });

    const onRemoveAll = useCallback(() => {
      onFieldUpdate(field, null);
      updateParams(defaultState);
    }, [onFieldUpdate, field]);

    const handleTypeChange = (type: AdvancedTargetingType) => {
      setType(type);

      if (!segmentsV2) {
        updateParams(defaultState);
      }
    };

    const upsertVariable = useCallback(
      (items: CustomVariablesData) => {
        const val = items.val;
        type && updateParams(prevParams => ({ ...prevParams, [type]: val }));
      },
      [type]
    );

    useImperativeHandle(
      ref,
      () => ({
        clearContextualSegments() {
          onRemoveAll();
        },
      }),
      [onRemoveAll]
    );

    return (
      <>
        <AdvancedTargetingReadOnly
          targetingParams={initState.contextual?.length || initState.custom?.length ? initState : null}
          name={name}
          readonly={readonly}
          onOpenDrawerClick={onDrawerOpen}
          onRemoveClick={onRemoveAll}
          isDisabled={isDisabled}
          accountUid={accountUid}
        />

        {isOpen && (
          <TargetingDrawer
            name={name}
            onClose={onDrawerClose}
            onApply={onDrawerApply}
            isDataUpdated={isDataUpdated(type)}
          >
            <AdvancedTargetingTopBox
              items={params}
              onEnter={upsertVariable}
              onTypeChange={handleTypeChange}
              type={type}
              updateParams={updateParams}
              accountUid={accountUid}
            />

            <AdvanceTargetingItems
              params={params}
              updateParams={updateParams}
              onAdd={upsertVariable}
              type={type}
              accountUid={accountUid}
              targetingContext={targetingContext}
            />
          </TargetingDrawer>
        )}
      </>
    );
  })
);
