import { Box } from '@mui/material';
import { uniq } from 'lodash';
import { type ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core/lib/TextField/TextField';
import {
  type ContextualSegmentsDataPayload,
  dropdownOptions,
  segmentIdMaxLength,
  segmentIdsLimit,
  segmentIdsPlaceholder,
  useContextualSegmentsDropdownLabels,
} from '@openx/types/targeting/contextualSegments';
import { ComparisonType, type CriteriaValue } from '@openx/types/targeting/targetingValuesTypes';

import { ComparisonTypeDropdown, DimensionChip, SelectedItems } from '../../../shared';
import { removeNotAllowedSymbols } from '../helpers';

const segmentIdsSpliter = /\n|,|;/;

const StyledHelperTextBox = props => <Box component="span" data-test="helper-text" {...props} />;

interface Props {
  item: ContextualSegmentsDataPayload;
  onChange?: (ctxSegment: ContextualSegmentsDataPayload) => void;
  targetingDimension: CriteriaValue;
  operatorSwitch?: JSX.Element;
  readonly?: boolean;
}

export const SegmentIdsItems = ({
  item,
  operatorSwitch,
  targetingDimension,
  readonly = true,
  onChange = () => {},
}: Props) => {
  const { t } = useTranslation();
  const labels = useContextualSegmentsDropdownLabels();
  const [comparisonType, setComparisonType] = useState<ComparisonType>(dropdownOptions[0]);

  const [segmentIds, setSegmentIds] = useState(item.includes.replaceAll(',', '\n'));
  const [segmentIdsError, setSegmentIdsError] = useState<string | null>(null);

  const handleRemove = (segmentIds: string[]) => {
    setSegmentIds(segmentIds.join('\n'));

    const segments = segmentIds.join(',');
    onChange({
      attr: item.attr,
      excludes: comparisonType === ComparisonType.NOT_INTERSECTS ? segments : item.excludes,
      includes: comparisonType === ComparisonType.INTERSECTS ? segments : item.includes,
    });
  };

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const segmentIdsInput = event.target.value;

      let items = uniq(
        segmentIdsInput
          .split(segmentIdsSpliter)
          .map(value => removeNotAllowedSymbols(value))
          .filter(value => !!value)
      );

      const hasElementsWithEceededMaxLength = items.some(item => item.length > segmentIdMaxLength);
      if (hasElementsWithEceededMaxLength) {
        setSegmentIdsError(t('Length of each ID cannot exceed {limit} characters.', { limit: segmentIdMaxLength }));
        return;
      }

      if (items.length > segmentIdsLimit) {
        setSegmentIdsError(t("Number of segment IDs can't be higher than {limit}.", { limit: segmentIdsLimit }));
        items = items.slice(0, segmentIdsLimit);
      } else {
        setSegmentIdsError('');
      }

      setSegmentIds(segmentIdsInput);

      const segments = items.join(',');
      onChange({
        attr: `${item.attr}`,
        excludes: comparisonType === ComparisonType.NOT_INTERSECTS ? segments : item.excludes,
        includes: comparisonType === ComparisonType.INTERSECTS ? segments : item.includes,
      });
    },
    [t, onChange, comparisonType, item]
  );

  const excludedItems = item.excludes.split(',');
  const includedItems = item.includes.split(',');
  const shouldShowDimension = item.excludes && item.includes;

  return (
    <Box data-test="provider-segment-ids" marginTop={1}>
      {!readonly && (
        <>
          <ComparisonTypeDropdown
            dataTest="contextual-segments-comparison-options"
            labels={labels}
            options={dropdownOptions}
            value={comparisonType}
            sx={{ marginBottom: 1, marginTop: 0 }}
            onChange={(value: ComparisonType) => {
              setComparisonType(value);
              const segments = value === ComparisonType.NOT_INTERSECTS ? item.excludes : item.includes;
              setSegmentIds(segments.replaceAll(',', '\n'));
            }}
          />
          <Box marginBottom={1}>
            <TextField
              data-test="contextual-segments-ids-area-input"
              error={!!segmentIdsError}
              helperText={<StyledHelperTextBox>{segmentIdsError}</StyledHelperTextBox>}
              InputProps={{ disableUnderline: true, inputComponent: 'textarea' }}
              label={t('Segment IDs')}
              rows={9}
              placeholder={t(segmentIdsPlaceholder)}
              value={segmentIds}
              onChange={handleOnChange}
              fullWidth
              multiline
            />
          </Box>
          {shouldShowDimension && operatorSwitch}
        </>
      )}

      {item.includes && (
        <SelectedItems
          dataTest="contains-any-of-segment-ids"
          filtersTitle={t('Contains any of following Segment IDs')}
          selectedItems={includedItems}
          readonly={comparisonType !== ComparisonType.INTERSECTS || readonly}
          handleChange={handleRemove}
          isAddIcon
        />
      )}

      {shouldShowDimension && (
        <DimensionChip targetingDimension={targetingDimension} sx={{ marginBottom: 1, marginLeft: 0, marginTop: 1 }} />
      )}

      {item.excludes && (
        <SelectedItems
          dataTest="contains-none-of-segment-ids"
          filtersTitle={t('Contains none of following Segment IDs')}
          selectedItems={excludedItems}
          readonly={comparisonType !== ComparisonType.NOT_INTERSECTS || readonly}
          handleChange={handleRemove}
          isAddIcon={false}
        />
      )}
    </Box>
  );
};
