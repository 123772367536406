import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  type InventoryAndContentState,
  InventoryContentOption,
  type TargetableInventoryList,
  TargetableInventoryOption,
} from '@openx/types/targeting/inventoryAndContent';

import {
  DimensionChipLine,
  FiltersTitle,
  SelectedItems,
  Switch,
  getInventoryDisplayName,
  setInventories,
} from '../../shared';
import type { TargetingItemsProps } from '../../types';

import { ExcludeInventorySubsetItems } from './ExcludeInventorySubsetItems';

interface contentItemsProps extends TargetingItemsProps<InventoryAndContentState> {
  isInventorySelected?: boolean;
}

export const InventoryItems = ({
  readonly = true,
  dispatchInventory = () => {},
  targetingParams,
  instanceState,
  isInventorySelected,
  isNotLastItem,
  switchState: chipLineSwitchState,
}: contentItemsProps) => {
  const { t } = useTranslation();

  const includes = targetingParams[InventoryContentOption.INVENTORY].includes;
  const excludes = targetingParams[InventoryContentOption.INVENTORY].excludes;
  const inventoriesCount = [...includes, ...excludes].length;
  const switchState = !includes.length && !!excludes.length;

  const handleChange = useCallback(
    (targetableInventoryOption: TargetableInventoryOption, filteredItems: TargetableInventoryList) => {
      if (targetableInventoryOption === TargetableInventoryOption.INCLUDES) {
        const filteredItemsNamesSet = new Set(filteredItems.map(item => item.name));

        const filteredExcludes = excludes.filter(item => filteredItemsNamesSet.has('' + item.parent_name));

        dispatchInventory({
          payload: filteredExcludes,
          type: setInventories[TargetableInventoryOption.EXCLUDES],
        });
      }

      dispatchInventory({
        payload: filteredItems,
        type: setInventories[targetableInventoryOption],
      });
    },
    [dispatchInventory, excludes]
  );

  const handleSwitchChange = useCallback(() => {
    const includesPayload = switchState ? excludes : [];
    const excludesPayload = switchState ? [] : includes;

    dispatchInventory({
      payload: includesPayload,
      type: setInventories[TargetableInventoryOption.INCLUDES],
    });
    dispatchInventory({
      payload: excludesPayload,
      type: setInventories[TargetableInventoryOption.EXCLUDES],
    });
  }, [dispatchInventory, excludes, includes, switchState]);

  const handleOnClear = useCallback(() => {
    dispatchInventory({
      payload: [],
      type: setInventories[TargetableInventoryOption.INCLUDES],
    });
    dispatchInventory({
      payload: [],
      type: setInventories[TargetableInventoryOption.EXCLUDES],
    });
  }, [dispatchInventory]);

  const onInventoryRemove = useCallback(
    (itemNames: TargetableInventoryList) => {
      handleChange(switchState ? TargetableInventoryOption.EXCLUDES : TargetableInventoryOption.INCLUDES, itemNames);
    },
    [handleChange, switchState]
  );

  return (
    <Grid>
      {inventoriesCount ? (
        <div data-test="sub-section">
          <FiltersTitle
            onClear={() => handleOnClear()}
            title={t('Inventory [ {count} ]', {
              count: inventoriesCount,
            })}
            readonly={readonly}
          />
          {!readonly && (
            <Switch
              switchState={switchState}
              onSwitchChange={() => handleSwitchChange()}
              switchOffLabel={t('include')}
              switchOnLabel={t('exclude')}
              groupTitle={t('the following:')}
            />
          )}
          <Grid data-test="section-items">
            <SelectedItems
              filtersTitle={switchState ? t('Excluded') : t('Included')}
              isAddIcon={!switchState}
              selectedItems={switchState ? excludes : includes}
              readonly={readonly}
              handleChange={onInventoryRemove}
              getItemLabel={getInventoryDisplayName}
              isRemovingAll={true}
              dataTest={'inventory'}
            />
            <ExcludeInventorySubsetItems
              readonly={readonly}
              dispatchInventory={dispatchInventory}
              targetingParams={targetingParams}
              instanceState={instanceState}
              isInventorySelected={isInventorySelected}
            />

            {isNotLastItem && <DimensionChipLine switchState={chipLineSwitchState} />}
          </Grid>
        </div>
      ) : null}
    </Grid>
  );
};
