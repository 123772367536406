import type { CustomVariablesDataPayload } from './customVariables';
import { TargetingFields } from './targeting';
import type { ComparisonType, CriteriaValue } from './targetingValuesTypes';

export const AdvancedTargetingTypeLabel = {
  [TargetingFields.CUSTOM_VARIABLES]: 'Custom Variables',
  [TargetingFields.CONTEXTUAL_SEGMENTS]: 'Contextual Segments',
} as const;
export type AdvancedTargetingType = keyof typeof AdvancedTargetingTypeLabel;

export type AdvancedTargetingData = {
  op: CriteriaValue;
  contextual: CustomVariablesDataPayload[];
  custom: CustomVariablesDataPayload[];
  contextualV2: ContextualSegmentsV2FormState;
};

export type ContextualSegmentsV2FormState = {
  attr: string;
  includes: string[];
  excludes: string[];
  op: ComparisonType.INTERSECTS | ComparisonType.NOT_INTERSECTS;
};
