import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { copyToClipboard } from '@openx/utils/lib/copyToClipboard';

import { Button } from '../../../../Button/Button';
import type { InvalidItemsError } from '../../types';

const StyledErrorsContainerDiv = styled('div')`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledErrorTitleTypography = styled(Typography)`
  color: #d83158;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

const StyledInvalidItemsTypography = styled(Typography)`
  border: 1px solid #d83158;
  height: 88px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  overflow-y: scroll;
  padding: ${({ theme }) => theme.spacing(0.25, 1.5)};
` as typeof Typography;

interface ErrorDetailsProps {
  errors: InvalidItemsError[];
}

export function ErrorDetails({ errors }: ErrorDetailsProps) {
  const { t } = useTranslation();

  return (
    <>
      {errors.map(({ error, invalidItems }) => {
        const invalidItemsText = invalidItems.join('\n');

        return (
          <StyledErrorsContainerDiv key={error} data-test="bulk-invalid-items">
            <StyledErrorTitleTypography variant="caption" data-test="bulk-error">
              {error}
            </StyledErrorTitleTypography>
            <StyledInvalidItemsTypography variant="body1" component="pre">
              {invalidItemsText}
            </StyledInvalidItemsTypography>
            <Button type="button" onClick={() => copyToClipboard(invalidItemsText)}>
              {t('Copy to Clipboard')}
            </Button>
          </StyledErrorsContainerDiv>
        );
      })}
    </>
  );
}
