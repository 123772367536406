import { PageHeader, Tags } from 'components';
import { isEqual } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { TopBar } from '@openx/components/table/ChipFilters/TopBar';

import { TopInfoPanel } from 'components/TopInfoPanel';
import type { DealLibraryItem } from 'types';

import { DealLibraryListTable } from './components';
import { StyledPaper, StyledTableContainer, StyledTopBarContainer } from './components/styled';
import { useGetActionCallbacks, useGetCriteria, useMappedDealTemplates } from './hooks';
import { ListActions, dispatchListData, listReducer } from './utils';

export type DealLibraryListProps = {
  dealTemplates: DealLibraryItem[];
  popularTags: string[];
  isLoading: boolean;
  setQueryParamsForBackButton: Dispatch<SetStateAction<string>>;
};

export const DealLibraryList = ({
  dealTemplates,
  popularTags,
  isLoading,
  setQueryParamsForBackButton,
}: DealLibraryListProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const urlCriteria = useGetCriteria(dealTemplates, searchParams);
  const mappedDealTemplates = useMappedDealTemplates(dealTemplates);
  const [state, dispatch] = useReducer(listReducer, {
    criteria: urlCriteria,
    dealTemplates: mappedDealTemplates,
    loadingList: true,
  });

  const { criteria: stateCriteria, loadingList, dealTemplates: stateDealTemplates } = state;
  const phrase = stateCriteria.phrase;

  const { onCriteriaChange, onSearchChange } = useGetActionCallbacks(
    dispatch,
    setSearchParams,
    setQueryParamsForBackButton,
    location
  );
  const isFirstRender = useRef(true);

  const shouldDispatchListData = useMemo(
    () => !isEqual(urlCriteria, stateCriteria) || isFirstRender.current,
    [urlCriteria, stateCriteria]
  );

  useEffect(() => {
    if (shouldDispatchListData) {
      dispatch({ type: ListActions.SET_LOADING });
      dispatchListData(
        {
          criteria: urlCriteria,
          dealTemplates: mappedDealTemplates,
        },
        dispatch
      );
      setQueryParamsForBackButton(location.search);
      isFirstRender.current = false;
    }
  }, [urlCriteria, location.search, mappedDealTemplates, setQueryParamsForBackButton, shouldDispatchListData]);

  /**
   * Scroll to the top of the page when the component mounts.
   * This is essential when returning from the details page which has been scrolled down,
   * ensuring a consistent and optimal user experience by presenting the page starting from the top.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledTableContainer>
      <TopInfoPanel />
      <PageHeader
        title="Cookieless Deal Library"
        subtitle="Explore OpenX's diverse selection of cookieless, data-targeted, and curated auction packages."
      >
        <StyledTopBarContainer>
          <TopBar
            filters={{
              phrase,
            }}
            onChange={onSearchChange}
            searchText="Search for a Deal"
          />
        </StyledTopBarContainer>
        <StyledPaper data-test="popular-tags-container">
          <Tags tags={popularTags} handleTagClick={onSearchChange} phrase={phrase} filled />
        </StyledPaper>
      </PageHeader>

      <DealLibraryListTable
        dealTemplates={stateDealTemplates}
        isLoading={isLoading || loadingList}
        phrase={phrase}
        criteria={stateCriteria}
        onSearchChange={onSearchChange}
        onCriteriaChange={onCriteriaChange}
      />
    </StyledTableContainer>
  );
};
