export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortCriteria {
  column: string;
  direction: SortDirection;
  valuePath?: Record<string, string[]>;
  sortableColumns: string[];
}

export interface PaginationCriteria {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  hasMore?: boolean;
}

export enum CriteriaDimension {
  FILTERS = 'filters',
  SORT = 'sort',
  PAGINATION = 'pagination',
}

export type TableCriteria<
  T extends CriteriaDimension,
  FiltersT extends Record<string, unknown> = {},
> = T extends CriteriaDimension.SORT
  ? SortCriteria
  : T extends CriteriaDimension.PAGINATION
    ? PaginationCriteria
    : T extends CriteriaDimension.FILTERS
      ? FiltersT
      : never;

export type AllTableCriteria<FiltersT extends Record<string, unknown> = {}> = {
  [dimension in CriteriaDimension]?: TableCriteria<dimension, FiltersT>;
};

export interface CriteriaChange<D extends CriteriaDimension, FiltersT extends Record<string, unknown> = {}> {
  dimension: D;
  value?: TableCriteria<D, FiltersT>;
}

export type ChangeCriteriaHandler<
  D extends CriteriaDimension = CriteriaDimension,
  FiltersT extends Record<string, unknown> = {},
> = (change: CriteriaChange<D, FiltersT>) => void;

export type ChangeSingleCriteriaAction<T extends CriteriaDimension, FiltersT extends Record<string, unknown> = {}> = (
  criteria: TableCriteria<T, FiltersT>
) => void;
