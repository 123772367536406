import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { InventoryContentOption } from '@openx/types/targeting/inventoryAndContent';
import { ComparisonType } from '@openx/types/targeting/targetingValuesTypes';

type InventoryComparisonTypeDropdownProps = {
  onChange: (action: ComparisonType) => void;
  value: ComparisonType | null;
  option: InventoryContentOption;
};

const comparisonOptions = {
  [InventoryContentOption.ADUNIT]: [ComparisonType.EQUALS, ComparisonType.DOES_NOT_EQUAL],
  [InventoryContentOption.APP_BUNDLE_ID]: [
    ComparisonType.EQUALS,
    ComparisonType.DOES_NOT_EQUAL,
    ComparisonType.BEGINS_WITH,
    ComparisonType.DOES_NOT_BEGIN_WITH,
  ],
  [InventoryContentOption.PAGE_URL]: [
    ComparisonType.EQUALS,
    ComparisonType.DOES_NOT_EQUAL,
    ComparisonType.CONTAINS,
    ComparisonType.DOES_NOT_CONTAIN,
    ComparisonType.BEGINS_WITH,
    ComparisonType.DOES_NOT_BEGIN_WITH,
    ComparisonType.ENDS_WITH,
    ComparisonType.DOES_NOT_END_WITH,
  ],
  [InventoryContentOption.SITE]: [ComparisonType.EQUALS, ComparisonType.DOES_NOT_EQUAL],
};

const ifComparisonOptionDoesntExist = (value: ComparisonType | null, option: InventoryContentOption): boolean =>
  !comparisonOptions[option].includes(value);

export const InventoryComparisonTypeDropdown = ({ onChange, value, option }: InventoryComparisonTypeDropdownProps) => {
  const { t } = useTranslation();

  const labels = {
    [ComparisonType.EQUALS]: t('Equals'),
    [ComparisonType.DOES_NOT_EQUAL]: t('Does not equal'),
    [ComparisonType.ENDS_WITH]: t('Ends with'),
    [ComparisonType.CONTAINS]: t('Contains'),
    [ComparisonType.BEGINS_WITH]: t('Begins with'),
    [ComparisonType.DOES_NOT_END_WITH]: t('Does not end with'),
    [ComparisonType.DOES_NOT_CONTAIN]: t('Does not contain'),
    [ComparisonType.DOES_NOT_BEGIN_WITH]: t('Does not begin with'),
  };

  useEffect(() => {
    ifComparisonOptionDoesntExist(value, option) && onChange(comparisonOptions[option][0]);
  }, [value, onChange, option]);

  return (
    <AutoComplete
      options={comparisonOptions[option]}
      renderOptions={{
        dataTest: 'comparsion-option',
      }}
      getOptionLabel={(option: ComparisonType) => labels[option]}
      textFieldReadOnly
      disableClearable
      onChange={(_e, value) => onChange(value)}
      value={ifComparisonOptionDoesntExist(value, option) ? comparisonOptions[option][0] : value}
      data-test="inventory-comparison-options"
    />
  );
};
