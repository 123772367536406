import { Grid } from '@mui/material';
import type { ReactNode } from 'react';

import { Item } from './Item';
import { StyledArrowForward, StyledIconButton } from './styled/TreeItem.styled';

type TreeItemProps<ItemType> = {
  readonly: boolean;
  testId?: string;
  onRemove: (id: ItemType) => void;
  item: ItemType;
  children?: ReactNode;
};

export function TreeItem<ItemType>({
  readonly,
  onRemove,
  item,
  testId,
  children,
}: TreeItemProps<ItemType>): JSX.Element {
  return (
    <Item testId={testId} readonly={readonly} item={item} onRemove={onRemove}>
      <Grid>
        <StyledIconButton key="arrowExpand" aria-label="arrowExpand" data-expandicon="expandRow" disabled size="large">
          <StyledArrowForward fontSize="small" data-expandicon="expandRow" />
        </StyledIconButton>
        {children}
      </Grid>
    </Item>
  );
}
