import DragHandleIcon from '@mui/icons-material/DragHandle';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Checkbox } from '@openx/components/core/lib/Checkbox/Checkbox';
import type { BaseRow, Columns, TableColumn } from '@openx/types/tableHelpers';

const StyledMenuItem = styled(MenuItem)`
  column-gap: 12px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-right: 0;
`;

const StyledDragHandleIcon = styled(DragHandleIcon)`
  cursor: grab;
`;

export interface ColumnFilterProps<RowT extends BaseRow> {
  columns: Columns<RowT>;
  handleFilterColumn: (column: TableColumn<RowT>) => void;
  updateColumns: (columns: Columns<RowT>) => void;
  updateLocalStorage: (columns: Columns<RowT>) => void;
}

const ColumnFilter = <RowT extends BaseRow>({
  handleFilterColumn,
  updateColumns,
  updateLocalStorage,
  columns,
}: ColumnFilterProps<RowT>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selectColumn = useMemo(() => columns.find(col => col.key === 'select'), [columns]);

  const columnsWithId = useMemo(
    () =>
      selectColumn
        ? columns
            .map(column => {
              return { id: column.key, ...column };
            })
            .filter(col => col.key !== 'select')
        : columns.map(column => {
            return { id: column.key, ...column };
          }),
    [selectColumn, columns]
  );

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <ViewColumnIcon data-test="filter-column-icon" />
      </IconButton>
      <Menu
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ReactSortable
          list={columnsWithId}
          setList={columns => {
            const removeColumnId = columns.map(({ id, ...props }) => props);
            if (selectColumn) {
              removeColumnId.unshift(selectColumn);
            }

            updateColumns(removeColumnId);
            updateLocalStorage(removeColumnId);
          }}
          swapThreshold={0.65}
          animation={300}
          handle=".MuiSvgIcon-root"
        >
          {columnsWithId.map(column => (
            <StyledMenuItem
              data-test={`filter-${column.title}`}
              key={column.id}
              onClick={() => {
                !column.disabled && handleFilterColumn(column);
              }}
            >
              <StyledCheckbox data-test="filter-checkbox" checked={!column.hide} disabled={!!column.disabled} />
              <ListItemText primary={column.title} data-test="filter-column-name" />
              <StyledDragHandleIcon data-test="filter-drag-icon" />
            </StyledMenuItem>
          ))}
        </ReactSortable>
      </Menu>
    </>
  );
};

export default ColumnFilter;
