import type React from 'react';
import { createContext, useContext, useMemo } from 'react';

import type { TargetingProviderConfig } from '@openx/types/targeting/targetingProviderConfig';

export interface TargetingProviderProps {
  children: React.ReactNode;
  targetingConfig: TargetingProviderConfig;
}

export type TargetingContext = TargetingProviderConfig;

const targetingContext = createContext<TargetingContext>({
  isOa: false,
  useAccountFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAccountOptionsFetch: () => {
    throw new Error('useAccountOptionsFetch: Not implemented. Ensure you are inside the DealContext');
  },
  useAccountUid: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAdPlacementOptions: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAudienceOptionsDetailsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useAudienceOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCategoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useContentObjectOptionsConfig: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCountriesOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useCurrentInstanceState: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useDistributionChannelOptions: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchByGeoData: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchBySearchText: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoriesBySearchText: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoryInstances: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchInventoryMarket: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useFetchSegments: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useInventoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useKeywordsOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useLocationSourceOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useMetacategoriesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  usePmpSizesFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  usePostalCodes: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useRelatedAccountsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useSelectRateOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useTechnologyOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useTrafficFilterEidsOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
  useVideoOptionsFetch: () => {
    throw new Error('Not implemented. Ensure you are inside the TargetingContext');
  },
});

export const useTargetingContext = () => useContext(targetingContext);

export function TargetingProvider({ children, targetingConfig }: TargetingProviderProps) {
  const value: TargetingContext = useMemo(
    () => ({
      ...targetingConfig,
    }),
    [targetingConfig]
  );

  return <targetingContext.Provider value={value}>{children}</targetingContext.Provider>;
}
