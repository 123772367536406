import { Grid } from '@mui/material';

import type { TechnologyAndDevicesOption, UserAgentPayload } from '@openx/types/targeting/technologyAndDevices';

import { DrawerTopBox } from '../shared';

import { TopBoxValueField, TypeOptionDropdown } from './TechnologyAndDevicesTopBox';

type TopBoxProps = {
  items: Set<string> | UserAgentPayload;
  onChange: (options: Set<string> | UserAgentPayload) => void;
  selectedType: TechnologyAndDevicesOption;
  onTypeChange: (type: TechnologyAndDevicesOption) => void;
};

export const TopBox = ({ items, onChange, selectedType, onTypeChange }: TopBoxProps): JSX.Element => {
  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems={'flex-end'}>
        <Grid item xs={3}>
          <TypeOptionDropdown onChange={onTypeChange} value={selectedType} />
        </Grid>

        <Grid item xs={9}>
          <TopBoxValueField items={items} onChange={onChange} selectedType={selectedType} />
        </Grid>
      </Grid>
    </DrawerTopBox>
  );
};
