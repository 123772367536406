import {
  type TechnologyAndDevicesFormState,
  technologyAndDevicesOptions,
} from '@openx/types/targeting/technologyAndDevices';

export function isEmptyState(state?: TechnologyAndDevicesFormState | null): boolean {
  if (!state) return true;

  return technologyAndDevicesOptions.every(type => {
    const value = state[type].val;
    if (value instanceof Set) return value.size === 0;

    return !Object.keys(value).length;
  });
}
