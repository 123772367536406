import { TableContainer } from '@mui/material';
import { useMemo } from 'react';

import { Paper } from '@openx/components/core/lib/Paper/Paper';
import { Table } from '@openx/components/table/Table';
import type { AllTableCriteria, CriteriaChange, CriteriaDimension } from '@openx/types/tableCriteria';
import type { TableColumn } from '@openx/types/tableHelpers';

import type { DealLibraryItem } from 'types';

import { ColumnKey, ColumnTitle } from '../constants';
import { useDealLibraryRowActions } from '../hooks';

import { DeviceTypeCellContainer, NameCellContainer, TextCellContainer } from './TableCells';

type DealLibraryListTableProps = {
  dealTemplates: DealLibraryItem[];
  isLoading: boolean;
  phrase: string;
  criteria: AllTableCriteria;
  onSearchChange: (filters: { phrase: string }) => void;
  onCriteriaChange: (change: CriteriaChange<CriteriaDimension>) => void;
};

export const DealLibraryListTable = ({
  dealTemplates,
  isLoading,
  phrase,
  criteria,
  onSearchChange,
  onCriteriaChange,
}: DealLibraryListTableProps): JSX.Element => {
  const dealLibraryRowActionsCopy = useDealLibraryRowActions();

  const columnDefs: TableColumn<DealLibraryItem>[] = useMemo(
    () => [
      {
        key: ColumnKey.NAME,
        render: ({ tags, id, name, description }) =>
          NameCellContainer({ description, id, name, tags }, phrase, onSearchChange),
        title: ColumnTitle.NAME,
        width: '40%',
      },
      {
        key: ColumnKey.DEVICE_TYPE,
        render: ({ device_type }) => DeviceTypeCellContainer({ device_type }),
        title: ColumnTitle.DEVICE_TYPE,
        width: '10%',
      },
      {
        key: ColumnKey.DISTRIBUTION_CHANNEL,
        render: ({ distribution_channel }) =>
          TextCellContainer({ displayValue: distribution_channel, type: ColumnKey.DISTRIBUTION_CHANNEL }),

        title: ColumnTitle.DISTRIBUTION_CHANNEL,
        width: '10%',
      },
      {
        key: ColumnKey.AD_PLACEMENT,
        render: ({ ad_placement }) => TextCellContainer({ displayValue: ad_placement, type: ColumnKey.AD_PLACEMENT }),
        title: ColumnTitle.AD_PLACEMENT,
        width: '10%',
      },
    ],
    [onSearchChange, phrase]
  );

  return (
    <Paper padding="none">
      <TableContainer>
        <Table
          data={dealTemplates}
          loading={isLoading}
          columns={columnDefs}
          enableTableHeaderCustomization={false}
          primaryRowAction={dealLibraryRowActionsCopy.CREATE_DEAL}
          criteria={criteria}
          onCriteriaChange={onCriteriaChange}
        />
      </TableContainer>
    </Paper>
  );
};
