import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import type { OptionsMap } from '@openx/types/options';
import type { TechnologyAndDevicesOption } from '@openx/types/targeting/technologyAndDevices';

import { OptionsDropdown } from '../../shared';
import { type MobileCarrierOption, MobileCarrierOptions, MobileCarrierWifiOption } from '../constants';
import { getMobileCarrierLabelsMap } from '../utils';

type MobileCarrierDropdownProps = {
  loading: boolean;
  selectedOptions: Set<string>;
  placeholder: string;
  options: OptionsMap;
  onChange: (options: Set<string>, op?: string) => void;
  selectedType: TechnologyAndDevicesOption;
};

export const MobileCarrierDropdown = ({
  loading,
  selectedOptions,
  placeholder,
  options,
  onChange,
}: MobileCarrierDropdownProps) => {
  const { t } = useTranslation();
  const opOptionsLabels = getMobileCarrierLabelsMap(t);
  const [opValue, setOpValue] = useState<MobileCarrierOption>(MobileCarrierOptions.isAnyOf);

  const handleChange = useCallback(
    (val: Set<string>) => {
      onChange(val);
    },
    [onChange]
  );

  const handleOpChange = useCallback(
    (val: MobileCarrierOption) => {
      setOpValue(val);

      if (val === MobileCarrierOptions.isWifiOnly) {
        onChange(new Set([MobileCarrierOptions.isWifiOnly]), MobileCarrierWifiOption.wifi);
        return;
      }

      onChange(selectedOptions, val);
    },
    [selectedOptions, onChange]
  );

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={3}>
        <AutoComplete
          data-test="mobile-carrier-options"
          onChange={(_, val) => val && handleOpChange(val)}
          getOptionLabel={option => opOptionsLabels[option]?.name ?? option}
          options={Object.values(MobileCarrierOptions)}
          value={opValue}
          disableClearable
        />
      </Grid>

      <Grid item xs={9}>
        {opValue !== MobileCarrierOptions.isWifiOnly && (
          <OptionsDropdown
            loading={loading}
            options={options}
            onChange={handleChange}
            selectedOptions={selectedOptions}
            placeholder={placeholder}
            dataTest="mobile-carrier-value"
          />
        )}
      </Grid>
    </Grid>
  );
};
