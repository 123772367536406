import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import type { OptionsType } from '@openx/types/options';

import { useTargetingContext } from '../../../utils/context/TargetingContext';
import { DrawerTopBox } from '../TargetingDrawer';

type TopBoxProps = {
  value: string | null;
  onChange: (val: string | null) => void;
  placeholder: string;
  optionsFetch: OptionsType.VIEWABILITY | OptionsType.VIEW_THROUGH_RATE;
};

const StyledGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const TopBox = ({ value, onChange, placeholder, optionsFetch }: TopBoxProps) => {
  const { t } = useTranslation();
  const { useSelectRateOptionsFetch } = useTargetingContext();
  const { isSelectRateLoading, selectRate: options, optionsList } = useSelectRateOptionsFetch(optionsFetch);

  return (
    <DrawerTopBox sx={{ paddingBottom: theme => theme.spacing(1) }}>
      <StyledGrid container spacing={1} alignItems="flex-end">
        <AutoComplete
          textFieldProps={{ label: t(placeholder), placeholder: t(`[ Select ${placeholder} ]`) }}
          options={optionsList}
          fullWidth
          loading={isSelectRateLoading}
          renderOptions={{
            dataTest: 'type-option',
            tooltip: true,
          }}
          getOptionLabel={option => options[option]?.name || option}
          onChange={(_e, value) => (value ? onChange(value) : onChange(null))}
          value={isSelectRateLoading ? '' : value}
          textFieldReadOnly
          data-test="select-rate-input"
        />
      </StyledGrid>
    </DrawerTopBox>
  );
};
