import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType } from '@openx/types/options';
import type { MetacategoryOptionsMap } from '@openx/types/targeting/metacategory';
import { useOptionsFetch } from '@openx/utils/apiHooks/generics/useOptionsFetch';

import { prepareMetacategoryOptions } from '../../Targeting/Metacategories/utils';

export function useMetacategoriesFetch() {
  const { t } = useTranslation();
  const {
    isLoading: areMetacategoriesLoading,
    data: metacategories,
    refetch: refetchMetacategories,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch Metacategories'),
    optionType: OptionsType.METACATEGORIES,
    queryOptions: { enabled: false },
  });

  const preparedCategories = useMemo(
    () => prepareMetacategoryOptions(metacategories as MetacategoryOptionsMap),
    [metacategories]
  );

  return {
    areMetacategoriesLoading,
    metacategories: preparedCategories,
    refetchMetacategories,
  };
}
