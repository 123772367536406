import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ReactQueryCacheTime, ReactQueryStaleTime } from '@openx/constants/react-query';
import { QueryKeys } from '@openx/utils/api/queryKeys';

import type { DealLibraryItem } from 'types';

export type DealTemplateJson = {
  deal_templates: DealLibraryItem[];
  popular_tags: string[];
};

axios.interceptors.request.use(function (config) {
  config.headers['X-OPENX-UI-CLIENT'] = 'deal-library';

  return config;
});

const dealLibraryList = async () => {
  const response = await axios.get<DealTemplateJson>('/deal-library');

  return response.data;
};

export const useDealLibraryListFetch = () => {
  const { data, isLoading, isError } = useQuery({
    gcTime: ReactQueryCacheTime.MINUTES_90,
    queryFn: dealLibraryList,
    queryKey: [QueryKeys.DEAL_LIBRARY],
    staleTime: ReactQueryStaleTime.MINUTES_60,
  });

  const dealTemplates = data?.deal_templates || [];
  const updatedDealTemplates = dealTemplates.map(template => ({
    ...template,
    account_uid: 'deal-library-account-uid',
  }));

  return {
    dealTemplates: updatedDealTemplates,
    isError,
    isLoading,
    popularTags: data?.popular_tags ?? [],
  };
};
