import { Grid, styled } from '@mui/material';
import { useEffect } from 'react';

import { type AudienceData, AudienceOption } from '@openx/types/targeting/audience';

import { useTargetingContext } from '../../utils';
import { useCheckTargetingLocation } from '../Geographic';
import { DrawerTopBox } from '../shared';

import { TopBoxValueField } from './TopBoxValueField'; // Make sure this path is correct
import { OaCreateNewAudienceButton, TypeOptionDropdown } from './components';

const StyledContainerGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

type TopBoxProps = {
  accountUid: string;
  onChange: (value?: string | Set<string>) => void;
  onChangeMulti: (values: Set<string>) => void;
  selectedType: AudienceOption;
  onTypeChange: (type: AudienceOption) => void;
  params: AudienceData | null;
};

export const TopBox = ({ accountUid, onChange, onChangeMulti, selectedType, onTypeChange, params }: TopBoxProps) => {
  const { useAccountOptionsFetch, useAudienceOptionsFetch, isOa } = useTargetingContext();
  const { options, isLoading, refetch } = useAudienceOptionsFetch(
    accountUid,
    selectedType,
    isOa
      ? {
          useCheckTargetingLocation,
        }
      : undefined
  );

  useEffect(() => {
    if (refetch && (selectedType === AudienceOption.DMP_SEGMENTS || selectedType === AudienceOption.OPEN_AUDIENCES)) {
      refetch();
    }
  }, [refetch, selectedType]);

  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems={'flex-end'}>
        {!isOa && (
          <Grid item xs={3}>
            <TypeOptionDropdown onChange={onTypeChange} value={selectedType} />
          </Grid>
        )}
        <StyledContainerGrid container spacing={1} item xs>
          <Grid item xs={12}>
            <TopBoxValueField
              selectedType={selectedType}
              params={params}
              onChange={onChange}
              onChangeMulti={onChangeMulti}
              accountUid={accountUid}
              options={options}
              isLoading={isLoading}
            />
          </Grid>
        </StyledContainerGrid>
        {isOa && (
          <Grid item>
            <OaCreateNewAudienceButton useAccountOptionsFetch={useAccountOptionsFetch} />
          </Grid>
        )}
      </Grid>
    </DrawerTopBox>
  );
};
