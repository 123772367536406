import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch as MuiSwitch,
  type SwitchProps as MuiSwitchProps,
} from '@mui/material';

export type SwitchFieldProps = Partial<MuiSwitchProps> & {
  'data-test'?: string;
  label?: string | JSX.Element;
  message?: string;
  error?: boolean;
  margin?: 'dense' | 'normal' | 'none';
};

export function SwitchField(props: SwitchFieldProps): JSX.Element {
  const { label, error, message, 'data-test': dataTest, margin = 'none', ...withoutCustomProps } = props;

  const defaultProps = {
    color: 'primary',
    disableRipple: true,
  } as Partial<SwitchFieldProps>;

  return (
    <FormControl error={error} data-test={dataTest} margin={margin}>
      <FormControlLabel control={<MuiSwitch {...defaultProps} {...withoutCustomProps} />} label={label || ''} />
      {message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
}
