import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainerGrid = styled(Grid)`
  margin: ${({ theme }) => theme.spacing(3, -2.5, 0)};
  padding: ${({ theme }) => theme.spacing(0, 2.5)};
  width: auto;

  &:hover {
    background-color: #f5f5f5;

    .clearIcon {
      visibility: visible;
    }
  }
`;

const StyledRemoveIconButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  visibility: hidden;
`;

const StyledTitleTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  text-transform: uppercase;
`;

interface SectionTitleProps {
  onClear?: () => void;
  title: string;
  readonly?: boolean;
}

export function FiltersTitle(props: SectionTitleProps) {
  const { onClear, title, readonly } = props;

  return (
    <StyledContainerGrid
      container
      alignItems="center"
      data-test={`section-${title.toLowerCase().replace(/\s/g, '-').split('-[')[0]}`}
    >
      <StyledTitleTypography
        variant="body1"
        data-test={`targeting-${title.replace(/\s/g, '-').split('-[')[0].toLocaleLowerCase().replaceAll(' ', '-')}`}
      >
        {title}
      </StyledTitleTypography>
      {!readonly && (
        <StyledRemoveIconButton className="clearIcon" onClick={onClear} size="large" data-test="close-button">
          <CloseIcon data-test="close-dialog" fontSize="small" />
        </StyledRemoveIconButton>
      )}
    </StyledContainerGrid>
  );
}
