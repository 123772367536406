import { Grid } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ACL_TYPE } from '@openx/types/aclType';
import {
  type InventoryAndContentState,
  InventoryContentOption,
  TargetableInventoryOption,
} from '@openx/types/targeting/inventoryAndContent';
import { usePermissionContext } from '@openx/utils/permission/context/PermissionContext';
import { isAllowedByCompiledAcl } from '@openx/utils/permission/validators';

import { useTargetingContext } from '../../../utils/context/TargetingContext';
import { GROUPED_ITEMS_ROWS_LIMIT, ITEMS_CHARACTER_LIMIT } from '../../constants';
import { ExcludeItems, ExcludeItemsBox, Item, ShowHideButton, TreeItem } from '../../shared';
import { setInventories } from '../../shared/InventoryAndContent/inventoryHelpers';
import { getGroupedLimit, getInventoryDisplayName, groupExcludes } from '../../shared/InventoryAndContent/utils';
import { getDisplayLimit } from '../../shared/SelectedItems/utils';
import type { TargetingItemsProps } from '../../types';
import { InventoryAutoComplete } from '../InventoryAndContentTopBox/InventoryAutoComplete/InventoryAutoComplete';

export const ExcludeInventorySubsetItems = ({
  targetingParams,
  readonly = true,
  dispatchInventory = () => {},
  instanceState,
  isInventorySelected,
}: TargetingItemsProps<InventoryAndContentState>) => {
  const { t } = useTranslation();
  const [isDisplayLimit, setIsDisplayLimit] = useState<boolean>(true);
  const { useAccountUid } = useTargetingContext();
  const accountUid = useAccountUid();
  const { useIsAllowed } = usePermissionContext();
  const { isAllowed } = useIsAllowed();
  const isRoot = isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE));

  const { includes, excludes } = targetingParams[InventoryContentOption.INVENTORY];
  const { grouped, other } = groupExcludes(excludes);

  const showExcludeItemBox = isRoot
    ? isInventorySelected && !readonly && !!includes.length
    : !readonly && !!includes.length;

  const { groupedDisplayLimit, groupedRowsUsed, isMoreGroupedToDisplay } = useMemo(
    () => getGroupedLimit(grouped, GROUPED_ITEMS_ROWS_LIMIT),
    [grouped]
  );

  const otherLabels = useMemo(() => other.map(inventory => getInventoryDisplayName(inventory)), [other]);
  const otherCharacterLimit = useMemo(
    () => (GROUPED_ITEMS_ROWS_LIMIT - groupedRowsUsed) * (ITEMS_CHARACTER_LIMIT / GROUPED_ITEMS_ROWS_LIMIT),
    [groupedRowsUsed]
  );

  const otherDisplayLimit = useMemo(
    () => (otherCharacterLimit > 0 ? getDisplayLimit(Object.values(otherLabels), otherCharacterLimit) : 0),
    [otherCharacterLimit, otherLabels]
  );

  const displayButton = useMemo(
    () => isMoreGroupedToDisplay || other.length > otherDisplayLimit,
    [isMoreGroupedToDisplay, other.length, otherDisplayLimit]
  );

  const notDisplayedCount = useMemo(() => {
    if (!displayButton) {
      return 0;
    }
    return isMoreGroupedToDisplay
      ? other.length + Object.keys(grouped).length - groupedDisplayLimit
      : other.length - otherDisplayLimit;
  }, [displayButton, grouped, groupedDisplayLimit, isMoreGroupedToDisplay, other.length, otherDisplayLimit]);

  const slicedGrouped = useMemo(
    () =>
      isDisplayLimit && isMoreGroupedToDisplay
        ? Object.fromEntries(Object.entries(grouped).slice(0, groupedDisplayLimit))
        : grouped,
    [grouped, groupedDisplayLimit, isDisplayLimit, isMoreGroupedToDisplay]
  );

  const slicedOther = useMemo(
    () => (isDisplayLimit ? other.slice(0, otherDisplayLimit) : other),
    [isDisplayLimit, other, otherDisplayLimit]
  );

  const onInventorySelect = useCallback(
    item => {
      dispatchInventory({
        payload: [...targetingParams[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES], item],
        type: setInventories[TargetableInventoryOption.EXCLUDES],
      });
    },
    [dispatchInventory, targetingParams]
  );

  const onItemDelete = useCallback(
    (itemId: string) => {
      dispatchInventory({
        payload: targetingParams[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES].filter(
          existingItem => !(itemId === existingItem.id)
        ),
        type: setInventories[TargetableInventoryOption.EXCLUDES],
      });
    },
    [dispatchInventory, targetingParams]
  );

  const onParentDelete = useCallback(
    (key: string) => {
      const removeIds = new Set<string>();

      slicedGrouped[key].forEach(item => {
        removeIds.add(item.id);
      });

      dispatchInventory({
        payload: targetingParams[InventoryContentOption.INVENTORY][TargetableInventoryOption.EXCLUDES].filter(
          existingItem => !removeIds.has(existingItem.id)
        ),
        type: setInventories[TargetableInventoryOption.EXCLUDES],
      });
    },
    [dispatchInventory, slicedGrouped, targetingParams]
  );

  const onButtonClick = useCallback(() => {
    setIsDisplayLimit(!isDisplayLimit);
  }, [isDisplayLimit, setIsDisplayLimit]);

  const groupedItems = useMemo(() => {
    return (
      <Grid>
        {Object.keys(slicedGrouped).map(key => (
          <Grid key={key}>
            <Item testId="exclude-subset-parent-item" readonly={readonly} item={key} onRemove={onParentDelete}>
              {`${key} (Site)`}
            </Item>
            {slicedGrouped[key].map(item => (
              <TreeItem
                testId="exclude-subset-item"
                readonly={readonly}
                onRemove={onItemDelete}
                item={item.id}
                key={item.id}
              >
                {getInventoryDisplayName(item, true)}
              </TreeItem>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  }, [onItemDelete, onParentDelete, readonly, slicedGrouped]);

  const otherItems = useMemo(() => {
    return (
      <Grid container alignItems="center">
        {slicedOther.map((item, index) => (
          <Item testId="exclude-subset-item" key={item.id} readonly={readonly} item={item.id} onRemove={onItemDelete}>
            {getInventoryDisplayName(item)}
            {index < slicedOther.length - 1 ? ',' : ''}
          </Item>
        ))}
      </Grid>
    );
  }, [slicedOther, readonly, onItemDelete]);

  return (
    <ExcludeItemsBox>
      {showExcludeItemBox && (
        <InventoryAutoComplete
          targetingParams={targetingParams}
          onInventorySelect={onInventorySelect}
          includes={includes}
          inputLabel={t('Exclude subset of selected Inventory')}
          testId="inventory-autocomplete-exclude"
          instanceUid={instanceState}
          accountId={accountUid}
        />
      )}
      {!!includes.length && !!excludes.length && (
        <>
          <ExcludeItems title={t('excluded subset of selected inventory')}>
            {groupedItems}
            {otherItems}
          </ExcludeItems>
          {displayButton && (
            <ShowHideButton
              isDisplayLimit={isDisplayLimit}
              onClick={onButtonClick}
              notDisplayedText={t('and {notDisplayedCount} more.', { notDisplayedCount })}
            >
              {isDisplayLimit ? t('show more') : t('hide')}
            </ShowHideButton>
          )}
        </>
      )}
    </ExcludeItemsBox>
  );
};
