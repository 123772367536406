import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Grid, IconButton, type Theme } from '@mui/material';
import { css, styled } from '@mui/material/styles';

export const StyledContainer = styled(Grid)`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

export const StyledIconContainer = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledSpan = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.spacing(3.5)};
`;

export const sharedIconStyles = (theme: Theme, disabledStyle: boolean) => css`
  ${
    disabledStyle &&
    css`
    color: ${theme.palette.text.disabled};
  `
  }
`;

export const StyledItemsRemoveIcon = styled(RemoveCircleOutlineIcon, {
  shouldForwardProp: prop => prop !== 'disabledStyle',
})<{
  disabledStyle: boolean;
}>`
  color: ${({ theme }) => theme.palette.error.main};

  ${({ theme, disabledStyle }) => sharedIconStyles(theme, disabledStyle)};
`;

export const StyledAddIcon = styled(AddCircleOutlineIcon, { shouldForwardProp: prop => prop !== 'disabledStyle' })<{
  disabledStyle: boolean;
}>`
  color: ${({ theme }) => theme.palette.success.main};

  ${({ theme, disabledStyle }) => sharedIconStyles(theme, disabledStyle)};
`;

export const StyledItemsRemoveIconButton = styled(IconButton)`
  visibility: hidden;
  height: min-content;
  padding: 0;
`;

export const StyledTitleWrapper = styled(Box)`
  position: relative;
  width: 100%;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.boxDark};
    .removeIconButton {
      visibility: visible;
    }
  }
`;
