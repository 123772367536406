import { TargetingProvider, targetingConfig } from '@openx/components/targeting';
import { PermissionProvider } from '@openx/utils/permission/context/PermissionContext';
import { permissionConfig } from '@openx/utils/permission/context/unityConfig';

export interface ConfigProvidersProps {
  children: React.ReactNode;
}

export function ConfigProviders({ children }: ConfigProvidersProps) {
  return (
    <PermissionProvider permissionConfig={permissionConfig}>
      <TargetingProvider targetingConfig={targetingConfig}>{children}</TargetingProvider>
    </PermissionProvider>
  );
}
