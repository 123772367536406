import { useTranslation } from 'react-i18next';

import { type OptionsMap, OptionsType } from '@openx/types/options';
import type { UseQueryOptions } from '@openx/types/reactQuery';

import { useOptionsFetch } from './generics/useOptionsFetch';

export function useVideoLocationOptionsFetch(queryOptions?: UseQueryOptions<OptionsMap>) {
  const { t } = useTranslation();
  const {
    isLoading: isVideoLocationLoading,
    data: videoLocations,
    refetch: refetchVideoLocation,
  } = useOptionsFetch({
    errorMessage: t('Failed to fetch video location options'),
    mapByKey: 'legacy_id',
    optionType: OptionsType.VIDEO_LOCATION,
    queryOptions,
  });

  return {
    isVideoLocationLoading,
    refetchVideoLocation,
    videoLocations,
  };
}
