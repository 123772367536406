import { SwipeableDrawer, type SwipeableDrawerProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';

import { Loader } from '../Loader/Loader';

interface DrawerProps extends Pick<Partial<SwipeableDrawerProps>, 'onClose' | 'onOpen' | 'open'> {
  loading?: boolean;
  children: ReactNode;
  dataTest?: string;
  className?: string;
  isStackedDrawer?: boolean;
  transparentBackground?: boolean;
  onClose: (event: React.SyntheticEvent<{}>, reason?: 'escapeKeyDown' | 'backdropClick') => void;
}

const StyledSwipeableDrawer = styled(SwipeableDrawer, {
  shouldForwardProp: prop => prop !== 'isStackedDrawer' && prop !== 'transparentBackground',
})<{
  isStackedDrawer: boolean;
  transparentBackground: boolean;
}>`
  .MuiBackdrop-root {
    ${({ transparentBackground }) => transparentBackground && { backgroundColor: 'transparent' }};
  }

  .MuiPaper-root {
    ${({ theme }) => theme.breakpoints.down(1920)} {
      width: ${({ isStackedDrawer }) => (isStackedDrawer ? '400px' : '800px')};
    }

    ${({ theme }) => theme.breakpoints.between(1920, 2560)} {
      width: ${({ isStackedDrawer }) => (isStackedDrawer ? '540px' : '1080px')};
    }

    ${({ theme }) => theme.breakpoints.up(2560)} {
      width: ${({ isStackedDrawer }) => (isStackedDrawer ? '640px' : '1280px')};
    }
  }
`;

export function Drawer(props: DrawerProps): JSX.Element {
  const {
    loading = false,
    isStackedDrawer = false,
    children,
    onClose = () => undefined,
    onOpen = () => undefined,
    open = false,
    dataTest,
    className,
    transparentBackground = false,
  } = props;

  return (
    <StyledSwipeableDrawer
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      anchor="right"
      data-test={dataTest}
      disableSwipeToOpen
      className={className}
      isStackedDrawer={isStackedDrawer}
      transparentBackground={transparentBackground}
    >
      {loading ? <Loader /> : children}
    </StyledSwipeableDrawer>
  );
}
