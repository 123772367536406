import { styled } from '@mui/material/styles';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer } from '@openx/components/core/lib/Drawer/Drawer';
import { DrawerActionBar, type DrawerActions } from '@openx/components/core/lib/Drawer/DrawerActionBar';
import { DrawerHeader } from '@openx/components/core/lib/Drawer/DrawerHeader';

const StyledHeaderContainerDiv = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(-2)};
`;

type TargetingDrawerProps = {
  name: string | JSX.Element;
  onClose: () => void;
  onApply: () => void;
  children: ReactNode;
  isDataUpdated: boolean;
};

export const TargetingDrawer = ({
  onClose,
  onApply,
  name,
  children,
  isDataUpdated,
}: TargetingDrawerProps): JSX.Element => {
  const { t } = useTranslation();

  const actions: DrawerActions = useMemo(
    () => [
      {
        color: 'primary',
        disabled: !isDataUpdated,
        label: t('Apply'),
        onClick: onApply,
      },
      {
        label: t('Cancel'),
        onClick: onClose,
        variant: 'text',
      },
    ],
    [isDataUpdated, onApply, onClose, t]
  );

  return (
    <Drawer open onClose={onClose}>
      <div data-test="side-details">
        <StyledHeaderContainerDiv>
          <DrawerHeader onClose={onClose}>{name}</DrawerHeader>
        </StyledHeaderContainerDiv>
        {children}
        <DrawerActionBar actions={actions} stickyBottom hideDivider />
      </div>
    </Drawer>
  );
};
