import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentObjectOptions, type ContentObjectProps } from '@openx/types/targeting/contentObject';
import { Intersect } from '@openx/types/targeting/targetingValuesTypes';

import { EmptyFilterMessage, FiltersTitle, SelectedItems, Switch } from '../../../shared';
import type { TargetingItemsProps } from '../../../types';
import { itemTitle } from '../../constants';

export const EpisodeItems = ({
  targetingParams,
  readonly,
  onChange = () => {},
}: TargetingItemsProps<ContentObjectProps>) => {
  const { t } = useTranslation();

  const { op, val } = targetingParams[ContentObjectOptions.EPISODE] || {
    op: Intersect.INTERSECTS,
    val: new Set<string>(),
  };

  const switchState = op === Intersect.NOT_INTERSECTS;
  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('blocked') : t('allowed');
  const filtersTitle = val.length > 0 ? typeLabel : '';
  const selectedItems = useMemo(() => val.sort((a, b) => a.eps[0] - b.eps[0]).map(item => item.label), [val]);
  const episodesLength = useMemo(() => val.reduce((acc, item) => acc + item.eps.length, 0), [val]);

  const handleChange = (values: string[]) => {
    const returnValues = values.map(value => val.find(ep => ep.label === value)!).filter(Boolean);

    onChange(prevState => ({
      ...prevState,
      [ContentObjectOptions.EPISODE]: {
        op,
        val: returnValues,
      },
    }));
  };

  if (val.length === 0) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }

  return (
    <div data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={itemTitle(ContentObjectOptions.EPISODE, episodesLength)}
        readonly={readonly}
      />

      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange(prevState => ({
              ...prevState,
              [ContentObjectOptions.EPISODE]: {
                op: switchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                val: val,
              },
            }))
          }
          switchOffLabel={t('allow')}
          switchOnLabel={t('block')}
          groupTitle={t('the following:')}
        />
      )}

      <SelectedItems
        dataTest="episodes"
        filtersTitle={filtersTitle}
        isAddIcon={!switchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={handleChange}
      />
    </div>
  );
};
