import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GeographicOption, type GeographicTargetingState, type RadiusCircle } from '@openx/types/targeting/geographic';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';
import { getCircleDisplayName } from '@openx/utils/state/geoLocationSearch/utils';

import { DimensionChipLine, FiltersTitle, SelectedItems } from '../../shared';

type CirclesItemsProps = {
  isNotLastItem: boolean;
  onChange: (newData: GeographicTargetingState) => void;
  readonly: boolean;
  targetingParams: GeographicTargetingState;
};

export const CirclesItems = ({ isNotLastItem, onChange, readonly, targetingParams }: CirclesItemsProps) => {
  const { t } = useTranslation();
  const { circles } = targetingParams;
  const circlesCount = circles.length;

  const onCirclesClear = useCallback(() => {
    onChange({ ...targetingParams, [GeographicOption.CIRCLES]: [] });
  }, [onChange, targetingParams]);

  const onCircleRemove = useCallback(
    (filteredItems: RadiusCircle[]) => {
      onChange({ ...targetingParams, [GeographicOption.CIRCLES]: filteredItems });
    },
    [onChange, targetingParams]
  );

  return (
    <Box data-test="sub-section">
      <FiltersTitle
        onClear={onCirclesClear}
        title={t('Location Radius [ {count} ]', { count: circlesCount })}
        readonly={readonly}
      />

      <SelectedItems
        filtersTitle={t('include')}
        isAddIcon
        selectedItems={circles}
        readonly={readonly}
        handleChange={onCircleRemove}
        isRemovingAll
        getItemLabel={getCircleDisplayName}
        dataTest="circles"
      />

      {isNotLastItem && <DimensionChipLine targetingDimension={Criteria.ANY} />}
    </Box>
  );
};
