import type { ButtonProps } from '../Button/Button';

import {
  StyledDrawerActionButton,
  StyledDrawerContainerDiv,
  StyledDrawerContentContainerDiv,
  StyledDrawerWrapperDiv,
} from './DrawerActionBar.styled';
import { DrawerDivider } from './DrawerDivider';

export interface DrawerAction extends Omit<ButtonProps, 'children'> {
  label: string;
  'data-test'?: string;
}

export type DrawerActions = DrawerAction[];

export interface DrawerActionBarProps {
  actions: DrawerActions;
  gutterTop?: boolean;
  gutterBottom?: boolean;
  variant?: ButtonProps['variant'];
  stickyBottom?: boolean;
  hideDivider?: boolean;
  backgroundColor?: string;
  sidePadding?: boolean;
  dataTest?: string;
}

export function DrawerActionBar(props: DrawerActionBarProps): JSX.Element {
  const {
    actions,
    variant = 'contained',
    stickyBottom = false,
    hideDivider = false,
    dataTest,
    gutterBottom,
    gutterTop,
    sidePadding,
    backgroundColor,
  } = props;

  return (
    <StyledDrawerWrapperDiv
      data-test={dataTest}
      stickyBottom={stickyBottom}
      gutterBottom={gutterBottom}
      gutterTop={gutterTop}
    >
      <StyledDrawerContainerDiv stickyBottom={stickyBottom} bgColor={backgroundColor} sidePadding={sidePadding}>
        {stickyBottom && !hideDivider && <DrawerDivider gutterBottom />}

        <StyledDrawerContentContainerDiv variant={variant} data-test="action-bar">
          {actions.map(action => {
            const { label, ...restActionProps } = action;

            return (
              <StyledDrawerActionButton variant={variant} {...restActionProps} key={label}>
                {label}
              </StyledDrawerActionButton>
            );
          })}
        </StyledDrawerContentContainerDiv>
      </StyledDrawerContainerDiv>
    </StyledDrawerWrapperDiv>
  );
}
