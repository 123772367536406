import { Grid, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

import { FormSection } from '@openx/components/core/lib/FormSection/FormSection';
import { TargetingReadOnlyList, packageToFormState, useFilteredTargetingItems } from '@openx/components/package';
import type { PackageWithTargeting } from '@openx/types/package';

import type { DealDetailsProps } from 'types';

import { FormSectionContainer } from './styledComponents';

export const TargetingCriteriaSection = ({ deal }: DealDetailsProps): JSX.Element => {
  const packageState = useMemo(() => packageToFormState((deal as Partial<PackageWithTargeting>) || {}), [deal]);
  const matchesWidth = useMediaQuery('(max-width: 760px)');
  const targeting = useFilteredTargetingItems(packageState);

  return (
    <FormSectionContainer>
      <FormSection divider title="Targeting Criteria">
        <Grid item xs={matchesWidth ? 10 : 8}>
          <TargetingReadOnlyList targeting={targeting} packageState={packageState} />
        </Grid>
      </FormSection>
    </FormSectionContainer>
  );
};
