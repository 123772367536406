import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import type { GeoLocation, GeoLocationsList } from '@openx/types/geoLocation';
import type { GeoLocationType } from '@openx/types/targeting/geographic';
import { Criteria } from '@openx/types/targeting/targetingValuesTypes';

import { DimensionChip, SelectedItems } from '../../shared';
import { geographicLabels } from '../constants';

type GeographicLocationItemsProps = {
  locationKeys: string[];
  locations: GeoLocationType;
  readonly: boolean;
  handleChange: (type: string, cb: (val: GeoLocationsList, type: string) => void) => (value: GeoLocationsList) => void;
  onLocationRemove: (itemNames: GeoLocationsList, type: string) => void;
  locationsSwitchState: boolean;
  getGeoLocationDisplayName: (item: GeoLocation) => string;
};

export function GeographicLocationItems({
  locationKeys,
  locations,
  readonly,
  handleChange,
  onLocationRemove,
  locationsSwitchState,
  getGeoLocationDisplayName,
}: GeographicLocationItemsProps): JSX.Element {
  const { t } = useTranslation();

  const filteredLocations = locationKeys.filter(key => !isEmpty(locations[key]));

  const locationItems = filteredLocations.map((key, index) => (
    <Fragment key={`location-${key}`}>
      <SelectedItems
        filtersTitle={t(geographicLabels[key])}
        isAddIcon={locationsSwitchState}
        selectedItems={locations[key]}
        readonly={readonly}
        handleChange={handleChange(key, onLocationRemove)}
        isRemovingAll={true}
        getItemLabel={getGeoLocationDisplayName}
        customDisplayLimit={15}
        dataTest={t(geographicLabels[key])}
      />

      {locationKeys.length - 1 > index ? (
        <DimensionChip targetingDimension={locationsSwitchState ? Criteria.ANY : Criteria.ALL} isInsideOption />
      ) : null}
    </Fragment>
  ));

  if (locationItems.length === 0) {
    return <></>;
  }

  return <>{locationItems}</>;
}
