import { Grid } from '@mui/material';
import type { ReactNode } from 'react';

import { Title } from './Title';
import {
  StyledChildrenGrid,
  StyledContainerGrid,
  StyledExcludeItemsRemoveIcon,
  StyledExcludeItemsRemoveIconButton,
} from './styled/ExcludeItems.styled';

type ExcludeItemsProps = {
  children: ReactNode;
  title: string;
};

export const ExcludeItems = ({ children, title }: ExcludeItemsProps): JSX.Element => {
  return (
    <StyledContainerGrid container>
      <Title title={title} data-test="exclude-subset-allow" />
      <Grid container alignItems="flex-start" wrap="nowrap" sx={{ marginTop: theme => theme.spacing(1) }}>
        <StyledExcludeItemsRemoveIconButton disabled size="large">
          <StyledExcludeItemsRemoveIcon fontSize="small" />
        </StyledExcludeItemsRemoveIconButton>
        <StyledChildrenGrid>{children}</StyledChildrenGrid>
      </Grid>
    </StyledContainerGrid>
  );
};
