import { PackageFields, type PackageFormState } from '@openx/types/package';
import { TargetingFields } from '@openx/types/targeting/targeting';

import { useTargeting } from './useTargeting';

export const useFilteredTargetingItems = (packageState: PackageFormState) => {
  const targeting = useTargeting();

  return targeting.filter(({ field }) => {
    if (field === TargetingFields.METACATEGORY) {
      return (
        packageState[PackageFields.TARGETING][field]?.excludes?.length ||
        packageState[PackageFields.TARGETING][field]?.includes?.val?.length ||
        packageState[PackageFields.TARGETING][field]?.keywords?.includes?.length ||
        packageState[PackageFields.TARGETING][field]?.keywords?.excludes?.length
      );
    }

    if (field === TargetingFields.ADVANCED_TARGETING) {
      return (
        packageState[PackageFields.TARGETING][TargetingFields.CUSTOM_VARIABLES]?.custom.length ??
        packageState[PackageFields.TARGETING][TargetingFields.CUSTOM_VARIABLES]?.contextual.length
      );
    }

    return packageState[PackageFields.TARGETING][field];
  });
};
