import type { SortDirection } from '@openx/types/tableCriteria';

import type { DealLibraryItem } from 'types';

import type { ColumnKey } from '../constants';

import { sortList } from './sortList';

export const filterList = (
  dealTemplates: DealLibraryItem[],
  phrase: string,
  sortDirection: SortDirection,
  sortColumn: ColumnKey
): DealLibraryItem[] => {
  const phraseLowerCased = phrase.toLowerCase();
  const sortedList = sortList(sortDirection, sortColumn, dealTemplates);
  return sortedList.filter(deal => {
    const { name, description, tags } = deal;
    const nameMatch = name.toLowerCase().includes(phraseLowerCased);
    const descriptionMatch = description.toLowerCase().includes(phraseLowerCased);
    const tagsMatch = tags.some(tag => tag.toLowerCase().includes(phraseLowerCased));

    return nameMatch || descriptionMatch || tagsMatch;
  });
};
