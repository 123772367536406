import { Grid } from '@mui/material';
import { type ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DrawerActionBar, type DrawerActions } from '@openx/components/core/lib/Drawer/DrawerActionBar';
import { TextField } from '@openx/components/core/lib/TextField/TextField';
import { InvalidItems } from '@openx/components/core/lib/TransferList/bulk/InvalidItems/InvalidItems';
import { type UrlTargeting, UrlTargetingType } from '@openx/types/targeting/domains';

import { BULK_SPLITER, type ValidationResult } from '../BulkAdd/index';

import { BulkAction } from './utils/bulk';
import { useBulk } from './utils/useBulk';

type TextFieldEditorProps = {
  initItems: string;
  onApply: (items: string[]) => void;
  errorText?: string;
  children?: ReactNode;
  placeholder: string;
  helperText?: string;
  validate: (value: string[]) => Promise<ValidationResult>;
  setApplyEnabled: (value: boolean) => void;
  limit?: number;
  childParams?: unknown;
  maxRows?: number;
  handleReset?: () => UrlTargeting;
  shouldDisplayResetButton?: boolean;
};

export type TextFieldEditorHandle = {
  initializeEditor: (items: string) => void;
};

export const TextFieldEditor = forwardRef(function TextFieldEditor(
  {
    initItems,
    onApply,
    errorText,
    children,
    placeholder,
    helperText,
    validate,
    setApplyEnabled,
    limit = Number.MAX_SAFE_INTEGER, // in case limit not provided use max integer as default to not show error
    childParams,
    maxRows,
    handleReset = () => ({ type: UrlTargetingType.blacklist, urls: [] }),
    shouldDisplayResetButton = false,
  }: TextFieldEditorProps,
  ref
) {
  const { t } = useTranslation();
  const { bulk, dispatchBulkChange } = useBulk(initItems);

  const isLimitReached = bulk.validItems.length > limit;

  useEffect(() => {
    // disable add when params in children changed, as current values needs to be revalidated
    setApplyEnabled(false);
    dispatchBulkChange({ isResetEnabled: true, type: BulkAction.SetResetEnabled });
  }, [childParams, setApplyEnabled, dispatchBulkChange]);

  useImperativeHandle(
    ref,
    () => ({
      initializeEditor: (items: string) => {
        dispatchBulkChange({ type: BulkAction.InitValues, value: items });
      },
    }),
    [dispatchBulkChange]
  );

  const onValidate = useCallback(async () => {
    dispatchBulkChange({ isValidating: true, type: BulkAction.SetValidating });

    const items = bulk.value
      .split(BULK_SPLITER)
      .map(value => value.trim())
      .filter(value => Boolean(value));

    const { errors, validItems } = await validate(items);

    const validItemsReformated = validItems.join('\n');
    const isWithinLimit = validItems.length <= limit;

    dispatchBulkChange({
      errors: errors,
      isResetEnabled: true,
      isValidating: false,
      type: BulkAction.InitValues,
      validItems: validItems,
      value: validItemsReformated,
    });

    setApplyEnabled(isWithinLimit);
    onApply(validItems);
  }, [bulk.value, validate, onApply, setApplyEnabled, limit, dispatchBulkChange]);

  const onReset = useCallback(async () => {
    dispatchBulkChange({ isValidating: true, type: BulkAction.SetValidating });

    const newInitValue = handleReset();

    dispatchBulkChange({ type: BulkAction.InitValues, value: newInitValue.urls.join('\n') });
    setApplyEnabled(false);
  }, [handleReset, setApplyEnabled, dispatchBulkChange]);

  const actions: DrawerActions = useMemo(() => {
    const initActions: DrawerActions = [
      {
        label: t('Validate'),
        loading: bulk.isValidating,
        onClick: onValidate,
      },
    ];

    if (shouldDisplayResetButton)
      initActions.push({
        color: 'error',
        'data-test': 'reset-to-account-settings',
        disabled: !bulk.isResetEnabled,
        label: t("Reset to account's settings"),
        loading: bulk.isValidating,
        onClick: onReset,
      });

    return initActions;
  }, [t, onValidate, bulk.isValidating, bulk.isResetEnabled, shouldDisplayResetButton, onReset]);

  const onChange = useCallback(
    event => {
      dispatchBulkChange({ type: BulkAction.SetValue, value: event.target.value });
      setApplyEnabled(false);
      dispatchBulkChange({ isResetEnabled: true, type: BulkAction.SetResetEnabled });
    },
    [setApplyEnabled, dispatchBulkChange]
  );

  return (
    <>
      <Grid
        sx={{
          '.MuiPaper-root.MuiAlert-standard': {
            alignItems: 'center',
            width: '100%',
          },
        }}
      >
        {!!bulk.errors.length && <InvalidItems errors={bulk.errors} errorText={errorText} />}
      </Grid>

      {children}

      <Grid
        container
        sx={{
          height: '100%',
          marginBottom: theme => theme.spacing(1),
          minHeight: '25rem',
        }}
      >
        <TextField
          data-test="targeting-bulk-input"
          multiline
          fullWidth
          InputProps={{ disableUnderline: true }}
          helperText={isLimitReached ? t('The limit has been reached ({limit}).', { limit }) : helperText}
          error={isLimitReached}
          placeholder={placeholder}
          onChange={onChange}
          value={bulk.value}
          disabled={bulk.isValidating}
          maxRows={maxRows}
          sx={{
            '.MuiInput-root': {
              height: '100%',
            },
            '.MuiInput-root textarea': {
              height: '100% !important',
              overflow: 'auto !important',
            },
          }}
        />
      </Grid>

      <DrawerActionBar actions={actions} hideDivider backgroundColor="inherit" dataTest="bulk-drawer-buttons" />
    </>
  );
});
